import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { MAX_TABLE_DATA_PER_PAGE } from "../../app/utils/apis/Constant";
import axios, { CancelTokenSource } from "axios";

let cancelToken: CancelTokenSource | null = null;

export const getAllManagers = createAsyncThunk(
  "manager/getAllManagers",
  async (
    { page, searchText, limit = MAX_TABLE_DATA_PER_PAGE, sortBy }: { page: number; total_page?: number; limit?: number, searchText?: string, sortBy?: string },
    { rejectWithValue }
  ) => {
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      const paramsPayload: any = {
        page: page,
        limit: limit,
        searchText: searchText
      }
      if (sortBy && sortBy?.length > 0) {
        paramsPayload.sortBy = sortBy;
      }
      const response = await apiClient.get(ApiConstants.GET_ALL_MANAGERS, {
        cancelToken: cancelToken.token,
        params: paramsPayload,
      });
      cancelToken = null;
      return response.data.data;
    } catch (err: any) {
      if (axios.isCancel(err)) {
        return;
      } else {
        return rejectWithValue(err.response?.data || "Failed to get projects");
      }
    }
  }
);

export const createManagerAPI = createAsyncThunk(
  "manager/createManager",
  async (
    data: {
      name: string;
      country_code: string;
      phone_number: string;
      email: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.CREATE_MANAGER}`,
        data
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const updateManagerAPI = createAsyncThunk(
  "manager/updateManagerAPI",
  async (
    data: {
      manager_id: number;
      name?: string;
      country_code?: string;
      phone_number?: string;
      email?: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let { manager_id, ...reqObj } = data;
      const response = await apiClient.put(
        `${ApiConstants.UPDATE_MANAGER}/${manager_id}/update`,
        reqObj
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const deleteManagerAPI = createAsyncThunk(
  "manager/deleteManagerAPI",
  async (data: { manager_id: number }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `${ApiConstants.UPDATE_MANAGER}/${data.manager_id}/delete`
      );
      return {
        payload: response.data.data,
        manager_id: data.manager_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getCpsForManagerAPI = createAsyncThunk(
  "manager/getCpsForManagerAPI",
  async (
    data: { manager_id: number; current_page: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.MANAGE_MANAGER_COMMON}/${data.manager_id}/cps?page=${data.current_page
        }&limit=${data.limit ?? MAX_TABLE_DATA_PER_PAGE}`
      );
      return { ...response.data.data, manager_id: data.manager_id };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getManagersProjectAPI = createAsyncThunk(
  "manager/getManagersProjectAPI",
  async (
    data: { manager_id: number; current_page: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.MANAGE_MANAGER_COMMON}/${data.manager_id
        }/projects?page=${data.current_page}&limit=${data.limit ?? MAX_TABLE_DATA_PER_PAGE
        }`
      );

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

// export const getAvailableCPsAPI = createAsyncThunk(
//   "manager/getAvailableCPsAPI",
//   async (
//     data: { current_page: number; limit?: number, searchText?: string, sortBy?: string },
//     { rejectWithValue }
//   ) => {
//     try {
//       const response = await apiClient.get(
//         `${ApiConstants.GET_AVAILABLE_CPS_FOR_MANAGER}?page=${data.current_page}&searchText=${data.searchText ?? ""}&limit=${data.limit ?? MAX_TABLE_DATA_PER_PAGE}`);
//       return response.data.data;
//     } catch (error: any) {
//       return rejectWithValue(error.response?.data?.error);
//     }
//   }
// );

export const getAvailableCPsAPI = createAsyncThunk(
  "manager/getAvailableCPsAPI",
  async ({
            current_page,
            limit = MAX_TABLE_DATA_PER_PAGE,
            searchText = "",
            sortBy
          } : {current_page: number; limit?: number; searchText?: string, sortBy?: string},{ rejectWithValue }
  ) => {
    try {
      const paramsPayload: any = {
                page: current_page,
                limit: limit,
                searchText: searchText??"",
              }
              if (sortBy && sortBy?.length > 0) {
                paramsPayload.sortBy = sortBy;
              }
      const response = await apiClient.get(ApiConstants.GET_AVAILABLE_CPS_FOR_MANAGER, {
          params: paramsPayload,
        });
      console.log('paramsPayload :===?>> ', paramsPayload);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

// export const getAvailableCPsAPI = createAsyncThunk(
//   "manager/getAvailableCPsAPI",
//   async (
//     {
//       page,
//       limit = MAX_TABLE_DATA_PER_PAGE,
//       searchText = "",
//       sortBy
//     } : {page: number; limit?: number; searchText?: string, sortBy?: string},{ rejectWithValue }
//   ) => {
//     try {
//       const paramsPayload: any = {
//         page: page,
//         limit: limit,
//         searchText: searchText,
//       }
//       if (sortBy && sortBy?.length > 0) {
//         paramsPayload.sortBy = sortBy;
//       }
//       const response = await apiClient.get(
//         `${ApiConstants.GET_AVAILABLE_CPS_FOR_MANAGER},{
//         params: paramsPayload
//         }
//       return response.data.data;
//     } catch (error: any) {
//       return rejectWithValue(error.response?.data?.error);
//     }
//   }
// )


export const allocateCPsAPI = createAsyncThunk(
  "manager/allocateCPsAPI",
  async (
    data: { manager_id: number; cp_ids: number[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.ALLOCATE_CPS}`,
        data
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const deallocateCpAPI = createAsyncThunk(
  "manager/deallocateCp",
  async (
    data: { manager_id: number; cp_ids: number[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.DEALLOCATE_CPS_FROM_MANAGER}`,
        data
      );
      return { payload: response.data.data, cp_ids: data.cp_ids };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getTopManagers = createAsyncThunk(
  "manager/getTopManagers",
  async (
    { page, total_page = 10 }: { page: number; total_page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_TOP_MANAGERS}/?page=${page}&limit=${total_page}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all managers");
    }
  }
);
