import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegristrationRequestType } from "../../dto/RegistrationRequest.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { RegistrationRequestReducer } from "../Reducer/RegistrationRequest/RegistrationRequestReducer";

export interface RegistrationReqSliceState {
  loading: boolean;
  verifyLoading: boolean;
  requests: RegristrationRequestType[];
  pagination: PaginationType;
}

const initialState: RegistrationReqSliceState = {
  loading: false,
  verifyLoading: false,
  requests: [],
  pagination: {
    total_data: 0,
    current_page: 1,
    page_size: 7,
    total_pages: 1,
  },
};

const registrationRequestSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setRegistrationReqPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },

    setRegistrationReqLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    RegistrationRequestReducer(builder);
  },
});
export const { setRegistrationReqPagination, setRegistrationReqLoading } =
  registrationRequestSlice.actions;

export default registrationRequestSlice.reducer;
