import React, { useEffect, useState } from 'react';
import './FilterModal.scss';
import Modal from '../Modal/Modal';
import CustomRadioButton from '../RadioButton/Radio';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { resetBrokerFilter, setBrokerFilter } from '../../../redux/Slices/brokerSlice';
import { resetManagerFilter, setManagerFilters } from '../../../redux/Slices/managerSlice';
import OutsideClickHandler from 'react-outside-click-handler';

interface OptionType {
    key: string,
    value: string

}

interface FilterModalPropsType {
    // onCloseModal: (data: boolean) => void;
    options: OptionType[];
    handleSubmit: (data: OptionType) => void;
    handleRemoveFilter: () => void;
    filterType: 'broker' | 'manager';
    containerClassName?: string;
    onOutSideClick: Function

}

const FilterModal: React.FC<FilterModalPropsType> = ({
    // onCloseModal,
    options,
    handleSubmit,
    handleRemoveFilter,
    filterType,
    containerClassName = "",
    onOutSideClick
}) => {
    const dispatch = useAppDispatch();
    const { brokerFilter } = useAppSelector(state => state.broker)
    const { managerFilter } = useAppSelector(state => state.manager)
    const selectedFilter = filterType === 'broker' ? brokerFilter : managerFilter;

    const handleFilterClick = (option: OptionType) => {
        if (filterType === 'broker') {
            dispatch(setBrokerFilter(option.key));
        } else {
            dispatch(setManagerFilters(option.key));
        }
        handleSubmit(option);
    };
    const handleRemoveClick = () => {
        if (filterType === 'broker') {
            dispatch(resetBrokerFilter());
        } else {
            dispatch(resetManagerFilter());
        }
        handleRemoveFilter();
    };
    return (
        <OutsideClickHandler onOutsideClick={() => onOutSideClick()}>
            <div className={containerClassName.length > 0 ? containerClassName : 'filter_modal_container'}>
                {/* <Modal isClose={true} onClose={() => onCloseModal(false)} > */}
                <div className='modal_content'>
                    <div className='content'>
                        <div className='heading_container'>
                            {/* Select Filter */}
                        </div>
                        <div className='filter_option_container'>
                            {options.map(opt => (
                                <div
                                    key={opt.key}
                                    className={`filter_option ${selectedFilter === opt.key ? 'selected' : ''
                                        }`}
                                    onClick={() => handleFilterClick(opt)}
                                >

                                    <div onClick={() => handleSubmit(opt)} className='filter_text'>
                                        {opt.value}
                                    </div>
                                    <div>
                                        <hr className='filter_horizontal_line' />
                                    </div>
                                </div>
                            ))}
                            <p onClick={handleRemoveClick} className='remove_filter_button'>Remove Filter</p>
                        </div>
                    </div>
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default FilterModal
