import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import ProjectList from './ProjectList';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { getAllActiveProjects } from '../../../redux/API/ProjectAPI';
import './SiteManagement.scss';

const ProjectsTabs: React.FC = () => {

    return (
        <div>
            <ProjectList isActive={true} />
        </div>
    )
}

export default ProjectsTabs