import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
import { useNavigate } from "react-router-dom";
import { getImageURL } from "../../../../app/utils/common/getImageUrl";
import { image } from "../../../../app/utils/common/image";
import ActionIcon from "../../../atoms/ActionIcon/ActionIcon";
import OptionMenu from "../../../atoms/OptionMenu/OptionMenu";
import TableComponent from "../../../atoms/TableComponent/Tablecomponent";
import { RoleType } from "../../../../dto/Role.dto";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/utils/hooks/reduxHook";
import { deleteRoleAPI, getAllRoles } from "../../../../redux/API/RoleAPI";
import RolesPermissionModal from "../../../atoms/RolesPermissionModal/RolesPermissionModal";
import { confirmModalProps } from "../../../../dto/confirm";
import ConfirmModal from "../../../atoms/confirmation-modal/confirmation-modal";

const ActionButtonContainer: React.FC<{ Role: any, setOpenPermissionModal: (data: boolean) => void; setCurrentRole: () => void }> = ({ Role, setOpenPermissionModal, setCurrentRole }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const options = [
    {
      name: "Edit",
      onClick: () => {
        setCurrentRole();
        setOpenPermissionModal(true);
        setIsMenuOpen(false);
      }
    },
    {
      name: "Remove",
      onClick: () => {
        setDeleteModal(true);
      },
    },
  ];
  const confirmModalProps: confirmModalProps = {
    text: `Are you sure you want to delete ${Role.role_name} role?`,
    confirmModal: deleteModal,
    setConfirmModal: setDeleteModal,
    buttons: ['Yes, Delete', 'Cancel'],
    onPressPositive: () => {
      setDeleteModal(false);
      dispatch(deleteRoleAPI({ role_id: Role.role_id }))
    },
    onPressNegative: () => { setDeleteModal(false) },
    image: image.REMOVE_USER_ICON
  };
  return (
    <div className="action_tab" style={{ position: "relative" }}>
      <div className="action-group" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <ActionIcon />
      </div>
      {isMenuOpen && (
        <OptionMenu
          options={options}
          left="-0px"
          top="28px"
          onOutSideClick={() => setIsMenuOpen(false)}
        />
      )}
      {deleteModal && <ConfirmModal confirmModalProps={confirmModalProps} />}
    </div>
  );
};

const AllRolesTable: React.FC = () => {
  const [openPermissionModal, setOpenPermissionModal] = useState<boolean>();
  const [currentRole, setCurrentRole] = useState<RoleType>()
  const navigate = useNavigate();
  const { loading, allRoles, isRoleUpdated } = useAppSelector(
    (state) => state.role
  );
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: allRoles.length,
    total_pages: 1,
    total_data: allRoles.length,
  });

  useEffect(() => {
    if (isRoleUpdated) {
      dispatch(getAllRoles());
    }
  }, [isRoleUpdated]);

  const handlePageChange = (pageNumber: number) => { };

  const columns: TableColumnsType<RoleType> = [
    {
      title: "Role No.",
      dataIndex: "role_id",
    },
    {
      title: "Role Name",
      dataIndex: "role_name",
      render: (_, record) => (
        <div
        >
          {record.role_name}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => <ActionButtonContainer setCurrentRole={() => { setCurrentRole(record) }} Role={record} setOpenPermissionModal={setOpenPermissionModal} />,
    },
  ];

  return (
    <div className="registration_dataTable">
      <TableComponent
        columns={columns}
        dataSource={allRoles}
        loading={loading}
        pageNumbers={Array.from(
          { length: pagination.total_pages },
          (_, index) => index + 1
        )}
        handlePageLink={handlePageChange}
        isPagination={false}
        totalProfile={pagination.total_data}
        prevPage={() => handlePageChange(pagination.current_page - 1)}
        nextPage={() => handlePageChange(pagination.current_page + 1)}
        activePage={pagination.current_page}
        currentTotal={Math.min(pagination.current_page * pagination.page_size, allRoles.length)}
      />
      {openPermissionModal && currentRole && <RolesPermissionModal isEdit={true} currentRole={currentRole} setOpenPermissionModal={setOpenPermissionModal} />}
    </div>
  );
};

export default AllRolesTable;
