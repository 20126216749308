import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/utils/hooks/reduxHook";
import { getPostRequestsAPI } from "../../../redux/API/PostAPI";
import PostList from "./PostLists";
import "./PostManagement.scss";
import {
  setPostActiveTab,
  setPostData,
  setPostLoading,
  setPostPagination,
} from "../../../redux/Slices/postSlice";
import { Color } from "antd/es/color-picker";
import Loader from "../../atoms/Loader/Loader";

const PostProjectTab = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("pending");

  const [loading, setLoading] = useState(false);

  //   useEffect(() => {
  //     dispatch(getPostRequestsAPI({ current_page: 1, Post_Status: activeTab }))
  //       .unwrap()
  //       .then(() => {
  //         dispatch(setPostLoading(true));
  //       })
  //       .catch(() => {
  //         dispatch(setPostLoading(false));
  //         setTimeout(() => {
  //           setLoading(false);
  //         }, 200);
  //       })
  //       .finally(() => {
  //         dispatch(setPostLoading(false));
  //         setTimeout(() => {
  //           setLoading(false);
  //         }, 200);
  //       });
  //   }, [activeTab, dispatch]);

  const handleTabChange = (key: string) => {
    try {
      dispatch(setPostPagination({
        current_page: 1,
        total_pages: 1,
        total_data: 10,
        page_size: 10
      }));
      dispatch(setPostData({ status: activeTab, payload: [] }));
      dispatch(setPostActiveTab(key));
      setActiveTab(key);
      // dispatch(setPostLoading(true));
      // setLoading(true);
    } catch (error) {
      console.error("Error fetching post data:", error);
    } finally {
      // dispatch(setPostLoading(false)); // Stop the loader
    }
  };

  const TabHeader = (data: {
    label: string;
    count?: number;
    color?: string;
  }) => (
    <div className="tab_selected" style={{ color: data.color }}>
      {data.label}
      {/* <div className="active_project_number">{data.count}</div> */}
    </div>
  );

  return (
    <div>
      <Tabs
        defaultActiveKey="pending"
        onChange={handleTabChange}
        tabBarStyle={{
          borderBottom: "1px solid #C4C4C4",
        }}
        items={[
          {
            // label: <TabHeader label="Request" count={pagination.total_data} />,
            label: <TabHeader label="Request" />,
            key: "pending",
            children: loading ? (
              <div className="center_div">
                <Loader />
              </div>
            ) : (
              <PostList activeTab={activeTab} />
            ),
          },
          {
            label: <TabHeader label="Accepted" color="#5f9937" />,
            key: "approved",
            children: loading ? (
              <div className="center_div">
                <Loader />
              </div>
            ) : (
              <PostList activeTab={activeTab} />
            ),
          },
          {
            label: <TabHeader label="Rejected" color="#db0b0b" />,
            key: "rejected",
            children: loading ? (
              <div className="center_div">
                <Loader />
              </div>
            ) : (
              <PostList activeTab={activeTab} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default PostProjectTab;
