import React, { useEffect, useState } from 'react'
import { confirmModalProps } from '../../../dto/confirm';
import { rolePermissionProps } from '../../../dto/rolesPermisson.dto';
import Modal from '../Modal/Modal';
import { Table, TableColumnsType } from 'antd';
import TableComponent from '../TableComponent/Tablecomponent';
import Button from '../Button/Button';
import { RoleModuleType, RolePermissionType, RoleType } from '../../../dto/Role.dto';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { createRoleAPI, getAllRoleModulesAPI, updateRoleAPI } from '../../../redux/API/RoleAPI';
import Checkbox from '../Checkbox/Checkbox';
import Loader from '../Loader/Loader';
import "./RolePermissionModal.scss";
import { image } from '../../../app/utils/common/image';
import Input from '../Input/Input';
import ErrorText from '../Error/error';
interface RolesPermissionProps {
    setOpenPermissionModal: (data: boolean) => void;
    currentRole?: RoleType;
    isEdit: boolean;

}

const RolesPermissionModal: React.FC<RolesPermissionProps> = props => {
    const { setOpenPermissionModal, currentRole, isEdit } = props;
    const { loading, isModulesUpdated, allModules } = useAppSelector(state => state.role);
    const [currentRoleModules, setCurrentRoleModules] = useState<RolePermissionType[]>(currentRole?.permissions ?? []);
    const [isUpdate, setIsUpdate] = useState(false);
    const [showError, setShowError] = useState(false);
    const [roleName, setRoleName] = useState(currentRole?.role_name ?? "");

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isModulesUpdated) {
            dispatch(getAllRoleModulesAPI());
        }
    }, [isModulesUpdated])

    const areModulesDifferent = (
        currentModules: RolePermissionType[],
        initialModules: RolePermissionType[]
    ): boolean => {
        if (currentModules.length !== initialModules.length) return true;
        const sortedCurrent = [...currentModules].sort((a, b) => a.module_id - b.module_id);
        const sortedInitial = [...initialModules].sort((a, b) => a.module_id - b.module_id);

        for (let i = 0; i < sortedCurrent.length; i++) {
            const currModule = sortedCurrent[i];
            const initModule = sortedInitial[i];
            if (
                currModule.module_id !== initModule.module_id ||
                currModule.module_name !== initModule.module_name
            ) {
                return true;
            }
            const currKeys = [...currModule.module_key].sort();
            const initKeys = [...initModule.module_key].sort();
            if (currKeys.length !== initKeys.length) return true;
            if (!currKeys.every((key, index) => key === initKeys[index])) return true;
        }

        return false;
    };

    useEffect(() => {
        if (isEdit) {
            const isDifferent = roleName.trim() !== currentRole?.role_name ? true : areModulesDifferent(currentRoleModules, currentRole.permissions);
            setIsUpdate(isDifferent)
        }
    }, [currentRoleModules, currentRole?.permissions, roleName]);


    const handlePermissionChange = (module_id: number, key: string, changed_module: RoleModuleType) => {
        setCurrentRoleModules(prevModules => {
            if (prevModules.some(mod => mod.module_id === module_id)) {
                const updatedModules = prevModules
                    .map(module => {
                        if (module.module_id === module_id) {
                            const updatedKeys = module.module_key.includes(key)
                                ? module.module_key.filter(k => k !== key)
                                : [...module.module_key, key];

                            return { ...module, module_key: updatedKeys };
                        }
                        return module;
                    })
                    .filter(module => module.module_key.length > 0);
                return updatedModules;
            } else {
                return [
                    ...prevModules,
                    {
                        module_id: changed_module.id,
                        module_key: [key],
                        module_name: changed_module.module_name,
                    },
                ];
            }
        });
    };

    const createPayload = () => {
        let remove_permissions: any = [];
        let added_permissions: any = [];
        let update_permissions: any = [];
        let payload: any = {}
        if (isEdit && currentRole) {
            currentRole?.permissions.forEach(mod => {
                if (currentRoleModules.filter(m => m.module_id === mod.module_id).length === 0) {
                    remove_permissions.push(mod.module_id);
                }
            });
            currentRoleModules.forEach(mod => {
                if (currentRole?.permissions.filter(m => m.module_id === mod.module_id).length === 0) {
                    added_permissions.push({
                        module_id: mod.module_id,
                        value: mod.module_key
                    });
                }
            });
            currentRoleModules.forEach(module => {
                let mod = currentRole?.permissions.filter(m => m.module_id === module.module_id);
                if (mod && mod.length > 0) {
                    if (!compareTwoArray(mod[0].module_key, module.module_key)) {
                        update_permissions.push({
                            module_id: module.module_id,
                            value: module.module_key,
                        })
                    }
                }
            })
            if (remove_permissions.length > 0) {
                payload.remove_permissions = remove_permissions;
            };
            if (added_permissions.length > 0) {
                payload.add_permissions = added_permissions;
            };
            if (update_permissions.length > 0) {
                payload.update_permissions = update_permissions;
            };
            if (roleName.trim() !== currentRole?.role_name) {
                payload.role_name = roleName;
            }
        } else {
            payload.role_name = roleName;
            payload.permissions = currentRoleModules.map(mod => {
                return {
                    module_id: mod.module_id,
                    value: mod.module_key
                }
            })
        }
        return payload;
    }
    const handleSubmit = () => {
        if (roleName.trim().length > 0) {
            // Edit Role functionality
            let payload = createPayload();
            if (isEdit && isUpdate && currentRole) {
                dispatch(updateRoleAPI({
                    role_id: currentRole.role_id,
                    payload
                }));
            } else {
                //create role functionality
                dispatch(createRoleAPI({ payload }));
            }
            setOpenPermissionModal(false);
        } else {
            setShowError(true);
        }

    }
    const compareTwoArray = (arr1: String[], arr2: String[]) => {
        if (arr1.length !== arr2.length) return false;

        const countMap = new Map();

        for (const val of arr1) {
            countMap.set(val, (countMap.get(val) || 0) + 1);
        }

        for (const val of arr2) {
            if (!countMap.has(val) || countMap.get(val) === 0) return false;
            countMap.set(val, countMap.get(val) - 1);
        }

        return Array.from(countMap.values()).every(count => count === 0);
    }

    const checkPermissionForRole = (module_id: number, key: string) => {
        return currentRoleModules.filter(permission => permission.module_id === module_id && permission.module_key.includes(key)).length > 0;
    }
    const columns: TableColumnsType<RoleModuleType> = [
        {
            title: "Module Name",
            dataIndex: "module_name",
        },
        {
            title: "Permissions",
            dataIndex: "key",
            render: (record, data) => (
                <div style={{ display: "flex" }}>
                    {record.map((permission: string) => (
                        <div style={{ display: "flex", marginRight: "20px" }} onClick={() => handlePermissionChange(data.id, permission, data)}>
                            <Checkbox checked={checkPermissionForRole(data.id, permission)} />
                            <div style={{ marginLeft: "5px" }}>{permission}</div>
                        </div>
                    ))}
                </div>
            ),
        },

    ]
    return (
        <div>
            <Modal
                isClose={true}
                onClose={
                    () => { setOpenPermissionModal(false) }
                }
            >
                {loading ?
                    <div style={{ height: "650px", width: "725px" }} className='center_div'><Loader /></div>
                    :
                    <>
                        <div className='role_name_input_container'>
                            <div className='group-photo'>Role Name</div>
                            <Input
                                placeholder="Enter Role"
                                type="text"
                                name="role Name"
                                id="roleName"
                                value={roleName}
                                onChange={(e) => { setRoleName(e.target.value) }}
                                iconSrc={image.USER_NAME_ICON}
                                showIcon={true}
                            />
                            {showError && roleName.trim().length <= 0 && <ErrorText message='Please provide role name' />}
                        </div>
                        <div style={{ width: "700px", paddingTop: "20px", height: "600px", overflowY: "auto" }}>
                            <div className='group-photo'>Permissions</div>
                            <TableComponent
                                columns={columns}
                                loading={loading}
                                dataSource={allModules}
                                pageNumbers={[1]}
                                handlePageLink={() => { }}
                                isPagination={false}
                                totalProfile={allModules.length}
                                prevPage={() => { }}
                                nextPage={() => { }}
                                activePage={1}
                                currentTotal={allModules.length}
                            />
                            {/* <Button></Button> */}
                        </div>
                        {
                            isEdit && isUpdate && <div className='center_div'>
                                <div className='green_filled_btn' onClick={() => handleSubmit()}>Update</div>
                            </div>
                        }
                        {
                            !isEdit && <div className='center_div'>
                                <div className='green_filled_btn' onClick={() => handleSubmit()}>Create</div>
                            </div>
                        }
                    </>
                }
            </Modal>
        </div>
    );
};

export default RolesPermissionModal;
