import React, { useEffect, useRef, useState } from 'react'
import { image } from '../../../app/utils/common/image'
import Card from '../../atoms/Card/Card';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { getAllManagers, getTopManagers } from '../../../redux/API/ManagerAPI';
import { ManagerType } from '../../../dto/Manager.dto';
import { getImageURL } from '../../../app/utils/common/getImageUrl';
import { setManagerPagination } from '../../../redux/Slices/managerSlice';
import { PaginationType } from '../../../dto/Pagination.dto';
import Loader from '../../atoms/Loader/Loader';
import EmptyPage from '../../atoms/EmptyPage/EmptyPage';



const ManagerDataComponent = () => {
    const [dropDown, setDropDown] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>({
        total_data: 10,
        current_page: 1,
        page_size: 10,
        total_pages: 1,
    })
    const menuRef = useRef<HTMLDivElement>(null)
    const [managers, setManagers] = useState<ManagerType[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const scrollRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
        ) {
            setDropDown(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        dispatch(getTopManagers({ page: pagination.current_page })).unwrap().then((data: {
            top_managers: ManagerType[],
            pagination: PaginationType
        }) => {
            if (data.pagination.current_page === pagination.current_page) {
                if (data.pagination.current_page === 1) {
                    setPagination(data.pagination);
                    setManagers(data.top_managers);
                } else {
                    setManagers([...managers, ...data.top_managers]);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [dispatch, pagination?.current_page]);
    useEffect(() => {
        if (dropDown) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dropDown])


    // useEffect(() => {
    //     dispatch(getAllManagers({ page: pagination?.current_page }));

    // }, [dispatch, pagination?.current_page])

    // pagination
    let debounceTimer: NodeJS.Timeout;
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

            if (scrollPercentage >= 0.7 && !loading) {
                // Clear the previous debounce timer
                clearTimeout(debounceTimer);

                // Set a new debounce timer
                debounceTimer = setTimeout(() => {
                    if (!loading && pagination.current_page < pagination.total_pages) {
                        setPagination({
                            ...pagination,
                            current_page: pagination.current_page + 1,
                        });
                    }
                }, 500); // 0.7 seconds debounce time
            }
        }
    };

    return (
        <div className='top_managers_and_brokers_list_container' ref={scrollRef} onScroll={handleScroll}>
            {loading && pagination.current_page === 1 ?
                <div className='center_div'><Loader isGray={true} /></div>
                :
                managers.length > 0 ?
                    managers.map((manager) => (
                        <div className='user_data_container'>
                            <div className='user_profile_container'>
                                <div className='profile_image_container'>
                                    <img className='profile_image'
                                        src={manager.profile_image ? getImageURL(manager.profile_image) : image.DEFAULT_PROFILE_IMAGE} />
                                </div>
                                <div className='profile_data'>
                                    <div className='user_name'>
                                        {manager.name}
                                    </div>
                                    <div className='sub_data'>
                                        {manager.country_code} {manager.phone_number}
                                    </div>
                                </div>
                                <div className='data_number'>
                                    {manager.total_projects} Sites
                                </div>
                            </div>
                            <div key={manager.manager_id} className='action_button_container' >
                                <div className='dot_container'
                                // onClick={(e) => {
                                //     e.stopPropagation();
                                //     setDropDown(!dropDown);
                                // }}
                                >

                                    {/* <div className='action_dot'></div>
                            <div className='action_dot'></div>
                            <div className='action_dot'></div> */}
                                </div>
                                {
                                    dropDown && <div className='action_dropdown' ref={menuRef}></div>
                                }
                            </div>
                        </div>
                    ))
                    : <div><EmptyPage image={image.NO_MANAGER_ADDED_YET_IMAGE} text='No Data Found' /></div>
            }

            {
                loading && pagination.current_page > 1 && <div className='loading_more_data'>Loading More data...</div>
            }

        </div>
    )
}


const ManagerList = () => {

    return (
        <Card>
            <div >
                <ManagerDataComponent />
            </div>
        </Card>
    )
}

export default ManagerList