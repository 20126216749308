import React, { useState } from 'react'
import styled from 'styled-components'
import SearchBar from '../molecules/Navbar/Searchbar';
import Notification from '../molecules/Navbar/Notification';
import ProfileSection from '../molecules/Navbar/ProfileSection';
import { image } from '../../app/utils/common/image';
import { useAppDispatch, useAppSelector } from '../../app/utils/hooks/reduxHook';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllUsersAPI } from '../../redux/API/AllUsersAPI';
import { setAllUsersLoading } from '../../redux/Slices/allUsersSlice';
import { setBrokerLoading } from '../../redux/Slices/brokerSlice';
import { getAllBrokers } from '../../redux/API/BrokerAPI';
import { setManagerLoading } from '../../redux/Slices/managerSlice';
import { getAllManagers } from '../../redux/API/ManagerAPI';
const NavbarContainer = styled.div`

position:relative;
width:98%;
display:flex;
align-items:center;
justify-content:space-between;
margin-left:24px;
@media screen and (max-width: 1000px) {
        display: none;
    }
`
const ProfilerContainer = styled.div`

// position:absolute;
top:30px;
right:20px;
height:50px;
display:flex;
flex-direction:row;
margin-right:25px;
`
const SearchBarContainer = styled.div`
// position:absolute;
top:30px;
left:10px;
`;
const Navbar: React.FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const path = location.pathname;
    const { allUsers, pagination } = useAppSelector((state) => state.allUsers);
    const { brokers, pagination: brokerPagination } = useAppSelector((state) => state.broker);
    const { managers, pagination: managerPagination } = useAppSelector((state) => state.manager)

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx"


    const exportAllUsers = async () => {
        if (!pagination.total_pages) {
            // console.warn("No pagination data available.");
            return;
        }
        dispatch(setAllUsersLoading(true));
        let allUserData: any[] = [];

        try {
            // Loop through all pages and fetch data
            for (let page = 1; page <= pagination.total_pages; page++) {
                const response: any = await dispatch(
                    getAllUsersAPI({ page, total_page: pagination.page_size, role_id: 2 })
                ).unwrap();

                if (response?.user_data) {
                    allUserData = [...allUserData, ...response.user_data];
                }
            }

            // Prepare the data for Excel export
            const userData = allUserData.map((user) => ({
                "Name": user.name || "",
                "Referral Code": user.referral_code || "",
                "Phone Number": `${user.country_code || ""} ${user.phone_number || ""}`,
                "City": user.city || "",
                "Area": user.leaving_area || "",
                "Referrer's Name": user.referrer_name || "",
                "Referrer's Phone Number": user.referrer_phone_number || "",
                "Referred By": user.refer_by || "",
            }));

            // Generate Excel file
            const worksheet = XLSX.utils.json_to_sheet(userData);
            const workbook = { Sheets: { "All Users": worksheet }, SheetNames: ["All Users"] };
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });

            // Download the Excel file
            FileSaver.saveAs(data, `AllUsersData${fileExtension}`);
            dispatch(setAllUsersLoading(false));
        } catch (error) {
            // console.error("Error exporting users:", error);
        } finally {
            dispatch(setAllUsersLoading(false));
        }
    };

    const exportAllBrokers = async () => {
        if (!brokerPagination.total_pages) {
            console.warn("No pagination data available.");
            return;
        }
        dispatch(setBrokerLoading(true));
        let allBrokerData: any[] = [];

        try {
            // Loop through all pages and fetch data
            for (let page = 1; page <= brokerPagination.total_pages; page++) {
                const response: any = await dispatch(
                    getAllBrokers({ page, limit: brokerPagination.page_size })
                ).unwrap();

                if (response?.cp_data) {
                    allBrokerData = [...allBrokerData, ...response.cp_data];
                }
            }

            // Prepare the data for Excel export
            const brokerData = allBrokerData.map((broker) => ({
                "Name": broker.name || "",
                "Referral Code": broker.referral_code || "",
                "Phone Number": `${broker.country_code || ""} ${broker.phone_number || ""}`,
                "Site Visits": broker.site_visits,
                "Referrer's Name": broker.referrer_name || "",
                "Referrer's Phone Number": broker.referrer_phone_number || "",
                "Referred By": broker.refer_by || "",
            }));

            // Generate Excel file
            const worksheet = XLSX.utils.json_to_sheet(brokerData);
            const workbook = { Sheets: { "All Brokers": worksheet }, SheetNames: ["All Brokers"] };
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });

            // Download the Excel file
            FileSaver.saveAs(data, `allBrokerData${fileExtension}`);
            dispatch(setBrokerLoading(false));
        } catch (error) {
            console.error("Error exporting brokers:", error);
        } finally {
            dispatch(setBrokerLoading(false));
        }
    }

    const exportAllManagers = async () => {
        if (!managerPagination.total_pages) {
            // console.warn("No pagination data available.");
            return;
        }
        dispatch(setManagerLoading(true));
        let allManagerData: any[] = [];

        try {
            // Loop through all pages and fetch data
            for (let page = 1; page <= managerPagination.total_pages; page++) {
                const response: any = await dispatch(
                    getAllManagers({ page, total_page: managerPagination.page_size })
                ).unwrap();

                if (response?.manager_data) {
                    allManagerData = [...allManagerData, ...response.manager_data];
                }
            }

            // Prepare the data for Excel export
            const managerData = allManagerData.map((manager) => ({
                "Name": manager.name || "",
                "Referral Code": manager.referral_code || "",
                "Phone Number": `${manager.country_code || ""} ${manager.phone_number || ""}`,
                "Broker Allocated": manager?.cps_allocated,
                "Total Projects": manager?.total_projects,
                "Referrer's Name": manager.referrer_name || "",
                "Referrer's Phone Number": manager.referrer_phone_number || "",
                "Referred By": manager.refer_by || "",
            }));

            // Generate Excel file
            const worksheet = XLSX.utils.json_to_sheet(managerData);
            const workbook = { Sheets: { "All Managers": worksheet }, SheetNames: ["All Managers"] };
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });

            // Download the Excel file
            FileSaver.saveAs(data, `allManagerData${fileExtension}`);
            dispatch(setManagerLoading(false));
        } catch (error) {
            console.error("Error exporting managers:", error);
        } finally {
            dispatch(setManagerLoading(false));
        }
    }
    return (
        <NavbarContainer>
            <SearchBarContainer>
                <SearchBar />
            </SearchBarContainer>
            <ProfilerContainer>
                {path === '/dashboard/all-users' && <div onClick={exportAllUsers} style={{ marginRight: "20px", marginTop: "10px", cursor: "pointer" }}>
                    <img src={image.EXCEL_ICON} className='excel-icon get-help-icon' />
                    {/* <div className="get-help-excel"> Export as Excel </div> */}
                </div>}

                {path === '/dashboard/manage-broker' && <div onClick={exportAllBrokers} style={{ marginRight: "20px", marginTop: "10px", cursor: "pointer" }}>
                    <img src={image.EXCEL_ICON} className='excel-icon get-help-icon' />
                </div>}

                {path === '/dashboard/manage-manager' && <div onClick={exportAllManagers} style={{ marginRight: "20px", marginTop: "10px", cursor: "pointer" }}>
                    <img src={image.EXCEL_ICON} className='excel-icon get-help-icon' />
                </div>}

                <Notification />
                <ProfileSection />
            </ProfilerContainer>
        </NavbarContainer>
    )
}

export default Navbar