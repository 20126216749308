import { Card } from 'antd'
import React, { useEffect, useState } from 'react'
import { getImageURL } from '../../../app/utils/common/getImageUrl'
import { image } from '../../../app/utils/common/image'
import { useAppSelector } from '../../../app/utils/hooks/reduxHook'
import { useNavigate, useParams } from 'react-router-dom'
import { PostType } from '../../../dto/post.dto'
import moment from 'moment'


const PostRequestDetailsCard = () => {

    const { pendingPosts, rejectedPosts, activePost } = useAppSelector(state => state.post);
    const { id } = useParams<{ id: string }>();
    const [currentPost, setCurrentPost] = useState<PostType>();
    const navigate = useNavigate();


    useEffect(() => {

        if (id && pendingPosts.length > 0 || activePost.length > 0 || rejectedPosts.length > 0) {
            const pending_post = pendingPosts.find((post) => post.post_id.toString() === id);
            const accepted_post = activePost.find((post) => post.post_id.toString() === id);
            const rejected_post = rejectedPosts.find((post) => post.post_id.toString() === id);
            if (pending_post) {
                setCurrentPost(pending_post);
            } else if (accepted_post) {
                setCurrentPost(accepted_post);
            } else if (rejected_post) {
                setCurrentPost(rejected_post);
            } else {
                navigate("/dashboard/post-requests")
            }
        } else {
            navigate("/dashboard/post-requests")
        }
    })

    return (
        <div className='post-card_PostRequestDetailsCard'>
            <div className='post_details_Data'>
                <div className='profile'>
                    <div className='profile_image_container'>
                        <img src={currentPost?.profile_image ? getImageURL(currentPost.profile_image) : image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                    </div>
                    <div className='data_container'>
                        <div className='profile_data_container'>
                            <div className='profile_name'>{currentPost?.name}</div>
                            <div className='profile_contact'>{currentPost?.country_code} {currentPost?.phone_number}</div>
                            <div className=' profile_time'>
                                {currentPost?.created_at
                                    ? moment(currentPost.created_at).fromNow()
                                    : 'Unknown time'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='post-details-card-description'>
                    {currentPost?.description}
                </div>
                <div className='post-details-images-container'>
                    {
                        currentPost?.media.map(img => (
                            <>
                                {
                                    img.type === 2 ?
                                        <div>
                                            <video
                                                className='video-project'
                                                controls={true}
                                                autoPlay={false}
                                                width="275"
                                            >
                                                <source src={getImageURL(img.url)} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>

                                        :
                                        <div>
                                            <img className='project-card-images' src={getImageURL(img.url)}
                                                onError={(e: any) => e.target.src = image.BackgroundImage} />
                                        </div>
                                }


                            </>

                        ))
                    }
                </div>
            </div>

            {/* <div className='reject-accept-btn_container'>
                <div className='reject-accept-btn_postDetails'>
                    <Button
                        buttonTitle="Reject"
                        onClick={() => { }}
                        className={'RejectButtonPostDetails'}
                    />
                    <Button
                        buttonTitle="Accept"
                        onClick={() => { }}
                        className={'RejectButton AcceptButton'}
                    />
                </div>
            </div> */}
        </div >

    )
}

export default PostRequestDetailsCard
