import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook'
import { ProjectListType } from '../../../dto/Projects.dto';
import { getImageURL } from '../../../app/utils/common/getImageUrl';
import Card from '../../atoms/Card/Card';
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';
import LoaderScreen from '../../atoms/LoaderScreen/LoaderScreen';
import { setProjectPagination } from '../../../redux/Slices/projectSlice';
import { PaginationType } from '../../../dto/Pagination.dto';
import Loader from '../../atoms/Loader/Loader';
import { getAllActiveProjects } from '../../../redux/API/ProjectAPI';
import EmptyPage from '../../atoms/EmptyPage/EmptyPage';

interface ProjectListProps {
    // projects: ProjectListType[],
    isActive: boolean,
    // projectPagination: PaginationType,
}

const ProjectList: React.FC<ProjectListProps> = ({ isActive = false }) => {

    const dispatch = useAppDispatch();
    const { loading, ActiveProjects, pagination } = useAppSelector(state => state.project)
    const MAX_VISIBLE_IMAGES = 4;  // Limit of visible images
    const navigate = useNavigate()
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(setProjectPagination({
            ...pagination,
            current_page: 1
        }))
        fetchProjects(1);
    }, [])

    useEffect(() => {
        if (pagination.current_page > 1)
            fetchProjects(pagination.current_page);
    }, [dispatch, pagination.current_page]);

    const fetchProjects = async (current_page: number) => {
        try {
            dispatch(getAllActiveProjects({ status: "Active", current_page, limit: pagination.page_size }))

        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {

        }
    };


    let debounceTimer: NodeJS.Timeout;
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

            if (scrollPercentage >= 0.7 && !loading) {
                // Clear the previous debounce timer
                clearTimeout(debounceTimer);

                // Set a new debounce timer
                debounceTimer = setTimeout(() => {
                    if (!loading && pagination.current_page < pagination.total_pages) {
                        dispatch(setProjectPagination({
                            ...pagination,
                            current_page: pagination.current_page + 1,
                        }));
                    }
                }, 500); // 0.7 seconds debounce time
            }
        }
    };
    return (
        <div>

            <div ref={scrollRef} onScroll={handleScroll} className={isActive ? 'active_projects_container' : 'completed_project_container'}>
                {loading && pagination.current_page === 1 ? <div style={{ height: "100%", width: "100%" }} className='center_div'><Loader /></div> :
                    ActiveProjects.length === 0 ?
                        <div style={{ height: "90%", width: "40%", marginLeft: "20%" }} className='center_div'><EmptyPage image={image.NO_SITE_VISITED_YET_IMAGE} text='No Site Available.' /></div>
                        :
                        ActiveProjects.map(project => {
                            const managerProfiles = project.manager_profiles || [];
                            const visibleProfiles: any = managerProfiles.slice(0, MAX_VISIBLE_IMAGES) || [];
                            const remainingProfiles = managerProfiles.length - MAX_VISIBLE_IMAGES;
                            return (
                                <Card customClass='project_card' isGreen={isActive}>
                                    <div className='post-card site-card' onClick={() => navigate(`/dashboard/site-management/site/${project.project_id}`)}>
                                        <div style={{ display: "contents" }}>
                                            <div className='post-thumbnail-container'>
                                                <img className='project-card-thumbnail' src={getImageURL(project.url)} onError={(e: any) => e.target.src = image.DEFAULT_BACKGROUND_IMAGE} />
                                            </div>
                                            <div className='site-visited-title'>{project.name}</div>
                                            <div className='site-visited-desc'>{project.address}, {project.city}, {project.state}, {project.zip_code}</div>
                                            <div className='site-visited-group'>
                                                <div className='group_list_container total-list-site'>
                                                    {visibleProfiles?.map((profile: string, index: number) => (
                                                        <img
                                                            key={index}
                                                            src={profile ? getImageURL(profile) : image.DEFAULT_PROFILE_IMAGE}
                                                            alt={`Manager ${index + 1}`}
                                                            className="profile-image"
                                                        />
                                                    ))}
                                                    {remainingProfiles > 0 && (
                                                        <div className="total_members_class total-member-site">
                                                            +{remainingProfiles}
                                                        </div>
                                                    )}
                                                </div>
                                                {visibleProfiles.length > 0 && <div className='site-visited-manager'>Managers</div>}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}
            </div>
            {
                loading && pagination.current_page > 1 && <div className='loading_more_data'>Loading more data...</div>
            }

        </div>
    )
}

export default ProjectList