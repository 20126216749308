import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { showErrorToast } from "../../../app/utils/common/Toast";
import { getSeenNotificationAPI, getUnseenNotificationAPI, SendNotificationAPI, storeDeviceTokenAPI } from "../../API/NotificationAPI";
import { NotificationState } from "../../Slices/notificationSlice";

export const NotificationReducer = (
  builder: ActionReducerMapBuilder<NotificationState>
) => {
  builder
    .addCase(SendNotificationAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(SendNotificationAPI.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
    })
    .addCase(SendNotificationAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
        state.loading = false;
      } else {
        showErrorToast("Error in notification...");
      }
    })

    .addCase(storeDeviceTokenAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(storeDeviceTokenAPI.fulfilled, (state, action: PayloadAction<any>) => {
          state.success = action.payload
        state.loading = false;
      })
      .addCase(storeDeviceTokenAPI.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
          state.loading = false;
        } else {
          showErrorToast("Error in store Device Token API...");
        }
      })
  
      .addCase(getSeenNotificationAPI.pending, (state) => {
        state.loading = true;
      })
    .addCase(getSeenNotificationAPI.fulfilled, (state, action: PayloadAction<any>) => {
        state.seenNotifications = action.payload.notification_data
        state.pagination = action.payload.pagination
          state.loading = false;
      })
      .addCase(getSeenNotificationAPI.rejected, (state, action: PayloadAction<any>) => {
          if (action.payload.message) {
            showErrorToast(action.payload.message);
            state.loading = false;
          } else {
            showErrorToast("Error in get Seen NotificationAPI API...");
          }
      })
  
      .addCase(getUnseenNotificationAPI.pending, (state) => {
        state.loading = true;
      })
    .addCase(getUnseenNotificationAPI.fulfilled, (state, action: PayloadAction<any>) => {
        state.unseenNotifications = action.payload.notification_data
        state.pagination = action.payload.pagination
        state.unseenNotificationCount = action.payload.pagination.total_data
        state.loading = false;
      })
      .addCase(getUnseenNotificationAPI.rejected, (state, action: PayloadAction<any>) => {
          if (action.payload.message) {
            showErrorToast(action.payload.message);
            state.loading = false;
          } else {
            showErrorToast("Error in get unSeen NotificationAPI API...");
          }
      })
  
};
