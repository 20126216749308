export const searchPath = {
  SITE_MANAGEMENT: "/dashboard/site-management",
  MANAGE_MANAGER: "/dashboard/manage-manager",
  MANAGE_BROKER: "/dashboard/manage-broker",
  MANAGE_GROUP: "/dashboard/manage-group",
  ALLOCATE_BROKER: "/dashboard/manage-manager/manager/broker/BrokerUnderManagerTable",
  AVAILABLE_MANAGER: "/dashboard/manage-group/create",
  REGISTRATION_REQUEST: "/dashboard/registration-request",
  ALL_USERS: "/dashboard/all-users",
  USER_MANAGEMENT: "/dashboard/manage-user-management"
};
