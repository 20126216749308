import React, { FC, useEffect, useState } from 'react'
import { TableColumnsType } from 'antd'
import { image } from '../../../../app/utils/common/image'
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook'
import { setavailableCPsPagination, setManagerFilters, setManagerPagination } from '../../../../redux/Slices/managerSlice'
import { getImageURL } from '../../../../app/utils/common/getImageUrl'
import { getAvailableManagers } from '../../../../redux/API/GroupApi'
import { AvailableManagersType } from '../../../../dto/Groups.dto'
import TableComponent from '../../../atoms/TableComponent/Tablecomponent'
import Checkbox from '../../../atoms/Checkbox/Checkbox'
import "./AddManagerUnderGroup.scss"
import { getAllManagers } from '../../../../redux/API/ManagerAPI'
import FilterModal from '../../../atoms/FilterModal/FilterModal'

interface ManagerUnderGroupTableProps {
    selectedManager: number[],
    setSelectedManager: React.Dispatch<React.SetStateAction<number[]>>;
    setManagerData: React.Dispatch<React.SetStateAction<any>>;
    setShowManagerTable: React.Dispatch<React.SetStateAction<boolean>>;
    managers: any[]
    pagination: any
    groupManager: any,
    loadingManager: boolean
}

const ManagerUnderGroupTable: FC<ManagerUnderGroupTableProps> = (props) => {
    const { selectedManager, setSelectedManager, setManagerData, setShowManagerTable, managers, pagination, loadingManager

    } = props
    const dispatch = useAppDispatch();
    const { managerFilter } = useAppSelector(state => state.manager);
    const { searchText } = useAppSelector(state => state.search);
    const [filterModal, setFilterModal] = useState<boolean>(false);

    useEffect(() => {
        if (!loadingManager)
            dispatch(getAllManagers({ page: pagination.current_page }));
    }, [pagination.current_page]);

    const handleSelection = (id: number) => {
        if (selectedManager.includes(id)) setSelectedManager(selectedManager.filter(_id => id !== _id));
        else setSelectedManager([...selectedManager, id]);
    }

    const handleSelectAllManager = () => {
        const currentManagerIds = managers.map(record => record.manager_id);
        const allSelected = currentManagerIds.every(id => selectedManager.includes(id));
        if (allSelected) {
            // Remove current page's records from selectedCps
            setSelectedManager(selectedManager.filter(id => !currentManagerIds.includes(id)));
        } else {
            // Add current page's records to selectedCps, avoiding duplicates
            const updatedSelection = Array.from(new Set([...selectedManager, ...currentManagerIds]));
            setSelectedManager(updatedSelection);
        }
    };
    const filterModalOptions = [
        {
            key: "top_performer",
            value: "Top Performer"
        },
        {
            key: "bottom_performer",
            value: "Bottom Performer"
        }
    ]
    const columns: TableColumnsType<AvailableManagersType> = [
        {
            title: 'Manager Profile',
            dataIndex: 'Photo',
            render: (text, record) => {
                return (
                    <img
                        src={record.profile_image ? getImageURL(record.profile_image) : image.DEFAULT_PROFILE_IMAGE}
                        alt="Profile"
                        className='reg-profile'
                        onClick={() => { }}
                    />
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Referral Code',
            dataIndex: 'referral_code',
            render: (text, record) => (
                <div>{record.referral_code}</div>
            )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
        },
        {
            title: 'Brokers',
            dataIndex: 'cps_allocated',
        },
        {
            title: 'Sites Managing',
            dataIndex: 'total_projects',
            render: (text, record) => (
                <div>{record.total_projects}</div>
            )
        },
        {
            title: 'Site Visits',
            dataIndex: 'site_visit_count',
            render: (text, record) => (
                <div>{record.site_visit_count}</div>
            )
        },
        {
            title: 'Referral Name',
            dataIndex: 'referrer_name',
            render: (text, record) => (
                <div>{record.referrer_name}</div>
            )
        },
        {
            title: 'Referrer Phone Number',
            dataIndex: 'referrer_phone_number',
            render: (text, record) => (
                <div>{record.referrer_phone_number}</div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <div>
                    <Checkbox checked={selectedManager.includes(record.manager_id)} onChange={() => { handleSelection(record.manager_id) }} />
                </div>
            ),
        },
    ];

    const handlePageChange = (pageNumber: number) => {
        dispatch(setManagerPagination({ ...pagination, current_page: pageNumber }))
    };

    const handleAllocateCp = () => {
        if (selectedManager.length > 0) {
            const selectedManagerData = managers.filter(manager => selectedManager.includes(manager.manager_id));
            setManagerData(selectedManagerData);
            setShowManagerTable(false)
        }
    }
    const fetchFilterData = (sortBy: string) => {
        dispatch(getAllManagers({ page: 1, sortBy: sortBy, searchText }));
        dispatch(setManagerFilters(sortBy))
    }

    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='broker_under_manager_header'>
                    <img src={image.BACK_ICON} className='back_icon' onClick={() => setShowManagerTable(false)} />
                    <div className='allocate_broker'>Allocate Manager</div>
                </div>
            </div>


            <div className='button_container'>
                <div className='btn' onClick={() => setFilterModal(!filterModal)}>
                    {(managerFilter !== "" || null || undefined) && <div className='filter_count'><span>1</span></div>}
                    <img className='btn_icon' src={image.FILTER_ICON} />
                    <div>
                        Filter
                    </div>
                </div>
            </div>
            <div className='select-all-button-container'>
                <p>Select All</p>
                <Checkbox
                    //checks if every record on the current page is in selectedCps
                    checked={managers.every(record => selectedManager.includes(record.manager_id))}

                    onChange={handleSelectAllManager}
                />
            </div>

            <div className='manager_dataTable'>
                <div>
                    <TableComponent columns={columns}
                        dataSource={managers ?? []}
                        loading={loadingManager}
                        emptyText='No Any Manager available!'
                        pageNumbers={Array.from({ length: pagination.total_pages }, (_, index) => index + 1)}
                        handlePageLink={handlePageChange}
                        isPagination={pagination.total_pages > 1}
                        totalProfile={pagination.total_data}
                        prevPage={() => handlePageChange(pagination.current_page - 1)}
                        nextPage={() => handlePageChange(pagination.current_page + 1)}
                        activePage={pagination.current_page}
                        currentTotal={Math.min(pagination.current_page * pagination.page_size, (managers ?? []).length)} />
                </div>
            </div>

            {
                selectedManager.length > 0 &&
                <div className='center_div create_project_btn_container' style={{ marginBottom: "10px" }}>
                    <button className='cancel_btn' onClick={() => { setSelectedManager([]); setManagerData([]) }}>
                        Cancel
                    </button>
                    <button className='submit_btn' onClick={() => handleAllocateCp()}>
                        Allocate
                    </button>
                </div>
            }
            {filterModal && (
                <div className='filter_modal_wrapper'>
                    <FilterModal
                        options={filterModalOptions}
                        handleSubmit={(data) => {
                            console.log(data)
                            fetchFilterData(data.key);
                            setFilterModal(false);
                        }}
                        handleRemoveFilter={() => {
                            dispatch(getAllManagers({ page: 1, limit: 15 }))
                            setFilterModal(false);
                        }}
                        filterType='manager'
                        containerClassName='filter_modal_container_2'
                        onOutSideClick={() => setFilterModal(false)}
                    />

                </div>
            )}
        </div>
    )
}

export default ManagerUnderGroupTable