import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  createProjectAPI,
  deleteMedia,
  getProjectGroupsAPI,
  updateSiteAPI,
  uploadFile,
} from "../../../../redux/API/ProjectAPI";
import { image } from "../../../../app/utils/common/image";

import "./CreateProject.scss";
import Checkbox from "../../../atoms/Checkbox/Checkbox";
import AddButton from "../../../atoms/CustomButton/AddButton/AddButton";
import { managers } from "../Modals/SelectManagers";
import CustomRadioButton from "../../../atoms/RadioButton/Radio";

import AddGroupTable from "../Modals/SelectGroupTable";
import Modal from "../../../atoms/Modal/Modal";
import {
  MediaItemType,
  ProjectGroupType,
  UpdateProjectPayloadType,
  VideoItemType,
} from "../../../../dto/Projects.dto";
import { getImageURL } from "../../../../app/utils/common/getImageUrl";
import EmptyPage from "../../../atoms/EmptyPage/EmptyPage";
import ActionIcon from "../../../atoms/ActionIcon/ActionIcon";
import ConfirmModal from "../../../atoms/confirmation-modal/confirmation-modal";
import OptionMenu from "../../../atoms/OptionMenu/OptionMenu";
import { confirmModalProps } from "../../../../dto/confirm";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/utils/hooks/reduxHook";
import {
  getAllGroupsManagers,
  getAllGroupsManagersCPs,
} from "../../../../redux/API/GroupApi";
import { ManagerType } from "../../../../dto/Manager.dto";
import Loader from "../../../atoms/Loader/Loader";
import ErrorText from "../../../atoms/Error/error";
import LoaderScreen from "../../../atoms/LoaderScreen/LoaderScreen";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../app/utils/common/Toast";
import { useNavigate } from "react-router-dom";
import { PaginationType } from "../../../../dto/Pagination.dto";

// dummy manager data
const MAX_VISIBLE_IMAGES = 4;
const CreateProjectModal: React.FC<{
  type: string;
}> = ({ type }) => {
  const { SingleProject } = useAppSelector((state) => state.project);
  const [screen, setScreen] = useState(1);
  const [selectedThumbnail, setSelectedThumbnail] = useState<
    File | undefined
  >();
  const [projectDescription, setProjectDescription] = useState({
    title: type === "edit" ? SingleProject?.name ?? "" : "",
    description: type === "edit" ? SingleProject?.description ?? "" : "",
    incentive: type === "edit" ? SingleProject?.incentive ?? "" : "",
    unit_price: type === "edit" ? SingleProject?.unit_price ?? "" : "",
  });
  const [projectAddress, setProjectAddress] = useState({
    email: "",
    address: type === "edit" ? SingleProject?.address ?? "" : "",
    state: type === "edit" ? SingleProject?.state ?? "" : "",
    city: type === "edit" ? SingleProject?.city ?? "" : "",
    phone: type === "edit" ? SingleProject?.phone_number.toString() ?? "" : "",
    zipcode: type === "edit" ? SingleProject?.zip_code.toString() ?? "" : "",
  });
  const [trainingVideos, setTrainingVideos] = useState<File[]>([]);
  const [newSiteFlyers, setNewSiteFlyers] = useState<{
    type: Number,
    file: File,
    thumbnail?: File,
    url: string,
  }[]>([]);
  const [trainingVideoThumbnails, setTrainingVideoThumbnails] = useState<
    File[]
  >([]);
  const [newProjectBrochure, setNewProjectBrochure] = useState<File[]>([]);
  const [newSitePhotos, setNewSitePhotos] = useState<File[]>([]);

  const [selectedGroups, setSelectedGroups] = useState<ProjectGroupType[]>([]);
  const [isSelectGroupModalOpen, setIsSelectGroupModalOpen] =
    useState<boolean>(false);

  const inputSiteMediaRef = useRef<HTMLInputElement>(null);
  const inputSiteFlyerRef = useRef<HTMLInputElement>(null);
  const inputBrochureRef = useRef<HTMLInputElement>(null);
  const inputTrainingVideoRef = useRef<HTMLInputElement>(null);
  const [currentSelectedGroup, setCurrentSelectedGroup] =
    useState<ProjectGroupType>();
  const [groupManagers, setGroupManagers] = useState<ManagerType[]>([]);
  const [managerLoading, setManagerLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [groupPagination, setGroupPagination] = useState<PaginationType>({
    total_data: 10,
    total_pages: 1,
    current_page: 1,
    page_size: 10,
  });
  const [currentProjectGroup, setCurrentProjectGroup] = useState<
    ProjectGroupType[]
  >([]);
  const [groupsLoading, setGroupLoading] = useState<boolean>(false);
  const [removedGroup, setRemovedGroup] = useState<ProjectGroupType[]>([]);
  const [removedSiteImages, setRemovedSiteImages] = useState<MediaItemType[]>(
    []
  );
  const [removedPDFs, setRemovedPDFs] = useState<MediaItemType[]>([]);
  const [removedFlyers, setRemovedFlyers] = useState<MediaItemType[]>([]);
  const [removedTrainingVideo, setRemovedTrainingVideo] = useState<
    VideoItemType[]
  >([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [discardChangeModal, setDiscardChangeModal] = useState<boolean>(false);
  const confirmModalProps: confirmModalProps = {
    text: "Are you sure you want to discard all changes",
    confirmModal: discardChangeModal,
    setConfirmModal: setDiscardChangeModal,
    buttons: ["Yes, Discard", "Cancel"],
    onPressPositive: () => {
      setDiscardChangeModal(false);
      handleCancel();
    },
    onPressNegative: () => {
      setDiscardChangeModal(false);
    },
    image: image.REMOVE_GROUP_ICON,
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (
      currentSelectedGroup &&
      (selectedGroups.filter(
        (grp) => grp.group_id === currentSelectedGroup.group_id
      ).length > 0 ||
        currentProjectGroup.filter(
          (grp) => grp.group_id === currentSelectedGroup.group_id
        ).length > 0)
    ) {
      setManagerLoading(true);
      dispatch(
        getAllGroupsManagers({ group_id: currentSelectedGroup.group_id })
      )
        .unwrap()
        .then((data: ManagerType[]) => {
          setGroupManagers(data);
        })
        .catch(() => { })
        .finally(() => {
          setManagerLoading(false);
        });
    }
  }, [currentSelectedGroup]);
  useEffect(() => {
    if (handleDataChange()) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
  }, [
    JSON.stringify(projectAddress),
    JSON.stringify(projectDescription),
    newSiteFlyers,
    newSitePhotos,
    newProjectBrochure,
    trainingVideos,
    selectedGroups,
    removedGroup,
    removedPDFs,
    removedSiteImages,
    removedTrainingVideo,
    removedFlyers
  ]);

  useEffect(() => {
    if (type === "edit" && SingleProject) {
      setGroupLoading(true);
      dispatch(
        getProjectGroupsAPI({
          project_id: SingleProject.project_id,
          current_page: groupPagination.current_page,
        })
      )
        .unwrap()
        .then(
          (data: {
            groups_data: ProjectGroupType[];
            pagination: PaginationType;
          }) => {
            if (data.pagination.current_page === 1) {
              setGroupPagination(data.pagination);
              setCurrentProjectGroup(data.groups_data);
            } else {
              setCurrentProjectGroup([
                ...currentProjectGroup,
                ...data.groups_data,
              ]);
            }
          }
        )
        .catch((err: any) => {
          console.log("error in fetching groups for projects", err);
        })
        .finally(() => {
          setGroupLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const urls = newSitePhotos.map((photo) => URL.createObjectURL(photo));
    setPhotoUrls(urls);

    // Cleanup URLs to avoid memory leaks
    return () => {
      urls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [newSitePhotos]);

  const onChangeData = async (key: string, value: any) => {
    switch (key) {
      case "title":
        setProjectDescription({
          ...projectDescription,
          title: value.toString(),
        });
        return;
      case "description":
        setProjectDescription({
          ...projectDescription,
          description: value.toString(),
        });
        return;
      case "incentive":
        setProjectDescription({
          ...projectDescription,
          incentive: value.toString(),
        });
        return;
      case "unit_price":
        setProjectDescription({
          ...projectDescription,
          unit_price: value.toString(),
        });
        return;
      case "thumbnail":
        setSelectedThumbnail(value);
        return;
      case "address":
        setProjectAddress({
          ...projectAddress,
          address: value.toString(),
        });
        return;

      case "zipcode":
        if (value.length <= 6) {
          setProjectAddress({
            ...projectAddress,
            zipcode: value.toString(),
          });
        }
        return;
      case "city":
        setProjectAddress({
          ...projectAddress,
          city: value.toString(),
        });
        return;
      case "state":
        setProjectAddress({
          ...projectAddress,
          state: value.toString(),
        });
        return;
      case "phone":
        if (value.length <= 10) {
          setProjectAddress({
            ...projectAddress,
            phone: value.toString(),
          });
        }
        return;
      case "email":
        setProjectAddress({
          ...projectAddress,
          email: value.toString(),
        });
        return;
      case "training_videos":
        value.forEach(async (file: File) => {
          const thumbnail: any = await generateThumbnail(file);
          if (file && file instanceof File && thumbnail instanceof File) {
            setTrainingVideoThumbnails(pre => [...pre, thumbnail]);
          }
        });
        setTrainingVideos([...trainingVideos, ...value]);
        return;
      case "site-flyers":
        let files = value;
        const updatedPayload = await Promise.all(
          files.map(async (file: File) => {
            if (file) {
              const fileType = file.type.split("/")[0];
              if (fileType === "image") {
                return {
                  type: 1,
                  file: file,
                  url: URL.createObjectURL(file),
                };
              } else if (fileType === "video") {
                const thumbnail = await generateThumbnail(file);

                return {
                  type: 2,
                  file: file,
                  thumbnail: thumbnail,
                  url: thumbnail ? URL.createObjectURL(thumbnail) : null,
                };
              }
            }
            return null; // Handle case where file might be invalid
          })
        );

        // Filter out any null results if needed
        const filteredPayload = updatedPayload.filter(Boolean);
        console.log("🚀 ~ updatedPayload ~ thumbnail:", filteredPayload)
        setNewSiteFlyers([...newSiteFlyers, ...filteredPayload]);
        return;
      case "newBrochure":
        setNewProjectBrochure([...newProjectBrochure, ...value]);
        return;
      case "site-photos":
        setNewSitePhotos([...newSitePhotos, ...value]);
        return;
      case "select-groups":
        if (type === "edit") {
          value.forEach((grp: ProjectGroupType) => {
            setRemovedGroup((pre) =>
              pre.filter((g) => g.group_id !== grp.group_id)
            );
            if (
              !(
                currentProjectGroup.filter((g) => g.group_id === grp.group_id)
                  .length > 0
              )
            ) {
              setSelectedGroups((pre) => [...pre, grp]);
            }
          });
        } else setSelectedGroups([...value]);

        return;
      default:
        return;
    }
  };

  const removeMedia = (key: string, index: number) => {
    switch (key) {
      case "site-flyers":
        let filteredFlyers = newSiteFlyers.filter(
          (data, dataIndex) => index !== dataIndex
        );
        setNewSiteFlyers(filteredFlyers);
        return;
      case "site-photos":
        let filteredSiteBlobUrls = photoUrls.filter(
          (data, dataIndex) => index !== dataIndex
        );

        let filteredSitePhotos = newSitePhotos.filter(
          (data, dataIndex) => index !== dataIndex
        );
        setPhotoUrls(filteredSiteBlobUrls);
        setNewSitePhotos(filteredSitePhotos);
        return;
      case "pdf":
        let filteredSitePdfs = newProjectBrochure.filter(
          (data, dataIndex) => index !== dataIndex
        );
        setNewProjectBrochure(filteredSitePdfs);
        return;
      default:
        return;
    }
  };

  const handleRemoveTrainingVideo = (index: number) => {
    let videoData = trainingVideos.filter(
      (data, dataIndex) => dataIndex !== index
    );
    let thumbnailData = trainingVideoThumbnails.filter(
      (data, dataIndex) => dataIndex !== index
    );
    setTrainingVideos(videoData);
    setTrainingVideoThumbnails(thumbnailData);
  };

  const generateThumbnail = (file: File): Promise<File | null> => {
    return new Promise((resolve, reject) => {
      const videoUrl = URL.createObjectURL(file);
      const videoElement = document.createElement("video");

      videoElement.src = videoUrl;

      videoElement.onloadeddata = () => {
        videoElement.currentTime = 2; // Capture time for thumbnail
      };

      videoElement.onseeked = () => {
        const canvasElement = document.createElement("canvas");
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        const context = canvasElement.getContext("2d");

        if (context) {
          context.drawImage(
            videoElement,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );

          // Convert canvas to Blob and create a File object
          canvasElement.toBlob((blob) => {
            if (blob) {
              const thumbnailFile = new File(
                [blob],
                `${file.name}-thumbnail.png`,
                { type: "image/png" }
              );
              resolve(thumbnailFile);
            } else {
              resolve(null);
            }

            // Clean up the URL
            URL.revokeObjectURL(videoUrl);
          }, "image/png");
        } else {
          reject(new Error("Failed to get canvas context"));
        }
      };

      videoElement.onerror = () => {
        reject(new Error("Failed to load video"));
        URL.revokeObjectURL(videoUrl);
      };
    });
  };

  function truncatePDFName(name: string) {
    const maxLength = 5;
    return name.length > maxLength ? name.slice(0, maxLength) + "..." : name;
  }

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    onChangeData("training_videos", files);
  };

  const handleValidation = (key: string) => {
    switch (key) {
      case "site_images":
        return (
          newSitePhotos.length > 0 ||
          (SingleProject &&
            SingleProject?.media_urls.site_photos.filter(
              (p) => !removedSiteImages.includes(p)
            ).length > 0)
        );
      case "site_name":
        return projectDescription.title.trim().length > 0;
      case "site_address":
        return projectAddress.address.trim().length > 15;
      case "site_price":
        return projectDescription.unit_price.trim().length > 0;
      case "site_incentive":
        return projectDescription.incentive.trim().length > 0;
      case "site_city":
        return projectAddress.city.trim().length > 0;
      case "site_zipcode":
        return projectAddress.zipcode.trim().length === 6;
      case "site_state":
        return projectAddress.state.trim().length > 0;
      case "site_phone":
        return projectAddress.phone.length > 0;
      case "site_description":
        return projectDescription.description.trim().length < 500;
      case "site_flyers":
        return (
          newSiteFlyers.length > 0 ||
          (SingleProject && SingleProject?.media_urls.site_flyers.length > 0)
        );
      case "site_pdfs":
        return (
          newProjectBrochure.length > 0 ||
          (SingleProject &&
            SingleProject?.media_urls.site_PDFs.filter(
              (p) => !removedPDFs.includes(p)
            ).length > 0)
        );
      case "training_videos":
        return (
          trainingVideos.length > 0 ||
          (SingleProject &&
            SingleProject.media_urls.training_videos.filter(
              (p) => !removedTrainingVideo.includes(p)
            ).length > 0)
        );
      case "selected_groups":
        return selectedGroups.length > 0;
      default:
        return false;
    }
  };

  const handleRemoveGroup = (group: ProjectGroupType) => {
    if (type === "create") {
      setSelectedGroups(
        selectedGroups.filter((g) => g.group_id !== group.group_id)
      );
    } else if (type === "edit") {
      if (
        currentProjectGroup.filter((g) => g.group_id === group.group_id)
          .length > 0
      ) {
        setRemovedGroup([...removedGroup, group]);
      } else {
        setSelectedGroups(
          selectedGroups.filter((g) => g.group_id !== group.group_id)
        );
      }
    }
  };

  const handleDataChange = () => {
    if (SingleProject) {
      if (
        SingleProject.name !== projectDescription.title.trim() ||
        SingleProject.phone_number?.toString() !==
        projectAddress.phone.trim() ||
        SingleProject.unit_price?.toString() !==
        projectDescription.unit_price.trim() ||
        SingleProject.incentive?.toString() !==
        projectDescription.incentive.trim() ||
        SingleProject.address?.trim() !== projectAddress.address.trim() ||
        SingleProject.city !== projectAddress.city.trim() ||
        SingleProject.zip_code?.toString() !== projectAddress.zipcode.trim() ||
        SingleProject.state?.toString() !== projectAddress.state.trim() ||
        SingleProject.description?.toString().trim() !==
        projectDescription.description.trim() ||
        newSiteFlyers.length > 0 ||
        newSitePhotos.length > 0 ||
        newProjectBrochure.length > 0 ||
        trainingVideos.length > 0 ||
        selectedGroups.length > 0 ||
        removedGroup.length > 0 ||
        removedPDFs.length > 0 ||
        removedFlyers.length > 0 ||
        removedSiteImages.length > 0 ||
        removedTrainingVideo.length > 0

      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (
      handleValidation("site_images") &&
      handleValidation("site_name") &&
      handleValidation("site_address") &&
      handleValidation("site_price") &&
      handleValidation("site_description") &&
      handleValidation("site_flyers") &&
      handleValidation("site_pdfs") &&
      handleValidation("training_videos") &&
      handleValidation("site_city") &&
      handleValidation("site_zipcode") &&
      handleValidation("site_state") &&
      handleValidation("site_phone")
    ) {
      let errorDelete: any = [];
      try {
        setLoading(true);
        // upload site photos
        let uploadedSitePhotos = [];
        if (newSitePhotos.length > 0) {
          const resSitePhotos = await uploadFile(newSitePhotos, "projects");
          uploadedSitePhotos = resSitePhotos.data.imageUrls;
          errorDelete = [...errorDelete, ...uploadedSitePhotos];
        }

        // upload site flyers
        let uploadedSiteFlyers: any = [];

        if (newSiteFlyers.length > 0) {
          for (let flyer of newSiteFlyers) {
            let uploadedFile = await uploadFile([flyer.file], "projects")
            if (flyer.type === 1) {
              uploadedSiteFlyers.push({
                type: uploadedFile.data.imageUrls[0].type,
                url: uploadedFile.data.imageUrls[0].url
              })
            } else if (flyer.type === 2 && flyer.thumbnail) {
              let thumbnailURL = await uploadFile([flyer.thumbnail], "projects");
              uploadedSiteFlyers.push({
                type: uploadedFile.data.imageUrls[0].type,
                url: uploadedFile.data.imageUrls[0].url,
                thumbnail: thumbnailURL.data.imageUrls[0].url
              })
            }
          }
        }
        // console.log("🚀 ~ handleSubmit ~ uploadedSiteFlyers:", uploadedSiteFlyers)
        errorDelete = [...errorDelete, ...uploadedSiteFlyers];


        // upload site pdfs
        let uploadedSitePDFs = [];

        if (newProjectBrochure.length > 0) {
          const resSitePDFs = await uploadFile(newProjectBrochure, "projects");
          uploadedSitePDFs = resSitePDFs.data.imageUrls;
          errorDelete = [...errorDelete, ...uploadedSitePDFs];
        }

        // upload site flyers
        let uploadedSiteTrainingVideos: any = [];

        for (let video of trainingVideos) {
          const resSiteTrainingVideos = await uploadFile([video], "projects");
          uploadedSiteTrainingVideos = [
            ...resSiteTrainingVideos.data.imageUrls,
            ...uploadedSiteTrainingVideos,
          ];
          errorDelete = [
            ...resSiteTrainingVideos.data.imageUrls,
            ...errorDelete,
          ];
        }
        // console.log("🚀 ~ handleSubmit ~ uploadedSiteTrainingVideos:", uploadedSiteTrainingVideos)
        // console.log("uploadedSiteTrainingVideos====>", uploadedSiteTrainingVideos);
        // upload video thumbnail
        let uploadedSiteTrainingVideosThumbnail: any = [];
        if (trainingVideoThumbnails.length > 0) {
          const resSiteTrainingVideosThumbnail = await uploadFile(
            trainingVideoThumbnails,
            "projects"
          );
          uploadedSiteTrainingVideosThumbnail =
            resSiteTrainingVideosThumbnail.data.imageUrls;
          errorDelete = [
            ...uploadedSiteTrainingVideosThumbnail,
            ...errorDelete,
          ];
        }
        if (type === "create") {
          let payload: any = {
            name: projectDescription.title,
            unit_price: projectDescription.unit_price,
            status: "Active",
            description: projectDescription.description,
            country_code: "+91",
            phone_number: projectAddress.phone,
            city: projectAddress.city,
            state: projectAddress.state,
            address: projectAddress.address,
            zip_code: projectAddress.zipcode,
            site_photos: [],
            training_videos: [],
            site_flyers: [],
            site_PDFs: [],
          };
          if (projectDescription.incentive.length > 0) {
            payload.incentive = projectDescription.incentive;
          }
          uploadedSitePhotos.forEach((img: any) => {
            payload.site_photos.push({
              type: img.type,
              url: img.url,
            });
          });
          // uploadedSiteFlyers.forEach((img: any) => {
          //   payload.site_flyers.push({
          //     type: img.type,
          //     url: img.url,
          //   });
          // });
          if (uploadedSiteFlyers.length > 0) {
            payload.site_flyers = uploadedSiteFlyers;
          }
          uploadedSiteTrainingVideos.forEach((video: any, index: number) => {
            payload.training_videos.push({
              type: video.type,
              url: video.url,
              thumbnail: uploadedSiteTrainingVideosThumbnail[index].url,
            });
          });
          uploadedSitePDFs.forEach((pdf: any) => {
            payload.site_PDFs.push({
              type: pdf.type,
              url: pdf.url,
            });
          });
          if (selectedGroups.length > 0) {
            payload.group_ids = [];
            selectedGroups.forEach((grp) => {
              payload.group_ids.push({
                group_id: grp.group_id,
              });
            });
          }
          // console.log("🚀 ~ handleSubmit ~ payload:", payload)
          dispatch(createProjectAPI(payload))
            .unwrap()
            .then(() => {
              setLoading(false);
              showSuccessToast("Site Created Successfully");
              navigate("/dashboard/site-management");
            })
            .catch((err: any) => {
              setLoading(true);
              deleteMediaFunction(errorDelete)
                .then(() => { })
                .finally(() => {
                  setLoading(false);
                  console.log(err.message);
                  showErrorToast(err.message || "Error in site creation.");
                });
            });
        } else if (type === "edit" && SingleProject) {
          let payload: UpdateProjectPayloadType = {
            project_id: SingleProject.project_id,
          };
          if (SingleProject.name !== projectDescription.title.trim())
            payload.name = projectDescription.title;
          if (
            SingleProject.phone_number.toString() !==
            projectAddress.phone.trim()
          ) {
            payload.phone_number = projectAddress.phone.trim();
            payload.country_code = "+91";
          }
          if (
            SingleProject.unit_price?.toString() !==
            projectDescription.unit_price.trim()
          )
            payload.unit_price = projectDescription.unit_price;
          if (!SingleProject.incentive) {
            if (projectDescription.incentive.trim().length > 0) {
              payload.incentive = projectDescription.incentive;
            }
          } else {
            if (
              SingleProject.incentive?.toString() !==
              projectDescription.incentive.trim()
            )
              payload.incentive = projectDescription.incentive;
          }
          if (SingleProject.address?.trim() !== projectAddress.address.trim())
            payload.address = projectAddress.address;
          if (SingleProject?.city !== projectAddress.city.trim())
            payload.city = projectAddress.city;
          if (
            SingleProject.zip_code?.toString() !== projectAddress.zipcode.trim()
          )
            payload.zip_code = projectAddress.zipcode;
          if (SingleProject.state?.toString() !== projectAddress.state.trim())
            payload.state = projectAddress.state;
          if (
            SingleProject.description?.toString().trim() !==
            projectDescription.description.trim()
          )
            payload.description = projectDescription.description;
          let deletedMedia: number[] = [];
          if (removedSiteImages.length > 0) {
            removedSiteImages.forEach((item) => {
              deletedMedia.push(item.id);
            });
          }
          if (removedPDFs.length > 0) {
            removedPDFs.forEach((item) => {
              deletedMedia.push(item.id);
            });
          }
          if (removedFlyers.length > 0) {
            removedFlyers.forEach((item) => {
              deletedMedia.push(item.id);
            });
          }
          let delete_group_ids = [];
          if (removedGroup.length > 0) {
            removedGroup.forEach((item) => {
              delete_group_ids.push(item.group_id);
            });
          }

          if (removedTrainingVideo.length > 0) {
            removedTrainingVideo.forEach((item) => {
              deletedMedia.push(item.id);
            });
          }

          if (newSitePhotos.length > 0 && uploadedSitePhotos.length > 0) {
            let add_site_photos: any = [];
            uploadedSitePhotos.forEach((item: any) => {
              add_site_photos.push({
                url: item.url,
                type: item.type,
              });
            });
            payload.add_site_photos = add_site_photos;
          }
          if (newSiteFlyers.length > 0 && uploadedSiteFlyers.length > 0) {
            payload.add_site_flyers = uploadedSiteFlyers;
          }
          if (newProjectBrochure.length > 0 && uploadedSitePDFs.length > 0) {
            let add_site_PDFs: any = [];
            uploadedSitePDFs.forEach((item: any) => {
              add_site_PDFs.push({
                url: item.url,
                type: item.type,
              });
            });
            payload.add_site_PDFs = add_site_PDFs;
          }
          if (
            trainingVideos.length > 0 &&
            uploadedSiteTrainingVideos.length > 0 &&
            uploadedSiteTrainingVideosThumbnail.length > 0
          ) {
            let add_training_videos: any = [];
            uploadedSiteTrainingVideos.forEach((item: any, index: number) => {
              add_training_videos.push({
                url: item.url,
                type: item.type,
                thumbnail: uploadedSiteTrainingVideosThumbnail[index].url,
              });
            });
            payload.add_training_videos = add_training_videos;
          }
          if (selectedGroups.length > 0) {
            let add_group_ids: any = [];
            selectedGroups.forEach((g) => {
              add_group_ids.push({
                group_id: g.group_id,
              });
            });
            payload.add_group_ids = add_group_ids;
          }

          if (deletedMedia.length > 0) {
            payload.delete_media = deletedMedia;
          }
          dispatch(updateSiteAPI(payload))
            .unwrap()
            .then(() => {
              setLoading(false);
              showSuccessToast("Site updated Successfully");
              navigate("/dashboard/site-management");
            })
            .catch((err: any) => {
              setLoading(true);
              deleteMediaFunction(errorDelete)
                .then(() => { })
                .finally(() => {
                  setLoading(false);
                  console.log(err.message);
                  showErrorToast(err.message || "Error in site update.");
                });
            });
        }

      } catch (err: any) {
        // delete image functionality
        setLoading(true);


        deleteMediaFunction(errorDelete)
          .then(() => { })
          .finally(() => {
            setLoading(false);
            let message = "";
            if (type === "create") message = "Error project creation";
            else message = "Error project update";
            showErrorToast(err.response?.data?.error.message || message);
          });
      }
    } else {
      setShowError(true);
    }
  };
  const deleteMediaFunction = async (urls: any[]) => {
    const formattedUrls = urls.map((item: { url: string }) => item.url);
    const formattedThumbnailUrls = urls
      .filter((item: any) => item.thumbnail && item.type === 2)
      .map((item: any) => item.thumbnail);
    console.log("🚀 ~ formattedThumbnailUrls ~ formattedThumbnailUrls:", formattedThumbnailUrls)
    if ([...formattedUrls, ...formattedThumbnailUrls].length > 0) {
      return deleteMedia({ image_path: [...formattedUrls, ...formattedThumbnailUrls] });
    }
  };

  function extractActualFilename(path: string): string {
    const fullFilename = path.substring(path.lastIndexOf("/") + 1); // Get the part after the last '/'
    const actualFilename = fullFilename.substring(
      fullFilename.lastIndexOf("-") + 1
    ); // Get the part after the last '-'
    return actualFilename;
  }

  const handleCancel = () => {
    setProjectAddress({
      email: "",
      address: SingleProject?.address ?? "",
      state: SingleProject?.state ?? "",
      city: SingleProject?.city ?? "",
      phone: SingleProject?.phone_number.toString() ?? "",
      zipcode: SingleProject?.zip_code.toString() ?? "",
    });
    setProjectDescription({
      title: SingleProject?.name ?? "",
      description: SingleProject?.description ?? "",
      incentive: SingleProject?.incentive ?? "",
      unit_price: SingleProject?.unit_price ?? "",
    });
    setNewSiteFlyers([]);
    setNewProjectBrochure([]);
    setNewProjectBrochure([]);
    setTrainingVideos([]);
    setTrainingVideos([]);
    setSelectedGroups([]);
    setRemovedGroup([]);
    setRemovedPDFs([]);
    setRemovedSiteImages([]);
    setRemovedTrainingVideo([]);
  };
  return (
    <div className="project_create_container">
      {loading && <LoaderScreen />}
      <>
        {discardChangeModal && (
          <ConfirmModal confirmModalProps={confirmModalProps} />
        )}
      </>
      {
        <>
          <input
            type="file"
            multiple
            accept="image/*"
            ref={inputSiteMediaRef}
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files) {
                const filesArray = Array.from(e.target.files);
                onChangeData("site-photos", filesArray);
              }
            }}
          />
          <input
            type="file"
            accept="image/*,video/*"
            multiple
            ref={inputSiteFlyerRef}
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files) {
                const filesArray = Array.from(e.target.files);
                onChangeData("site-flyers", filesArray);
              }
            }}
          />
          <input
            type="file"
            multiple
            accept="application/pdf"
            ref={inputBrochureRef}
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files) {
                const filesArray = Array.from(e.target.files);
                onChangeData("newBrochure", filesArray);
              }
            }}
          />
          <input
            type="file"
            multiple
            accept="video/*"
            ref={inputTrainingVideoRef}
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files) {
                handleVideoUpload(e);
              }
            }}
          />
        </>
      }
      <div className="screen_container">
        <div className="add_site_photo">
          <div
            className="add_photo_img"
            onClick={() => {
              if (inputSiteMediaRef.current) {
                inputSiteMediaRef.current.value = "";
                inputSiteMediaRef.current.click();
              }
            }}
          >
            <img src={image.ADD_IMAGE_PLACEHOLDER} />
            <div className="add_image_text">Add Image</div>
          </div>

          {newSitePhotos.map((photo, index) => (
            <div className="site_photos" key={index}>
              <img className="img" src={photoUrls[index]} alt="site" />
              <img
                className="cancel_icon"
                src={image.CLOSE_ICON}
                onClick={() => removeMedia("site-photos", index)}
                alt="remove"
              />
            </div>
          ))}

          {type === "edit" &&
            SingleProject?.media_urls.site_photos.map(
              (photo, index) =>
                !removedSiteImages.includes(photo) && (
                  <div className="site_photos" key={index}>
                    <img
                      className="img"
                      src={getImageURL(photo.url)}
                      alt="site"
                    />
                    <img
                      className="cancel_icon"
                      src={image.CLOSE_ICON}
                      onClick={() => {
                        setRemovedSiteImages([...removedSiteImages, photo]);
                      }}
                      alt="remove"
                    />
                  </div>
                )
            )}
        </div>
        {showError && !handleValidation("site_images") && (
          <ErrorText message="Please enter site images" />
        )}

        <div className="project_data">
          <div className="project_information">
            <div className="input_container">
              <label className="label">
                Site Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="input"
                placeholder="Enter site name"
                value={projectDescription.title}
                onChange={(e) => {
                  onChangeData("title", e.target.value);
                }}
              />
              {showError && !handleValidation("site_name") && (
                <ErrorText message="Please enter site name" />
              )}
            </div>
            <div className="input_container">
              <label className="label">
                Phone Number <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="input"
                value={projectAddress.phone}
                placeholder="Enter Phone number"
                onChange={(e) => {
                  onChangeData("phone", e.target.value);
                }}
              />
              {showError && !handleValidation("site_phone") && (
                <ErrorText message="Please enter phone number." />
              )}
            </div>
            <div className="input_container number_input">
              <label className="label">
                Site Price <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                className="input number_input"
                placeholder="Enter price"
                value={projectDescription.unit_price}
                onChange={(e) => {
                  onChangeData("unit_price", e.target.value);
                }}
              />
              {showError && !handleValidation("site_price") && (
                <ErrorText message="Please enter  valid site price" />
              )}
            </div>
            <div className="input_container ">
              <label className="label">Incentive(Optional)</label>
              <input
                type="number"
                className="input number_input"
                placeholder="Enter points"
                value={projectDescription?.incentive}
                onChange={(e) => {
                  onChangeData("incentive", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="project_information" style={{ marginTop: "20px" }}>
            <div className="input_container">
              <label className="label">
                Address <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="input"
                placeholder="Enter site address"
                value={projectAddress.address}
                onChange={(e) => {
                  onChangeData("address", e.target.value);
                }}
              />
              {showError && !handleValidation("site_address") && (
                <ErrorText message="Address must be more that 15 character." />
              )}
            </div>
            <div className="input_container">
              <label className="label">
                City <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="input"
                value={projectAddress.city}
                placeholder="Enter city"
                onChange={(e) => {
                  onChangeData("city", e.target.value);
                }}
              />
              {showError && !handleValidation("site_city") && (
                <ErrorText message="Please enter site city" />
              )}
            </div>
            <div className="input_container number_input">
              <label className="label">
                Zip code <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                className="input number_input"
                placeholder="Enter zip code"
                value={projectAddress.zipcode}
                onChange={(e) => {
                  onChangeData("zipcode", e.target.value);
                }}
              />
              {showError && !handleValidation("site_zipcode") && (
                <ErrorText message="Please enter valid zip code" />
              )}
            </div>
            <div className="input_container ">
              <label className="label">
                State <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number "
                className="input number_input"
                placeholder="Enter points"
                value={projectAddress.state}
                onChange={(e) => {
                  onChangeData("state", e.target.value);
                }}
              />
              {showError && !handleValidation("site_state") && (
                <ErrorText message="Please enter site's location state." />
              )}
            </div>
          </div>
          <div className="project_description">
            <div className="desc_container">
              <label className="label">
                Description <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="input number_input"
                value={projectDescription.description}
                placeholder="Enter site description"
                onChange={(e) => {
                  onChangeData("description", e.target.value);
                }}
              />
              {showError && !handleValidation("site_description") && (
                <ErrorText message="Description must be more than 500 characters." />
              )}
            </div>
          </div>
          <div className="project_media">


            {/*               SITE FLYERS  */}
            <div className="site_pdf_container">
              <div className="label">
                Site Flyers <span style={{ color: "red" }}>*</span>
              </div>
              {showError && !handleValidation("site_flyers") && (
                <ErrorText message="Please enter site flyers." />
              )}
              <div className="flyer_container">
                <div
                  className="add_photo_img add_pdf_container"
                  onClick={() => {
                    inputSiteFlyerRef.current?.click();
                  }}
                >
                  <img src={image.ADD_IMAGE_PLACEHOLDER} />
                  <div className="add_image_text">Add Flyers</div>
                </div>
                {newSiteFlyers.map((flyer, index) => (
                  <div className="site_photos" key={index}>
                    <img className="img" src={flyer.url} alt="site" />
                    <img
                      className="cancel_icon"
                      src={image.CLOSE_ICON}
                      onClick={() => removeMedia("site-flyers", index)}
                      alt="remove"
                    />
                    {flyer.type === 2 &&
                      <img
                        className="video_icon"
                        src={image.ADD_VIDEO_PLACEHOLDER}
                        style={{ marginLeft: "0" }}
                      />
                    }
                  </div>
                ))}
                {type === "edit" &&
                  SingleProject?.media_urls.site_flyers.map(
                    (flyer, index) =>
                      !removedFlyers.includes(flyer) && (
                        <div className="site_photos" key={index}>
                          <img className="img" src={getImageURL(flyer.type === 2 && flyer.thumbnail ? flyer.thumbnail : flyer.url)} alt="site_flyer" />
                          <img
                            className="cancel_icon"
                            src={image.CLOSE_ICON}
                            onClick={() => setRemovedFlyers([...removedFlyers, flyer])}
                            alt="remove"
                          />
                          {flyer.type === 2 &&
                            <img
                              className="video_icon"
                              src={image.ADD_VIDEO_PLACEHOLDER}
                              style={{ marginLeft: "0" }}
                            />
                          }
                        </div>
                      )
                  )}
              </div>
            </div>


            {/* SITE PDFS */}
            <div className="site_pdf_container">
              <div className="label">
                Site PDFs <span style={{ color: "red" }}>*</span>
              </div>
              {showError && !handleValidation("site_pdfs") && (
                <ErrorText message="Please enter site PDFs." />
              )}
              <div className="pdfs-container">
                <div
                  className="add_photo_img add_pdf_container"
                  onClick={() => {
                    inputBrochureRef.current?.click();
                  }}
                >
                  <img src={image.ADD_PDF_PLACEHOLDER} />
                  <div className="add_image_text">Add PDFs</div>
                </div>
                {newProjectBrochure.map((file, index) => (
                  <div className="pdf_container">
                    <img
                      src={image.PDF_PLACEHOLDER_IMAGE}
                      className="pdf_img"
                    />
                    <div className="pdf_name_text">
                      {truncatePDFName(file.name)}
                    </div>
                    <img
                      className="cancel_icon"
                      src={image.CLOSE_ICON}
                      onClick={() => removeMedia("pdf", index)}
                    />
                  </div>
                ))}
                {type === "edit" &&
                  SingleProject?.media_urls.site_PDFs.map(
                    (pdf) =>
                      !removedPDFs.includes(pdf) && (
                        <div className="pdf_container">
                          <img
                            src={image.PDF_PLACEHOLDER_IMAGE}
                            className="pdf_img"
                          />
                          <div className="pdf_name_text">
                            {truncatePDFName(extractActualFilename(pdf.url))}
                          </div>
                          <img
                            className="cancel_icon"
                            src={image.CLOSE_ICON}
                            onClick={() => {
                              setRemovedPDFs([...removedPDFs, pdf]);
                            }}
                          />
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="training_video_section">
            <div className="label">
              Training Video <span style={{ color: "red" }}>*</span>
            </div>
            {showError && !handleValidation("training_videos") && (
              <ErrorText message="Please enter site training video." />
            )}
            <div className="site_training_video_container">
              <div
                className="add_photo_img"
                onClick={() => {
                  if (inputTrainingVideoRef.current) {
                    inputTrainingVideoRef.current.value = "";
                    inputTrainingVideoRef.current.click();
                  }
                }}
              >
                <img
                  src={image.ADD_VIDEO_PLACEHOLDER}
                  style={{ marginLeft: "0" }}
                />
                <div className="add_image_text">Add Videos</div>
              </div>

              {trainingVideoThumbnails.map((thumbnail, index) => (
                <div className="site_video">
                  <img className="img" src={URL.createObjectURL(thumbnail)} />
                  <img
                    className="cancel_icon"
                    src={image.CLOSE_ICON}
                    onClick={() => handleRemoveTrainingVideo(index)}
                  />
                </div>
              ))}
              {type === "edit" &&
                SingleProject?.media_urls.training_videos.map(
                  (vid, index) =>
                    !removedTrainingVideo.includes(vid) && (
                      <div className="site_video">
                        <img
                          className="img"
                          src={
                            getImageURL(vid.thumbnail) ??
                            image.DEFAULT_PROFILE_IMAGE
                          }
                        />
                        <img
                          className="cancel_icon"
                          src={image.CLOSE_ICON}
                          onClick={() =>
                            setRemovedTrainingVideo([
                              ...removedTrainingVideo,
                              vid,
                            ])
                          }
                        />
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="group_select_ui">
        <div className="grp_container">
          <div>
            <h1 className="grp_header_text">Select Group</h1>
            <div style={{ float: "right", marginBottom: "20px" }}>
              <AddButton
                btnText="Select"
                onclick={() => {
                  setIsSelectGroupModalOpen(true);
                }}
                plusIcon={true}
                padding="10px 15px"
                buttonClass="add_btn "
              />
            </div>
          </div>
          <div className="grp_header">
            <div className="tab1 center_div">Select</div>
            <div className="tab2 center_div">Image</div>
            <div className="tab3 center_div">Name</div>
            <div className="tab4 center_div">Total Managers</div>
            <div className="tab5 center_div">Total Brokers</div>
            <div className="tab6 center_div">Action</div>
          </div>
          {groupsLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ height: "150px", width: "150px" }}>
                <Loader isGray={true} />
              </div>
            </div>
          ) : (
            <>
              {[
                ...selectedGroups,
                ...currentProjectGroup.filter((g) => !removedGroup.includes(g)),
              ].length > 0 ? (
                <div className="grp_body_container">
                  {[
                    ...selectedGroups,
                    ...currentProjectGroup.filter(
                      (g) => !removedGroup.includes(g)
                    ),
                  ].map((grp) => (
                    <div className="grp_body">
                      <div className="tab1 center_div">
                        <CustomRadioButton
                          checked={
                            currentSelectedGroup?.group_id === grp.group_id
                          }
                          onClick={() => {
                            setCurrentSelectedGroup(grp);
                          }}
                        />
                      </div>
                      <div className="tab2  center_div">
                        <img
                          className="grp_profile_img"
                          src={
                            grp.photo
                              ? getImageURL(grp.photo)
                              : image.DEFAULT_PROFILE_IMAGE
                          }
                        />
                      </div>
                      <div className="tab3 center_div">{grp.name}</div>
                      <div className="tab4 center_div">
                        {grp.manager_profiles.length > 0 ? (
                          <div className="group_list_container">
                            {grp.manager_profiles
                              ?.slice(0, MAX_VISIBLE_IMAGES)
                              .map((imgSrc: any, index: number) => (
                                <img
                                  key={index}
                                  src={
                                    imgSrc
                                      ? getImageURL(imgSrc)
                                      : image.DEFAULT_PROFILE_IMAGE
                                  }
                                  alt={`Cp ${index + 1}`}
                                />
                              ))}
                            {grp.total_managers >
                              MAX_VISIBLE_IMAGES && (
                                <div className="total_members_class">
                                  +
                                  {grp.total_managers -
                                    MAX_VISIBLE_IMAGES}
                                </div>
                              )}
                          </div>
                        ) : (
                          "No Managers added."
                        )}
                      </div>
                      <div className="tab5 center_div">
                        {grp.cp_profiles.length > 0 ? (
                          <div className="group_list_container">
                            {grp.cp_profiles
                              ?.slice(0, MAX_VISIBLE_IMAGES)
                              .map((imgSrc: any, index: number) => (
                                <img
                                  key={index}
                                  src={
                                    imgSrc
                                      ? getImageURL(imgSrc)
                                      : image.DEFAULT_PROFILE_IMAGE
                                  }
                                  alt={`Cp ${index + 1}`}
                                />
                              ))}
                            {grp.total_cps > MAX_VISIBLE_IMAGES && (
                              <div className="total_members_class">
                                +{grp.total_cps - MAX_VISIBLE_IMAGES}
                              </div>
                            )}
                          </div>
                        ) : (
                          "No Broker allocated."
                        )}
                      </div>
                      <div className="tab6 center_div">
                        <ActionButtonContainer
                          handleRemoveGroup={handleRemoveGroup}
                          group={grp}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="center_div">
                  {" "}
                  <div
                    style={{ height: "30%", width: "30%", marginTop: "10px" }}
                  >
                    <EmptyPage
                      image={image.NO_MANAGER_ADDED_YET_IMAGE}
                      text="No group added yet"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="select_broker_manager_container">
        <div className="select_container">
          <div className="header_container">
            <div className="header_text">Managers</div>
          </div>
          <div className="list_container">
            {currentSelectedGroup &&
              (selectedGroups.filter(
                (grp) => grp.group_id === currentSelectedGroup.group_id
              ).length > 0 ||
                currentProjectGroup.filter(
                  (grp) => grp.group_id === currentSelectedGroup.group_id
                ).length > 0) ? (
              managerLoading ? (
                <div
                  style={{ height: "100%", width: "100%" }}
                  className="center_div"
                >
                  <Loader isGray={true} />
                </div>
              ) : groupManagers.length == 0 ? (
                <div
                  style={{ height: "100%", width: "100%" }}
                  className="center_div"
                >
                  <div style={{ height: "100%", width: "100%" }}>
                    <EmptyPage
                      image={image.NO_MANAGER_ADDED_YET_IMAGE}
                      text="No manger in selected group."
                    />
                  </div>
                </div>
              ) : (
                groupManagers.map((manager) => (
                  <div className="profile_data">
                    <div className="profile">
                      <div className="profile_image_container">
                        <img
                          src={
                            manager.profile_image
                              ? getImageURL(manager.profile_image)
                              : image.DEFAULT_PROFILE_IMAGE
                          }
                          className="profile_image"
                        />
                      </div>
                      <div className="data_container">
                        <div className="profile_data_container">
                          <div className="profile_name">{manager.name}</div>
                          <div className="profile_contact">
                            {manager.country_code} {manager.phone_number}
                          </div>
                        </div>
                      </div>
                      <div className="add_btn_container">
                        {/* <CustomRadioButton onChange={(e) => console.log(e.target.value)} checked={true} /> */}
                      </div>
                    </div>
                  </div>
                ))
              )
            ) : (
              <div
                style={{ height: "100%", width: "100%" }}
                className="center_div"
              >
                <div style={{ height: "300px", width: "300px" }}>
                  <EmptyPage
                    image={image.NO_MANAGER_ADDED_YET_IMAGE}
                    text="No group selected yet."
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="div_center create_project_btn_container">
        {type === "edit" ? (
          update ? (
            <button
              className="cancel_btn"
              onClick={() => setDiscardChangeModal(true)}
            >
              Cancel
            </button>
          ) : null
        ) : (
          <button
            className="cancel_btn"
            onClick={() => setDiscardChangeModal(true)}
          >
            Cancel
          </button>
        )}

        {type === "edit" ? (
          update ? (
            <button className="submit_btn" onClick={() => handleSubmit()}>
              Update
            </button>
          ) : null
        ) : (
          <button className="submit_btn" onClick={() => handleSubmit()}>
            Submit
          </button>
        )}
      </div>

      {isSelectGroupModalOpen && (
        <Modal onClose={() => setIsSelectGroupModalOpen(false)} isClose={true}>
          <AddGroupTable
            selectedGroups={[
              ...selectedGroups,
              ...currentProjectGroup.filter((g) => !removedGroup.includes(g)),
            ]}
            onChangeData={onChangeData}
            setIsSelectGroupModalOpen={setIsSelectGroupModalOpen}
          />
        </Modal>
      )}
    </div>
  );
};

export default CreateProjectModal;

const ActionButtonContainer: React.FC<{
  handleRemoveGroup: (group: ProjectGroupType) => void;
  group: ProjectGroupType;
}> = ({ handleRemoveGroup, group }) => {
  const [isMenuOpen, seIsMenuOpen] = useState(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const options = [
    {
      name: "Remove",
      onClick: () => {
        setRemoveModal(true);
      },
    },
  ];

  const confirmModalProps: confirmModalProps = {
    text: "Are you sure you want to remove this group from site?",
    confirmModal: removeModal,
    setConfirmModal: setRemoveModal,
    buttons: ["Yes, Remove", "Cancel"],
    onPressPositive: () => {
      handleRemoveGroup(group);
      setRemoveModal(false);
    },
    onPressNegative: () => {
      setRemoveModal(false);
    },
    image: image.REMOVE_GROUP_ICON,
  };

  return (
    <div className="action_tab" style={{ position: "relative" }}>
      <div className="action-group" onClick={() => seIsMenuOpen(!isMenuOpen)}>
        <ActionIcon />
      </div>
      {isMenuOpen && (
        <OptionMenu
          options={options}
          left={"-100px"}
          top={"32px"}
          onOutSideClick={() => seIsMenuOpen(false)}
        />
      )}
      {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}
    </div>
  );
};
