import React, { useEffect, useState } from 'react'
import { image } from '../../../../app/utils/common/image';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../../dto/confirm';
import Button from '../../../atoms/Button/Button';
import ManageBrokerHeader from './ManageBrokerHeader';
import './ManageBrokerDetails.scss'
import ManageBrokerSitesVisited from './ManageBrokerSitesVisited';
import ManageBrokerWorked from './ManageBrokerWorked';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { BrokerType } from '../../../../dto/Broker.dto';
import { getCPsManagerAPI, getCPsSiteVisitAPI } from '../../../../redux/API/BrokerAPI';
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen';

const ManageBrokerDetails = () => {
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const [currentBroker, setCurrentBroker] = useState<BrokerType>();
    const dispatch = useAppDispatch();
    const { brokers, pagination, managers, brokerSiteVisit } = useAppSelector(state => state.broker)
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id && brokers.length > 0) {
            const broker = brokers.find((broker) => broker.cp_id.toString() === id);
            if (broker)
                setCurrentBroker(broker);
            else
                navigate("/dashboard/manage-broker")
        } else
            navigate("/dashboard/manage-broker")
    }, [id, brokers]);

    useEffect(() => {
        if (id) {
            dispatch(getCPsManagerAPI({ cpId: Number(id), page: 1 }));
            dispatch(getCPsSiteVisitAPI({ cpId: Number(id), page: 1 }));
        }
    }, [id, pagination.current_page, dispatch]);


    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this broker?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => {
        },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_USER_ICON
    };

    return (
        <div className='create_project_container' >
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>Broker Details</div>
            </div>
            <div className='group-details-container'>

                {currentBroker && <ManageBrokerHeader currentBrokerDetails={currentBroker} />}
                <div className='manage-broker-container'>
                    <ManageBrokerSitesVisited />
                    <ManageBrokerWorked managers={managers || []} />
                </div>

            </div>

            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}


        </div>
    )
}

export default ManageBrokerDetails