import React, { useEffect, useRef, useState } from 'react'
import { image } from '../../../app/utils/common/image'
import Card from '../../atoms/Card/Card';
import { getAllBrokers, getTopBrokers } from '../../../redux/API/BrokerAPI';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { getImageURL } from '../../../app/utils/common/getImageUrl';
import { setBrokerPagination } from '../../../redux/Slices/brokerSlice';
import { BrokerType } from '../../../dto/Broker.dto';
import { PaginationType } from '../../../dto/Pagination.dto';
import Loader from '../../atoms/Loader/Loader';
import EmptyPage from '../../atoms/EmptyPage/EmptyPage';


const BrokerDataComponent = () => {
    const [dropDown, setDropDown] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null)
    // const {   } = useAppSelector(state => state.broker);

    const [loading, setLoading] = useState(false);
    const [brokers, setBrokers] = useState<BrokerType[]>([]);
    const [pagination, setPagination] = useState<PaginationType>({
        total_data: 10,
        current_page: 1,
        page_size: 10,
        total_pages: 1,
    })
    const dispatch = useAppDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
        ) {
            setDropDown(false);
        }
    };
    useEffect(() => {
        if (dropDown) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dropDown])

    useEffect(() => {
        setLoading(true);
        dispatch(getTopBrokers({ page: pagination?.current_page })).unwrap().then((data: {
            top_cps: BrokerType[],
            pagination: PaginationType
        }) => {
            if (data.pagination.current_page === pagination.current_page) {
                if (data.pagination.current_page === 1) {
                    setPagination(data.pagination)
                    setBrokers(data.top_cps)
                } else {
                    setBrokers([...brokers, ...data.top_cps])
                }
            }
        }).finally(() => {
            setLoading(false);
        })

    }, [dispatch, pagination?.current_page])

    // pagination
    let debounceTimer: NodeJS.Timeout;
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loading) {

                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => {
                    if (!loading && pagination.current_page < pagination.total_pages) {
                        setPagination({
                            ...pagination, current_page: pagination.current_page + 1,
                        });
                    }
                }, 500);
            }
        }
    };

    return (

        <div ref={scrollRef} onScroll={handleScroll} className='top_managers_and_brokers_list_container'>
            {loading && pagination.current_page === 1 ?
                <div className='center_div'><Loader isGray={true} /></div> :
                brokers.length > 0 ?
                    brokers.map((broker) => (
                        <div className='user_data_container'>
                            <div className='user_profile_container'>
                                <div className='profile_image_container'>
                                    <img className='profile_image' src={broker.profile_image ? getImageURL(broker.profile_image) : image.DEFAULT_PROFILE_IMAGE} onError={(e: any) => e.target.src = image.DEFAULT_PROFILE_IMAGE} />
                                </div>
                                <div className='profile_data'>
                                    <div className='user_container_main'>
                                        <div className='user_name_container'>
                                            <div className='user_name'>
                                                {broker?.name}
                                            </div>
                                            <div className='sub_data'>
                                                {broker?.country_code} {broker?.phone_number}
                                            </div>
                                        </div>
                                        <div>
                                            <span
                                                    className={`rank_span ${
                                                    broker.current_rank === "BRONZE"
                                                    ? "rank_span_bronze"
                                                    : broker.current_rank === "SILVER"
                                                    ? "rank_span_silver"
                                                    : broker.current_rank === "GOLD"
                                                    ? "rank_span_gold"
                                                    : broker.current_rank === "PLATINUM"
                                                    ? "rank_span_platinum"
                                                    : "rank_span_default"
                                                }`}
                                                > {broker.current_rank} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='data_number'>
                                    {broker?.site_visits} Sites
                                </div>
                            </div>
                            <div className='action_button_container' >
                                <div className='dot_container'
                                // onClick={(e) => {
                                // e.stopPropagation();
                                // setDropDown(!dropDown);
                                // }}
                                >

                                    {/* <div className='action_dot'></div>
                            <div className='action_dot'></div>
                            <div className='action_dot'></div> */}
                                </div>
                                {
                                    dropDown && <div className='action_dropdown' ref={menuRef}></div>
                                }
                            </div>
                        </div>
                    ))
                    : <div><EmptyPage image={image.NO_MANAGER_ADDED_YET_IMAGE} text='No Data Found' /></div>
            }
            {loading && pagination.current_page > 1 && (
                <div className='loading_more_data'>Loading more data...</div>
            )}
        </div>
    )
}

const BrokerList = () => {
    return (
        // <Card customClass='project_card' primaryBorderColor={!isActive ? "#F7681C" : "#5f9937"}>
        <Card isGreen={false}>
            <div >
                <BrokerDataComponent />
            </div>
        </Card>
    )
}

export default BrokerList;