import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { addPermission } from "../Slices/permissionSlice";



const getStore = () => {
  const store = require('../../redux/store').default;
  return store;
};
// Async thunk for refreshing the access token
export const getUserData = createAsyncThunk(
  "auth/getUserData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(ApiConstants.USER_PROFILE);
      if (response.data.data.permission_details.permissions) {
        let store = getStore();
        store.dispatch(addPermission(response.data.data.permission_details.permissions))
      }
      return response.data.data; // Assuming response is already structured correctly
    } catch (error: any) {

      return rejectWithValue(
        error.response?.data || "Unable to fetch user data."
      );
    }
  }
);

export const updateUserData = createAsyncThunk(
  "auth/updateUserData",
  async (
    userData: {
      name: string;
      phone_number: string;
      profile_image: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.put(
        ApiConstants.UPDATE_USER_DATA,
        userData
      );
      return response.data.data;
    } catch (error: any) {
      console.log("error in updating user data", error);
      return rejectWithValue(
        error.response?.data || "Unable to update user data."
      );
    }
  }
);

export const refreshAccessToken = createAsyncThunk(
  "auth/refreshAccessToken",
  async (_, { rejectWithValue }) => {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      if (!refresh_token) {
        throw new Error("No refresh token available");
      }
      const response = await apiClient.post(ApiConstants.REFRESHTOKEN, {
        refresh_token,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to refresh token");
    }
  }
);
export const logoutAPI = createAsyncThunk(
  "auth/logoutAPI",
  async (_, { rejectWithValue }) => {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      console.log("refresh_token :>> ", refresh_token);
      if (!refresh_token) {
        throw new Error("No refresh token available");
      }
      const response = await apiClient.post(ApiConstants.REFRESHTOKEN, {
        refresh_token,
      });
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to refresh token");
    }
  }
);
