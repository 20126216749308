import React, { useEffect, useState } from "react";
import type { TableColumnsType } from "antd";
import { useNavigate } from "react-router-dom";
import ActionIcon from "../../../atoms/ActionIcon/ActionIcon";
import OptionMenu from "../../../atoms/OptionMenu/OptionMenu";
import TableComponent from "../../../atoms/TableComponent/Tablecomponent";
import { useAppDispatch, useAppSelector} from "../../../../app/utils/hooks/reduxHook";
import { deleteRankAPI, getAllRank } from "../../../../redux/API/RankAPI";
import { RankDetail } from "../../../../dto/Rank.dto";
import RankEditModal from "../../../atoms/RankEditModal/RankEditModal";
import { confirmModalProps } from "../../../../dto/confirm";
import { image } from "../../../../app/utils/common/image";
import ConfirmModal from "../../../atoms/confirmation-modal/confirmation-modal";
import { setRankLoading } from "../../../../redux/Slices/rankSlice";
import { showSuccessToast } from "../../../../app/utils/common/Toast";

const ActionButtonContainer: React.FC<{ Ranks: any, setRemoveModal: (data:boolean)=> void, deleteRankFunction: (id: number)=> void, setIsEditModalOpen :(data:boolean)=>void, setCurrentRank: () => void }> = ({ Ranks, setIsEditModalOpen, setCurrentRank, deleteRankFunction }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const confirmModalProps: confirmModalProps = {
    text: "Are you sure you want to remove this Rank?",
    confirmModal: true,
    setConfirmModal: setRemoveModal,
    buttons: ["Yes, Remove", "Cancel"],
    onPressPositive: () => {
      deleteRankFunction(Ranks.id);
      setRemoveModal(false);
    },
    onPressNegative: () => setRemoveModal(false),
    image: image.ADD_ICON,
  };

  const options = [
    {
      name: "Edit",
      onClick: () => {
        setIsEditModalOpen(true);
        setCurrentRank();
        // navigate(`rank/edit`);
      },
    },
    {
      name: "Remove",
      onClick: () => {
        // alert(`Removing ${Ranks.id}`);
        setRemoveModal(true)
        // deleteRankFunction(Ranks.id)
      },
    },
  ];
    
  return (
    <div className="action_tab" style={{ position: "relative" }}>
      <div className="action-group" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <ActionIcon />
      </div>
      {isMenuOpen && (
        <OptionMenu
          options={options}
          left="-0px"
          top="28px"
          onOutSideClick={() => setIsMenuOpen(false)}
        />
      )}
    {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

    </div>
    
  );
};

const RankTable: React.FC = () => {
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>();
    const navigate = useNavigate();
    const { loading, allRanks } = useAppSelector((state) => state.rank );
    const dispatch = useAppDispatch();
    const [currentRank, setCurrentRank] = useState<RankDetail>();
    const [pagination, setPagination] = useState({
      current_page: 1,
      page_size: allRanks.length,
      total_pages: 1,
      total_data: allRanks.length,
    });
    const [removeModal, setRemoveModal] = useState<boolean>(false);

  
  useEffect(() => {
       dispatch(getAllRank());
   }, [dispatch]);

  const handlePageChange = (pageNumber: number) => {};

  const handleDeleteRank = async (id: number) => {
    dispatch(setRankLoading(true));
    setRemoveModal(false);
    try {
      await dispatch(deleteRankAPI({ rank_Id: id })).unwrap().then(() => {
        showSuccessToast("Rank Deleted Successfully!");
        dispatch(getAllRank()).unwrap().then(() => {
          dispatch(setRankLoading(false));
        })
      })
    } catch (error) {
      dispatch(setRankLoading(false));
      console.error("Failed to delete the rank:", error);
    }
  };
  const columns: TableColumnsType<RankDetail> = [
    {
      title: "Rank",
      dataIndex: "name",
      render: (_, record) => (
        <div
          onClick={() => navigate(`${record.name}`)}
          style={{ cursor: "pointer" }}
        >
          {record.name}
        </div>
      ),
    },
    {
      title: "Level",
      dataIndex: "level",
    },
      {
        title: "Video",
        dataIndex: "videos",
      },
      {
        title: "referrals",
        dataIndex: "referrals",
      }, 
      {
        title: "Site Visits",
        dataIndex: "site_visits",
      },
        {
        title: 'Action',
        dataIndex: 'action',
          render: (_, record) => <ActionButtonContainer setCurrentRank={() => { setCurrentRank(record) }} Ranks={record} deleteRankFunction={handleDeleteRank}          setIsEditModalOpen={setIsEditModalOpen} setRemoveModal={setRemoveModal} />,
        },
  ];

  return (
    <div className="registration_dataTable">
      <TableComponent
        columns={columns}
        dataSource={allRanks}
        loading={loading}
        pageNumbers={Array.from(
          { length: pagination.total_pages },
          (_, index) => index + 1
        )}
        handlePageLink={handlePageChange}
        isPagination={false}
        totalProfile={pagination.total_data}
        prevPage={() => handlePageChange(pagination.current_page - 1)}
        nextPage={() => handlePageChange(pagination.current_page + 1)}
        activePage={pagination.current_page}
        currentTotal={Math.min( pagination.current_page * pagination.page_size, allRanks.length )}
      />
        {isEditModalOpen && currentRank && <RankEditModal currentRank={currentRank} setIsEditModalOpen={setIsEditModalOpen} />}
    </div>
  );
};

export default RankTable;
