import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import axios, { CancelTokenSource } from "axios";
import { MAX_TABLE_DATA_PER_PAGE } from "../../app/utils/apis/Constant";

let cancelToken: CancelTokenSource | undefined;

export const getAllBrokers = createAsyncThunk(
  "broker/getAllBrokers",
  async (
    {
      page,
      limit = MAX_TABLE_DATA_PER_PAGE,
      searchText = "",
      sortBy
    }: { page: number; limit?: number; searchText?: string, sortBy?: string },
    { rejectWithValue }
  ) => {
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const paramsPayload: any = {
        page: page,
        limit: limit,
        searchText: searchText
      }
      if (sortBy && sortBy?.length > 0) {
        paramsPayload.sortBy = sortBy;
      }
      const response = await apiClient.get(ApiConstants.GET_ALL_BROKERS, {
        params: paramsPayload,
      });
      return response.data.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      } else {
        return rejectWithValue(
          error.response?.data?.error || "Failed to get brokers"
        );
      }
    }
  }
);

export const getTopBrokers = createAsyncThunk(
  "broker/getTopBrokers",
  async (
    { page, total_page = 10 }: { page: number; total_page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_TOP_BROKERS}/?page=${page}&limit=${total_page}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all brokers");
    }
  }
);

export const createBrokerAPI = createAsyncThunk(
  "broker/createBroker",
  async (
    data: {
      name: string;
      country_code: string;
      phone_number: string;
      email: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.CREATE_BROKER}`,
        data
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const updateBrokerAPI = createAsyncThunk(
  "broker/updateBrokerAPI",
  async (
    data: {
      cpId: number;
      name?: string;
      country_code?: string;
      phone_number?: string;
      email?: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let { cpId, ...reqObj } = data;
      const response = await apiClient.put(
        `${ApiConstants.UPDATE_BROKER}/${cpId}/update`,
        reqObj
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to update broker");
    }
  }
);

export const deleteBrokerAPI = createAsyncThunk(
  "broker/deleteBrokerAPI",
  async (data: { cpId: number }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `${ApiConstants.DELETE_BROKER}/${data.cpId}/delete`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to delete broker");
    }
  }
);

export const getCPsManagerAPI = createAsyncThunk(
  "broker/getCpsManager",
  async (
    {
      cpId,
      page,
      total_page = 10,
    }: { cpId: number | any; page: number; total_page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_CPs_MANAGER}/${cpId}/managers?limit=${total_page}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all brokers");
    }
  }
);

export const getCPsSiteVisitAPI = createAsyncThunk(
  "broker/getCPsSiteVisitAPI",
  async (
    {
      cpId,
      page,
      total_page = 10,
    }: { cpId: number | any; page: number; total_page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_CPs_SITE_VISITS}/${cpId}/site-visit?limit=${total_page}`
      );
      return response.data.data.cp_site_visits;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all brokers");
    }
  }
);

//update Incentive
export const upadateIncentiveAPI = createAsyncThunk(
  "broker/upadateIncentiveAPI",
  async (
    data: { incentive: number, userId: number},

    { rejectWithValue }
  ) => {
    try {
      const { userId, incentive } = data;

      const response = await apiClient.post(
        `${ApiConstants.UPDATE_INCENTIVES}/${userId}/settled`,
        { incentive }
      );
      return response.data.data;
    } catch (error: any) {
      console.log("error print", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

