import React, { useRef, useState } from 'react'
import { image } from '../../../../app/utils/common/image';
import './TrainingVideo.scss';
import Modal from '../../../atoms/Modal/Modal';
import { useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';

interface TrainingVideoPropsType {

}

const TrainingVideo = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [selectedVideo, setSelectedVideo] = useState<{
        url: string,
        id: number,
        type: number,

    }>();
    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

    const { SingleProject } = useAppSelector(state => state.project)
    const handleVideoClick = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };
    return (
        <div className='training_video_container'>

            {SingleProject?.media_urls.training_videos.map(video => (
                <div className='training_video'>
                    <img className='img' src={getImageURL(video.thumbnail)} />
                    <div className='play-button-div' onClick={() => { setSelectedVideo(video); setShowVideoModal(true) }}>
                        <img className='play_icon' src={image.VIDEO_PLAY_ICON}></img>
                    </div>
                </div>
            ))}
            {
                showVideoModal &&
                <Modal isClose={true} onClose={() => setShowVideoModal(false)}>
                    <div className='single_video_container'>
                        <video
                            ref={videoRef}
                            className='video'
                            controls={true}
                            autoPlay={false}
                            onClick={handleVideoClick}
                            width="500"
                        >
                            <source src={getImageURL(selectedVideo?.url ?? "")} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default TrainingVideo