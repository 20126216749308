import React, { FC, useEffect, useId, useState } from "react";
import type { TableColumnsType } from "antd";
import Button from "../../atoms/Button/Button";
import TableComponent from "../../atoms/TableComponent/Tablecomponent";
import { useAppDispatch, useAppSelector, } from "../../../app/utils/hooks/reduxHook";
import { getRegistrationRequestsAPI, VerifyRegistrationReqAPI, } from "../../../redux/API/RegistrationRequestAPI";
import { RegristrationRequestType } from "../../../dto/RegistrationRequest.dto";
import { getImageURL } from "../../../app/utils/common/getImageUrl";
import { image } from "../../../app/utils/common/image";
import { setRegistrationReqLoading, setRegistrationReqPagination } from "../../../redux/Slices/registrationRequestSlice";
import ConfirmModal from "../../atoms/confirmation-modal/confirmation-modal";
import { confirmModalProps } from "../../../dto/confirm";
import { showErrorToast, showSuccessToast } from "../../../app/utils/common/Toast";
import { rejectModalProps } from "../../../dto/reject.dto";
import RejectModal from "../../atoms/RejectModal/RejectModal";
import Loader from "../../atoms/Loader/Loader";
import moment from "moment";
import EmptyPage from "../../atoms/EmptyPage/EmptyPage";

const RegistrationReqTable: FC = () => {
    const { requests, pagination, loading } = useAppSelector(
        (state) => state.registrationRequest
    );
    const dispatch = useAppDispatch();
    const [AcceptRegistrationReqModal, setRegistrationReqModal] = useState(false);
    const [userId, setUserId] = useState(0);
    const [RejectRegistrationReqModal, setRejectRegistrationReqModal] = useState(false);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [rejectLoading, setRejectLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            dispatch(
                getRegistrationRequestsAPI({
                    page: pagination.current_page,
                    registration_req_Status: "pending",
                })
            );
        }
    }, [pagination.current_page]);

    const acceptModalProps: confirmModalProps = {
        text: "Are you sure you want to Accept this request?",
        confirmModal: AcceptRegistrationReqModal,
        setConfirmModal: setRegistrationReqModal,
        buttons: ["Yes, Accept", "Cancel"],
        onPressPositive: () => { handleConfirmationAcceptClick(); },
        onPressNegative: () => { setRegistrationReqModal(false); },
        image: image.ADD_ICON,
    };

    const rejectModalProps: rejectModalProps = {
        heading: "Are you sure you want to Reject this request?",
        showRejectModal: RejectRegistrationReqModal,
        setShowRejectModal: setRejectRegistrationReqModal,
        rejectReason: rejectReason,
        setRejectReason: setRejectReason,
        handleRejectedSaveBtn: () => { handleConfirmationRejectClick() },
    };


    const handleConfirmationAcceptClick = async () => {
        setRegistrationReqModal(false);
        try {
            dispatch(setRegistrationReqLoading(true))
            dispatch(VerifyRegistrationReqAPI({ userId, status: 2 }))
                .unwrap()
                .then(() => {
                    showSuccessToast("Request accepted successfully!");
                    dispatch(getRegistrationRequestsAPI({ page: pagination.current_page, registration_req_Status: "pending", })).unwrap().finally(() => {
                        dispatch(setRegistrationReqLoading(false))
                    });

                });
        } catch (error) {
            console.error(`Failed to approve request ${userId}:`, error);
        } finally {
        }
    };

    const handleConfirmationRejectClick = async () => {
        if (rejectReason.trim().length === 0) {
            showErrorToast("Please provide a reason for rejecting the request.");
            return;
        }
        setRejectLoading(true);
        setRejectRegistrationReqModal(false);
        try {
            await dispatch(
                VerifyRegistrationReqAPI({ userId, status: 3, reject_reason: rejectReason })
            ).unwrap();
            showSuccessToast("Request rejected successfully");
            setRejectReason("");

            // Refresh the list
            await dispatch(
                getRegistrationRequestsAPI({ page: pagination.current_page, registration_req_Status: "pending", })).unwrap();
        } catch (error) {
            console.error(`Failed to reject request ${userId}:`, error);
            showErrorToast("Failed to reject the request. Please try again.");
        } finally {
            setRejectLoading(false);
        }
    };
    // Handle page changes
    const handlePageChange = (pageNumber: number) => {
        if (!loading) {
            let tempData = { ...pagination };
            tempData.current_page = pageNumber;
            dispatch(setRegistrationReqPagination(tempData));
        }
    };

    const handleAcceptClick = (userId: number) => {
        setRegistrationReqModal(true);
        setUserId(userId);
    };
    const handleRejectClick = (userId: number) => {
        setRejectRegistrationReqModal(true);
        setUserId(userId);
    }
    const columns: TableColumnsType<RegristrationRequestType> = [
        {
            title: "Profile",
            dataIndex: "profile_image",
            render: (profile) => (
                <img
                    src={getImageURL(profile) || image.DEFAULT_PROFILE_IMAGE}
                    onError={(e: any) => (e.target.src = image.DEFAULT_PROFILE_IMAGE)}
                    alt="Profile"
                    className="reg-profile"
                />
            ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            // render: (date) => moment(date).format("DD-MM-YYYY"),
            render: (date) => (
                <div style={{ width: "max-content" }}>
                    {moment(date).format("DD-MM-YYYY")}
                </div>
            )
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Phone Number",
            dataIndex: "phone_number",
            render: (_, record) => (
                <div>
                    {record.country_code}{record.phone_number}
                </div>
            )
        },

        {
            title: "City",
            dataIndex: "city",
            render: (_, record) => (
                <div>
                    {record.city}
                </div>
            )
        },
        {
            title: "Area",
            dataIndex: "leaving_area",
            render: (_, record) => (
                <div>
                    {record.leaving_area}
                </div>
            )
        },
        {
            title: "Referral code",
            dataIndex: "referral_code",
            render: (_, record) => (
                <div>
                    {record.referral_code}
                </div>
            )
        },
        {
            title: "Referral Name",
            dataIndex: "referrer_name",
            render: (_, record) => (
                <div>
                    {record.referrer_name}
                </div>
            )
        },
        {
            title: "Referral Phone Number",
            dataIndex: "referrer_phone_number",
            render: (_, record) => (
                <div>
                    {record.referrer_phone_number}
                </div>
            )
        },
        {
            title: "Referred by",
            dataIndex: "refer_by",
            render: (_, record) => (
                <div>
                    {record.refer_by}
                </div>
            )
        },


        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <div className="reject-accept-btn">
                    <Button
                        buttonTitle="Reject"
                        onClick={() => handleRejectClick(record.id)}
                        className="RejectButton"
                    />
                    <Button
                        buttonTitle="Accept"
                        onClick={() => handleAcceptClick(record.id)}
                        className="RejectButton AcceptButton"
                    />
                </div>
            ),
        },

    ];

    return (
        <div className="registration_dataTable">
            {rejectLoading ? <div className='center_div'><Loader /></div> :
                requests.length > 0?
                    <TableComponent
                        columns={columns}
                        loading={loading}
                        dataSource={requests}
                        pageNumbers={Array.from({ length: pagination.total_pages }, (_, index) => index + 1)}
                        handlePageLink={handlePageChange}
                        isPagination={pagination.total_pages > 1}
                        totalProfile={pagination.total_data}
                        prevPage={() => handlePageChange(pagination.current_page - 1)}
                        nextPage={() => handlePageChange(pagination.current_page + 1)}
                        activePage={pagination.current_page}
                        currentTotal={Math.min(pagination.current_page * pagination.page_size, requests.length)}
                    /> :
                    <div className="EmptyPageSite_container_post">
                        <EmptyPage
                        image={image.NO_REGISTRATION_REQUEST_ADDED_YET_IMAGE}
                        text="No Request Added yet."
                        />
                     </div>
            }
            {AcceptRegistrationReqModal && <ConfirmModal confirmModalProps={acceptModalProps} />}
            {RejectRegistrationReqModal && <RejectModal rejectModalProps={rejectModalProps} />}

        </div>
    );
};

export default RegistrationReqTable;
