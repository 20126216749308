import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook'
import { getUserData } from '../../../redux/API/UserAPI';
import Sidebar from '../../template/Sidebar';
import './Dashboard.scss';
import Navbar from '../../template/Navbar';
import { checkPermission, permissionType } from '../../../app/utils/common/checkPermission';
import Loader from '../../atoms/Loader/Loader';
import { getUnseenNotificationAPI } from '../../../redux/API/NotificationAPI';
import { PaginationType } from '../../../dto/Pagination.dto';
import { SetNotificationPagination, SetUnseenNotificationCount } from '../../../redux/Slices/notificationSlice';
const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, user, loading, access_token } = useAppSelector(state => state.auth);
  const {pagination} = useAppSelector((state)=> state.notification)
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (!isAuthenticated && !user) {
      if (localStorage.getItem('access_token') || access_token) {
        dispatch(getUserData())
      } else {
        navigate('/login');
      }
    }
  }, [dispatch, isAuthenticated, user])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUnseenNotificationAPI({ page: 1 }))
    }
  }, [dispatch, isAuthenticated])
  
  useEffect(() => {
    if (isAuthenticated && user) {
      const pathParts = path.split('/').filter(Boolean);
      let key = pathParts.length > 0 && pathParts[1] ? pathParts[1] : "/";
      let flag = true;
      switch (key) {
        case "site-management":
          if (!checkPermission(permissionType.SITE_MODULE, "read")) {
            navigate("/not-found");
          }
          return;
        case "manage-manager":
          if (!checkPermission(permissionType.MANAGER_MODULE, "read")) {
            navigate("/not-found");
          }
          return;
        case "manage-broker":
          if (!checkPermission(permissionType.BROKER_MODULE, "read")) {
            navigate("/not-found");
          }
          return;
        case "manage-group":
          if (!checkPermission(permissionType.GROUP_MODULE, "read")) {
            navigate("/not-found");
          }
          return;
        case "post-requests":
          if (!checkPermission(permissionType.POST_MODULE, "read")) {
            navigate("/not-found");
          }
          return;
        case "registration-request":
          if (!checkPermission(permissionType.USER_REGISTRATION_REQUEST, "read")) {
            navigate("/not-found");
          }
          return;
        case "all-users":
          if (!checkPermission(permissionType.USER_MODULE, "read")) {
            navigate("/not-found");
          }
          return;
        default:
          return;
      }
    }
  }, [isAuthenticated, user, path])
  return (
    <div className='dashboard-container'>
      {loading ? <div className='loading-container'><Loader /> </div> :

        <>
          <Sidebar />
          <div className='dashboard-outlet-container'>
            <div className='navbar'>
              <Navbar />
            </div>
            <div className='outlet'>
              <Outlet />
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Dashboard