import React from 'react'
import Input from '../../../atoms/Input/Input'
import { image } from '../../../../app/utils/common/image'
import { useAppSelector } from '../../../../app/utils/hooks/reduxHook'
import { getImageURL } from '../../../../app/utils/common/getImageUrl'

const DetailsHeader = () => {
    const { currentManager } = useAppSelector(state => state.manager)
    return (
        <div className='group-container broker-container'>
            <div style={{ position: "relative" }}>
                <div>
                    <img src={currentManager?.profile_image ? getImageURL(currentManager.profile_image) : image.DEFAULT_PROFILE_IMAGE} className='broker-profile' />
                    {/* <img src={image.CAMERA_PHOTO} alt="camera icon" className="broker-camera_image" /> */}
                </div>
            </div>
            <div className='broker-main-container'>
                <div className='group-name-container'>
                    <div className='group-photo'>Name</div>
                    <Input
                        placeholder="Enter Name"
                        type="text"
                        name="broker Name"
                        id="brokerName"
                        value={currentManager?.name}
                        onChange={() => { }}
                        iconSrc={image.PhoneLogo}
                        disabled={true}
                        showIcon={true}
                    />
                </div>
                {/* <div className='group-name-container'>
                    <div className='group-photo'>Email ID</div>
                    <Input
                        placeholder="Enter Email"
                        type="email"
                        name="broker Email"
                        id="brokerEmail"
                        value={currentManager?.email}
                        onChange={() => { }}
                        iconSrc={image.EmailLogo}
                        disabled={true}
                        showIcon={true}
                    />
                </div> */}
                <div className='group-name-container'>
                    <div className='group-photo'>Phone Number</div>
                    <Input
                        placeholder="Enter Phone Number"
                        type="number"
                        name="broker number"
                        id="brokerNumber"
                        value={currentManager?.phone_number}
                        onChange={() => { }}
                        iconSrc={image.PhoneLogo}
                        disabled={true}
                        showIcon={true}
                    />
                </div>

                <div className='group-name-container'>
                    <div className='group-photo'>Referral Code</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="Referral Code"
                        id="referralCode"
                        value={currentManager?.referral_code}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
               
                <div className='group-name-container'>
                    <div className='group-photo'>Referral Name</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="Referral Name"
                        id="referrerName"
                        value={currentManager?.referrer_name}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Referral Phone Number</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="Referral Phone Number"
                        id="referralPhoneNumber"
                        value={currentManager?.referrer_phone_number}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Refer By</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="broker Name"
                        id="brokerName"
                        value={currentManager?.refer_by}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
            </div>

        </div>
    )
}

export default DetailsHeader;