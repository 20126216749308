import React, { FC, useEffect, useRef, useState } from 'react'
import Button from '../../atoms/Button/Button'
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { resendOtp } from '../../../redux/Slices/authSlice';
import Loader from '../../atoms/Loader/Loader';
import { message } from 'antd';


interface VerifyLoginProps {
    handleOtpChange: Function,
    otp: string[],
    setOtp: React.Dispatch<React.SetStateAction<string[]>>,
    handleVerifyOtp: Function,
    setActiveButton: React.Dispatch<React.SetStateAction<boolean>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    otpId: string,
    errorMessage: string,
}

const VerificationScreen: FC<VerifyLoginProps> = (props) => {
    const { handleOtpChange, otp, setOtp, handleVerifyOtp, setActiveButton, otpId, errorMessage, loading, setLoading } = props;
    const dispatch: AppDispatch = useDispatch();
    const inputsRef = useRef<any>([]);
    const [countdown, setCountdown] = useState(59);
    const [showResendLink, setShowResendLink] = useState<boolean>(false);

    useEffect(() => {
        let timer: any;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else {
            setShowResendLink(true)
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleKeyDown = (e: any, index: number) => {
        if (e.key === "Backspace") {
            if (otp[index] === "" && index > 0) {
                let finalIdex = index - 1;
                inputsRef.current[finalIdex].focus();
            }
            setActiveButton(false);
        } else if (e.key === "Enter") {
            if (otp.every((digit) => digit !== "")) {
                handleVerifyOtp();
            }
        }
    };

    const handleInputChange = (e: any, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            handleOtpChange(e, index);
            if (value !== '' && index < 3) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleResendOtp = async () => {
        setLoading(true);
        if (countdown === 0) {
            setLoading(false);
            await dispatch(resendOtp({ otpId })); // Dispatch the resend OTP action
            setCountdown(59); // Reset the countdown after resend
            setOtp(["", "", "", ""]);
            setShowResendLink(false);

        }
    };

    return (
        <div className='verification_main'>
            {!loading ? (<>   <div className='verification_title_desc'>
                <p className="verification-Heading">Verification Code</p>
                <p className="verification-desc">Please enter the OTP received via SMS on the mobile number you have provided</p>
            </div>


                <div className='verify-container'>
                    <div className='otp-container'>
                        <p className="otp-label">OTP</p>
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="number"
                                maxLength={1}
                                placeholder='-'
                                value={digit}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputsRef.current[index] = el)}
                                className='otp-input'
                            />
                        ))}
                    </div>
                    <div>
                        {/* {errorMessage && <div style={{ color: 'red', fontSize: '12px', marginLeft: '15px' }}>{errorMessage}</div>} */}
                    </div>

                    <div className='resend-container' >
                        <label className='resent-text'>Resent OTP:
                            <span className=' resent-timer'>
                                {countdown > 0 ? (` 00:${countdown}`) : <div className='resend-button' onClick={handleResendOtp}>Resend</div>}
                            </span>
                        </label>
                    </div>

                    <div className='verifyNow-button_div'>
                        <Button buttonTitle='Verify Now' onClick={() => { handleVerifyOtp() }} className='verifyNow-button ' />
                    </div>
                </div> </>) : ""}


        </div>

    )
}

export default VerificationScreen
