import React from 'react'
import { image } from '../../../app/utils/common/image'
import { useNavigate } from 'react-router-dom';
import UserManagementTable from '../../molecules/UserManagement/UserManagementTable';

const UserManagement = () => {
   const navigate = useNavigate();

  return (
    <div className='manager_container'>
    <div className='manager_header'>
        <div className='allManager'>
            User Management
        </div>
        <div className='button_container'>
                <div className='btn' onClick={() => { navigate('broker/create') }}>
                    <img className='btn_icon' src={image.ADD_ICON} />
                    <div>
                        Add
                    </div>
                </div>
        </div>
    </div>

    <div className='manager_data_table'>
        <UserManagementTable />
    </div>
</div >
  )
}

export default UserManagement
