import React, { useEffect, useState } from "react";
import "../AuthScreens/AuthScreens.scss";
import { image } from "../../../app/utils/common/image";
import SignUpScreen from "./SignUpScreen";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  sendOtp,
  setIsRemeber,
  verifyOtp,
} from "../../../redux/Slices/authSlice";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import Loader from "../../atoms/Loader/Loader";
import VerificationScreen from "./VerificationScreen";
import InputField from "../../atoms/InputField/InputField";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import ErrorText from "../../atoms/Error/error";
import { message } from "antd";
import {
  checkPermission,
  permissionType,
} from "../../../app/utils/common/checkPermission";
import { useRequestPermission } from "../../../firebase";

const LoginFormV1 = () => {
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("+91");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeButton, setActiveButton] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state: any) => state.auth);
  const requestPermission = useRequestPermission();

  const handleSendOtp = () => {
    setLoading(true);
    setErrorMessage("");
    if (isChecked) {
      dispatch(setIsRemeber(true));
    }
    dispatch(sendOtp({ phoneNumber, countryCode, portal: "web_admin" })).then(
      (action: any) => {
        // Dispatch the send OTP action
        setLoading(false);
        if (action.type === "auth/sendOtp/fulfilled") {
          setShowOtpModal(true);
          setErrorMessage("");
        } else if (action.type === "auth/sendOtp/rejected") {
          message.error(action.payload.error?.message);
          setErrorMessage(action.payload.error?.message);
        }
      }
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeButton) {
      handleSendOtp();
    }
  };
  const deviceToken = JSON.parse(
    JSON.stringify(localStorage.getItem("deviceToken"))
  );
  const handleVerifyOtp = () => {
    const otpString = otp.join("");
    setLoading(true);
    setErrorMessage("");
    let payload: any = {
      countryCode,
      phoneNumber,
      otpId: authState.otpId,
      otp: otpString,
    };
    if (deviceToken) payload.device_token = deviceToken;
    dispatch(verifyOtp(payload)) // Dispatch the verify OTP action
      .then((action: any) => {
        setLoading(false);
        if (action.type === "auth/verifyOtp/fulfilled") {
          // navigate('/dashboard/')
          // { checkPermission(permissionType.USER_REGISTRATION_REQUEST, "read") && checkPermission(permissionType.SITE_MODULE, "read") && checkPermission(permissionType.MANAGER_MODULE, "read") && checkPermission(permissionType.BROKER_MODULE, "read") ? navigate('/dashboard/') : navigate('/dashboard/registration-request') }

          {
            checkPermission(permissionType.USER_MODULE, "read") &&
            checkPermission(permissionType.SITE_MODULE, "read") &&
            checkPermission(permissionType.MANAGER_MODULE, "read") &&
            checkPermission(permissionType.BROKER_MODULE, "read")
              ? navigate("/dashboard/")
              : navigate("/dashboard/all-users");
          }
        } else {
          setErrorMessage(
            action.payload?.error?.message || "Failed to verify OTP"
          );
          message.error(
            action.payload?.error?.message || "Failed to verify OTP"
          );
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setErrorMessage("Error verifying OTP: " + err.message);
      });
  };

  const handlePhoneChange = (event: any) => {
    // console.log('event.target.value :>> ', event.target.value);
    if (event.target.value.length <= 10) {
      setPhoneNumber(event.target.value);
      setActiveButton(event.target.value.length === 10);
      setErrorMessage("");
    }
  };

  const handleOtpChange = (e: any, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    setErrorMessage("");
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="AuthForm_main">
      <div className="AuthForm_Card">
        <img
          className="AuthPageLogo"
          src={image.ARB_WHITE_LOGO_IMAGE}
          alt="Login Page Logo"
        />
        <div className="authFormContainer">
          <div className="upperBox"></div>
          <div className="auth_Form">
            {!loading ? (
              !showOtpModal ? (
                <>
                  <p className="auth_Form-Heading">Login</p>
                  <div className="auth_form-Inputs_container">
                    <InputField
                      label="Phone Number"
                      type="number"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                      onKeyDown={handleKeyDown}
                      iconSrc={image.PhoneLogo}
                    />

                    <div className="CheckBoxContainer">
                      <div>
                        <Checkbox
                          checked={isChecked}
                          onClick={() => setIsChecked(!isChecked)}
                        />
                      </div>
                      <label htmlFor="remember-me-checkbox">Remember Me</label>
                    </div>

                    <div className="authButton_div">
                      <Button
                        buttonTitle="Get OTP"
                        onClick={activeButton ? handleSendOtp : undefined}
                        className={
                          activeButton ? "authButtonActive" : "authButton"
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            ) : (
              <div className="loader_container">
                <Loader />
              </div>
            )}

            {showOtpModal && (
              <VerificationScreen
                handleOtpChange={handleOtpChange}
                otp={otp}
                handleVerifyOtp={handleVerifyOtp}
                setActiveButton={setActiveButton}
                otpId={authState.otpId}
                setOtp={setOtp}
                errorMessage={errorMessage}
                loading={loading}
                setLoading={setLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginFormV1;
