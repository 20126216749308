// InputField.tsx
import React from 'react';
import './InputField.scss';

interface InputFieldProps {
    label: string;
    type: string;
    placeholder: string;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    iconSrc?: string;
    showIcon?: boolean;
    disabled?: boolean;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // Correct type here

}

const InputField: React.FC<InputFieldProps> = ({
    label,
    type,
    placeholder,
    value,
    onChange,
    iconSrc,
    showIcon = true,
    disabled = false,
    onKeyDown,
}) => {
    return (
        <div className="inputField">
            <label>{label}</label>
            <div className="inputWrapper">
                {showIcon && iconSrc && <img src={iconSrc} alt={`${label} icon`} className="inputIcon" />}
                <input
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className="input"
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                />
            </div>
        </div>
    );
};

export default InputField;
