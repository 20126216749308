import React from 'react'
import { useNavigate } from 'react-router-dom';
import { image } from '../../../../app/utils/common/image';
import ProjectsTabs from '../../../molecules/SiteManagement/ProjectsTab'
import './SiteManagement.scss';
import { checkPermission, permissionType } from '../../../../app/utils/common/checkPermission';

const SiteManagement = () => {
    const navigate = useNavigate();
    return (
        <div className='site_management_page_container'>
            <div className='site_management_header'>
                <div className='header_text'>
                    Sites Management
                </div>
                <div className='button_container'>
                    {
                        checkPermission(permissionType.SITE_MODULE, "create") &&
                        <div className='btn' onClick={() => navigate("create")}>
                            <img className='btn_icon' src={image.ADD_ICON} />
                            <div>
                                Add
                            </div>
                        </div>
                    }

                </div>
            </div>
            <div className='project_list_container'>
                <ProjectsTabs />
            </div>
        </div>
    )
}

export default SiteManagement