import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios";
import { refreshAccessToken } from "../../redux/API/UserAPI";
import { BASE_URL } from "./axiosInstance";
import {
  clearToken,
  setAccessToken,
  setToken,
} from "../../redux/Slices/authSlice";

const apiClient = axios.create({
  baseURL: BASE_URL,
});

const getStore = () => {
  const store = require("../../redux/store").default;

  return store;
};

let isRefreshing = false;
let failedQueue: any[] = [];

// const requestsMap = new Map<string, AbortController>(); // Keeps track of active requests

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

apiClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const requestKey = config.url || "";
    let store = getStore();
    let state = store.getState();
    const accessToken =
      state.auth.access_token ?? localStorage.getItem("access_token");
    // console.log("🚀 ~ requestKey:", requestKey, "accessToken", accessToken);
    // const controller = new AbortController();
    // if (requestsMap.has(requestKey) && accessToken) {
    //   const existingController = requestsMap.get(requestKey);
    //   console.log("🚀 ~ existingController:", existingController);
    //   //   existingController?.abort(); // Cancel previous request
    // }

    // config.signal = controller.signal;
    // requestsMap.set(requestKey, controller);

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // Clear completed request from the map
    // const requestKey = response.config.url || "";
    // requestsMap.delete(requestKey);

    return response;
  },
  async (error: AxiosError) => {
    // console.log("🚀 ~ error:", error);
    const originalRequest = error.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };

    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise<string>((resolve: any, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            if (originalRequest.headers) {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
            }
            return apiClient(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        getStore()
          .dispatch(refreshAccessToken())
          .then(({ payload }: any) => {
            const { access_token } = payload.data;
            getStore().dispatch(setAccessToken(access_token));

            apiClient.defaults.headers[
              "Authorization"
            ] = `Bearer ${access_token}`;
            if (originalRequest.headers) {
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${access_token}`;
            }
            processQueue(null, access_token);
            resolve(apiClient(originalRequest));
          })
          .catch((err: any) => {
            processQueue(err, null);
            // console.log("error ", err);
            localStorage.clear(); // Clear local storage to remove tokens
            window.location.href = "/login"; // Redirect to login page
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    if (
      error.response &&
      error.response.status === 401 &&
      error.code !== "ERR_CANCELED"
    ) {
      getStore().dispatch(clearToken());
    }

    // const requestKey = error.config?.url || "";
    // requestsMap.delete(requestKey);

    return Promise.reject(error);
  }
);

export { apiClient };
