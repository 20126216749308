import React from 'react'
import { image } from '../../../app/utils/common/image'
import Button from '../../atoms/Button/Button';
import './ContactUs.scss'

const ContactUs = () => {
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Contact Us
                </div>
            </div>
            <div className='contact-us-container'>
                <img src={image.CONTACT_US_IMAGE} className='contact-icon' />
                <div className='contact-desc'>
                    We're here to help! Whether you're a studio looking for support or a user <br />
                    with inquiries, feel free to reach out to us. Our team is ready to provide the<br />
                    guidance you need to make the most out of ARB Ventures.
                </div>
                <div className='contact-button'>
                    <button className='callusactive' onClick={() => { }}>
                        <img src={image.CALL_WHITE_ICON} className='contact-call-icon' />
                        <a style={{color: "white", textDecorationLine:"none"}}  href={`tel:9133133400`}> Call us </a>
                        {/* <span>Call us : 9133133400</span> */}
                    </button>
                    <button className='callusactive' onClick={() => { }}>
                        <img src={image.EMAIL_WHITE_ICON} className='contact-email-icon' />
                        <a style={{color: "white" , textDecorationLine:"none"}} href={`mailto:info@arbventures.in`}> Email Us </a> 
                    </button>

                </div>
            </div>
        </div>
    )
}

export default ContactUs