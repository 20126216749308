import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import {
  allocateCPsAPI,
  createManagerAPI,
  deallocateCpAPI,
  deleteManagerAPI,
  getAvailableCPsAPI,
  getAllManagers,
  getCpsForManagerAPI,
  getManagersProjectAPI,
  updateManagerAPI,
  getTopManagers,
} from "../../API/ManagerAPI";
import { ManagerSliceState } from "../../Slices/managerSlice";
import {
  ActiveCPsType,
  ManagerType,
  ManagersCPType,
  ManagersProjectType,
} from "../../../dto/Manager.dto";
import { PaginationType } from "../../../dto/Pagination.dto";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../app/utils/common/Toast";

export const ManagerReducer = (
  builder: ActionReducerMapBuilder<ManagerSliceState>
) => {
  builder
    .addCase(getAllManagers.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getAllManagers.fulfilled,
      (
        state,
        action: PayloadAction<{
          manager_data: ManagerType[];
          pagination: PaginationType;
        }>
      ) => {
        if (action.payload) {

          if (state.pagination.current_page === action.payload.pagination.current_page) {
            state.loading = false;
            let test = { ...state.pagination }
            state.refreshTable = false;
            state.managers = action.payload.manager_data;

            if (action.payload.pagination.current_page === 1) {
              state.pagination.page_size = action.payload.pagination.page_size;
              state.pagination.total_pages = action.payload.pagination.total_pages;
              state.pagination.total_data = action.payload.pagination.total_data;
            }
          }
        }
      }
    )
    .addCase(getAllManagers.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    .addCase(getTopManagers.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getTopManagers.fulfilled,
      (
        state,
        action: PayloadAction<{
          top_managers: ManagerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.pagination = action.payload.pagination;
        if (action.payload.pagination.current_page === 1) {
          state.managers = action.payload.top_managers;
        } else {
          state.managers = [
            ...state.managers,
            ...action.payload.top_managers,
          ];
        }
        state.loading = false;
      }
    )
    .addCase(getTopManagers.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    .addCase(createManagerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      createManagerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          manager_data: ManagerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        showSuccessToast("Manager created successfully.");
        state.managers = [];
      }
    )
    .addCase(createManagerAPI.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in manager creation.");
      }
    })
    .addCase(updateManagerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      updateManagerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          manager_data: ManagerType[];
          pagination: PaginationType;
        }>
      ) => {
        showSuccessToast("Manager updated.");
        state.loading = false;
        state.managers = [];
      }
    )
    .addCase(updateManagerAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in manager creation.");
      }
      state.loading = false;
    })
    .addCase(deleteManagerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      deleteManagerAPI.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const tempManagers = [...(state.managers ?? [])];
        const filteredManagers =
          tempManagers.filter(
            (manager) => manager.manager_id !== action.payload.manager_id
          ) ?? [];
        showSuccessToast("Manager deleted successfully.");
        state.managers = [...filteredManagers];
      }
    )
    .addCase(deleteManagerAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in manager creation.");
      }
      state.loading = false;
    })
    .addCase(getManagersProjectAPI.pending, (state) => { })
    .addCase(
      getManagersProjectAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          manager_projects_data: ManagersProjectType[];
          pagination: PaginationType;
        }>
      ) => {
        if (state.ManagersCPsPagination.current_page === action.payload.pagination.current_page) {
          state.ManagersProjectPagination = action.payload.pagination;
          if (action.payload.pagination.current_page === 1) {
            state.ManagersProject = action.payload.manager_projects_data;
          } else {

            state.ManagersProject = [
              ...state.ManagersProject,
              ...action.payload.manager_projects_data,
            ];
          }
        }
      }
    )
    .addCase(
      getManagersProjectAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
        } else {
          showErrorToast("Error in manager creation.");
        }
      }
    )
    .addCase(getCpsForManagerAPI.pending, (state) => { })
    .addCase(
      getCpsForManagerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          manager_cps_data: ManagersCPType[];
          pagination: PaginationType;
          manager_id: number;
        }>
      ) => {
        if (
          action.payload.pagination.current_page ===
          state.ManagersCPsPagination.current_page
        ) {
          if (action.payload.pagination.current_page === 1) {
            state.ManagersCPs = {
              manager_id: action.payload.manager_id,
              cps: action.payload.manager_cps_data,
            };
            state.ManagersCPsPagination = action.payload.pagination;
          } else {
            state.ManagersCPs.cps = [
              ...state.ManagersCPs.cps,
              ...action.payload.manager_cps_data,
            ];
          }
          state.ManagersCPsPagination = action.payload.pagination;
        }
        // state.ManagersCPs = [...state.ManagersCPs, ...action.payload.manager_cps_data];
      }
    )
    .addCase(
      getCpsForManagerAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
        } else {
          showErrorToast("Error in manager creation.");
        }
      }
    )
    .addCase(getAvailableCPsAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getAvailableCPsAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          cp_data: ActiveCPsType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        if (
          action.payload.pagination.current_page ===
          state.availableCPsPagination.current_page
        ) {
          state.availableCPsPagination = action.payload.pagination;
          state.activeCps = action.payload.cp_data;
        }
      }
    )
    .addCase(
      getAvailableCPsAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action?.payload?.message) {
          showErrorToast(action?.payload?.message);
        } else {
          showErrorToast("Error in manager creation.");
        }
        state.loading = false;
      }
    )
    .addCase(allocateCPsAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(allocateCPsAPI.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      showSuccessToast("Brokers allocated successfully.");
    })
    .addCase(allocateCPsAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in manager creation.");
      }
      state.loading = false;
    })
    .addCase(deallocateCpAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      deallocateCpAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          payload: any;
          cp_ids: number[];
        }>
      ) => {
        state.loading = false;
        state.ManagersCPs.cps = state.ManagersCPs.cps.filter(
          (cp) => !action.payload.cp_ids.includes(cp.cp_id)
        );
        showSuccessToast("Broker deallocate successfully.");
      }
    )
    .addCase(deallocateCpAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in manager creation.");
      }
      state.loading = false;
    });
};
