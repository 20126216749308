import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { image } from '../../../../app/utils/common/image';
import Button from '../../../atoms/Button/Button';
import ErrorText from '../../../atoms/Error/error';
import { validatePhoneNumber } from '../../../../app/utils/common/validation';
import Input from '../../../atoms/Input/Input';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { createRankAPI } from '../../../../redux/API/RankAPI';
import { setRankLoading } from '../../../../redux/Slices/rankSlice';
import Loader from '../../../atoms/Loader/Loader';
import { showSuccessToast } from '../../../../app/utils/common/Toast';

const CreateRank = () => {
    const navigate = useNavigate();
    const [name, setName] = useState<string>('');
    const [level, setLevel] = useState<any>();
    const [videos, setVideos] = useState<any>();
    const [referrals, setReferrals] = useState<any>();
    const [siteVisits, setSiteVisits] = useState<any>();
    const [showError, setShowError] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector((state) => state.rank);

    const handleSubmit = () => {
        dispatch(setRankLoading(true));
        dispatch(createRankAPI({
            name,
            level,
            videos,
            referrals,
            site_visits: siteVisits,
        })).unwrap().then(() => {
            dispatch(setRankLoading(false));
            showSuccessToast("Rank Created Succesfully!")
            navigate("/dashboard/rank-management");
        })
    }
    return (
        <>
        {loading?<div className='center_div'><Loader/></div>:   
            <div className='create_project_container' >
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>{"Add"} Rank</div>
            </div>
            <div className='group-details-container broker-add-container'>
                <div className='group-container add-broker-container'>
                    <div style={{ position: "relative" }}>
                    </div>
                    <div className='broker-main-container'>
                        <div className='group-name-container'>
                            <div className='group-photo'>Rank Name</div>
                            <Input
                                placeholder="Enter Rank Name"
                                type="text"
                                name="Rank Name"
                                id="rankName"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                                iconSrc={image?.USER_NAME_ICON}
                                showIcon={true}
                            />
                            {showError && name.trim().length == 0 && <ErrorText message="Please provide broker's name" />}
                        </div>
                        <div className='group-name-container'>
                            <div className='group-photo'>Rank Level</div>
                            <Input
                                placeholder="Enter Rank Level"
                                type="number"
                                name="Level"
                                id="level"
                                value={level}
                                onChange={(e) => { setLevel(e.target.value) }}
                                iconSrc={image?.USER_NAME_ICON}
                                showIcon={true}
                            />
                            {showError && level.trim().length == 0 && <ErrorText message="Please provide broker's name" />}
                        </div>
                        <div className='group-name-container'>
                            <div className='group-photo'>Videos</div>
                            <Input
                                placeholder="Enter Videos"
                                type="number"
                                name="video number"
                                id="videoNumber"
                                value={videos}
                                onChange={(e) => { if (e.target.value.length <= 10) setVideos(e.target.value) }}
                                iconSrc={image.PhoneLogo}
                                showIcon={true}
                            />
                            {showError && videos.trim().length == 0 && <ErrorText message="Please provide broker's name" />}
                            </div>
                            
                        <div className='group-name-container'>
                            <div className='group-photo'>referrals</div>
                            <Input
                                placeholder="Enter referrals"
                                type="number"
                                name="referrals"
                                id="referrals"
                                value={referrals}
                                onChange={(e) => { if (e.target.value.length <= 10) setReferrals(e.target.value) }}
                                iconSrc={image.PhoneLogo}
                                showIcon={true}
                            />
                            {showError && referrals.trim().length == 0 && <ErrorText message="Please provide broker's name" />}
                            </div> 
                            
                            <div className='group-name-container'>
                            <div className='group-photo'>Site Visits</div>
                            <Input
                                placeholder="Enter Site Visits"
                                type="number"
                                name="Site Visits"
                                id="site_visits"
                                value={siteVisits}
                                onChange={(e) => { if (e.target.value.length <= 10) setSiteVisits(e.target.value) }}
                                iconSrc={image.PhoneLogo}
                                showIcon={true}
                            />
                        </div>
                    </div>
                    <div className='grp-submit-container' style={{ marginLeft: "0px" }}>
                        <Button buttonTitle={ "create"} onClick={() => handleSubmit()} className='group_btn_submit' />
                    </div>
                </div>
            </div>
        </div>
        }
    </>
  )
}

export default CreateRank
