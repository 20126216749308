import React, { useEffect, useState } from 'react'
import './App.css';
import AppRoutes from './app/routes/AppRoutes';
import { useAppDispatch, useAppSelector } from './app/utils/hooks/reduxHook';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { messaging, onMessageListener, useRequestPermission } from './firebase';
import { SetNotificationPagination, SetUnseenNotificationCount } from './redux/Slices/notificationSlice';
import Pagination from './components/atoms/Pagination/Pagination';
import store from './redux/store';

function App() {

  const dispatch = useAppDispatch();
  const requestPermission = useRequestPermission();


  useEffect(() => {

    const fetchDeviceToken = async () => {
      const token = await requestPermission();
      if (token) {
        localStorage.setItem('deviceToken', token);
      }
    };
    fetchDeviceToken();
  }, [requestPermission]);



  useEffect(() => {
    // Listen for messages
    onMessageListener().then((payload) => {
      const unseenNotificationCount = store.getState().notification.unseenNotificationCount
      console.log('got notification:::::>> ', payload);
      dispatch(SetUnseenNotificationCount(unseenNotificationCount + 1))
    });
  }, []);

  return (
    <div className='App'>
      <AppRoutes />
      <ToastContainer />
      {/* <GetNotification title="New Notification" body="New Notification body"  time="2pm" /> */}
    </div>
  );
}



export default App;
