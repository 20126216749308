import React, { useEffect, useRef, useState } from 'react'
import { image } from '../../../../app/utils/common/image'
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import { confirmModalProps } from '../../../../dto/confirm';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { ManagerType } from '../../../../dto/Manager.dto';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import EmptyPage from '../../../atoms/EmptyPage/EmptyPage';
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen';
import { setBrokersManagerPagination } from '../../../../redux/Slices/brokerSlice';
import { useParams } from 'react-router-dom';
import { getCPsManagerAPI } from '../../../../redux/API/BrokerAPI';
import { PaginationType } from '../../../../dto/Pagination.dto';
import Loader from '../../../atoms/Loader/Loader';

interface ManageBrokerWorkedProps {
    managers: ManagerType[];
}

const ActionButtonContainer = () => {
    const [isMenuOpen, seIsMenuOpen] = useState<boolean>(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const options = [{
        name: "Edit",
        onClick: () => {
            // console.log("edit click");
        }
    },
    {
        name: "Remove",
        onClick: () => {
            setRemoveModal(true)
        }
    }
    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this manager?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.ADD_ICON
    };


    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-147px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}


const ManageBrokerWorked: React.FC<ManageBrokerWorkedProps> = ({ }) => {


    const dispatch = useAppDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const { brokers, brokerSiteVisit } = useAppSelector(state => state.broker);
    const [cpsManager, setCpsManager] = useState<ManagerType[]>([])
    const [pagination, setPagination] = useState<PaginationType>({
        total_data: 10,
        current_page: 1,
        page_size: 10,
        total_pages: 1
    })
    useEffect(() => {
        const currentBroker = brokers.find((broker) => broker.cp_id.toString() === id);
        if (currentBroker) {
            setLoading(true);
            dispatch(getCPsManagerAPI({ cpId: Number(id), page: pagination.current_page, total_page: pagination.page_size })).unwrap()
                .then((data: {
                    cp_managers_data: ManagerType[],
                    pagination: PaginationType
                }) => {
                    if (data.pagination.current_page === pagination.current_page) {
                        if (data.pagination.current_page === 1) {
                            setCpsManager(data.cp_managers_data);
                        } else {
                            setCpsManager([...cpsManager, ...data.cp_managers_data]);
                        }
                    }
                }).catch(() => { }).finally(() => { setLoading(false) })
        }
    }, [pagination.current_page])
    const handleScroll = () => {

        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loading) {
                // console.log('calling')

                if (pagination.current_page < pagination.total_pages)
                    setPagination({ ...pagination, current_page: pagination.current_page + 1 })
            }
        }
    };

    return (
        <div className="group-manager-container worked-manager">
            <div className="manager-header-flex">
                <div className="group-manager-heading">Worked Under Managers</div>
            </div>
            {loading ? <div style={{ height: "100%", width: "100%" }} className='center_div'><Loader isGray={true} /> </div> :
                <div>
                    {cpsManager?.length > 0 ? (
                        <div ref={scrollRef} onScroll={handleScroll} className="profile_data">
                            {cpsManager.map((manager: any) => (
                                <div key={manager.id} className="profile">
                                    <div className="profile_image_container">
                                        <img src={image.DEFAULT_PROFILE_IMAGE} alt={manager.name} className="profile_image" />
                                    </div>
                                    <div className="data_container">
                                        <div className="profile_data_container">
                                            <div className="profile_name">{manager.name}</div>
                                            {/* <div className="project_text">{manager.country_code} {manager.phone_number}</div> */}
                                        </div>
                                        <div className="profile-end-flex">
                                            <div className="profile_project_container">
                                                <div className="project_text">{manager.country_code} {manager.phone_number}</div>
                                            </div>
                                            {/* <ActionButtonContainer /> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    ) : (
                        <div className='EmptyPageManager_container'>
                            <EmptyPage className='Site_emptyPage' image={image.NO_MANAGER_ADDED_YET_IMAGE} text="No Any Manager added yet!" />
                        </div>
                    )}</div>}

        </div>
    )
}

export default ManageBrokerWorked