import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RankModuleType } from "../../dto/Rank.dto";
import { RankReducer } from "../Reducer/Rank/RankReducer";

export interface RankSliceStateTypes {
    loading: boolean;
    allRanks: RankModuleType[];
  }
  
  const initialState: RankSliceStateTypes = {
    loading: false,
    allRanks: [],
  };
  
  const rankSlice = createSlice({
    name: "rank",
    initialState,
    reducers: {
      setRankLoading(state, action: PayloadAction<boolean>) {
        state.loading = action.payload;
      },
    },
    extraReducers: (builder) => {
      RankReducer(builder);
    },
  });
  export const {setRankLoading} = rankSlice.actions;
  
  export default rankSlice.reducer;
  