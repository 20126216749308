import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

export const getRegistrationRequestsAPI = createAsyncThunk(
  "registrationRequest/getRegistrationRequestsAPI",
  async (
    {
      page,
      total_page = 10,
      registration_req_Status,
      searchText = "",
    }: {
      page: number;
      total_page?: number;
      registration_req_Status: string;
      searchText?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_REGISTRATION_REQUEST}/${registration_req_Status}/?page=${page}&searchText=${searchText ?? ""}&limit=${total_page}`
      );
      return {
        user_data: response.data.data.user_data,
        pagination: response.data.data.pagination,
        status: registration_req_Status,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all registration requests");
    }
  }
);

export const VerifyRegistrationReqAPI = createAsyncThunk(
  "registrationRequest/verifyRegistrationReqAPI",
  async (
    data: { userId: number; status: number; reject_reason?: string },

    { rejectWithValue }
  ) => {
    try {
      const { userId, status, reject_reason } = data;

      const response = await apiClient.post(
        `${ApiConstants.VERIFY_REGISTRATION_REQ}/${userId}/verify`,
        { status, reject_reason }
      );
      return response.data.data;
    } catch (error: any) {
      console.log("error print", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const approveAllRegistrationReqAPI = createAsyncThunk(
  "registrationRequest/approveAllRegistrationReqAPI",
  async (_, { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(`${ApiConstants.APPROVE_ALL_REGISTRATION_REQ}`, {});
      return response.data.data;
    } catch (error: any) {
      console.log("error in approve all registration request", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);
