import React, { useEffect, useRef, useState } from 'react';
import { image } from '../../../app/utils/common/image';
import Card from '../../atoms/Card/Card';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { getImageURL } from '../../../app/utils/common/getImageUrl';
import Loader from '../../atoms/Loader/Loader';
import { setVisitManagementActiveTab, setVisitManangementPagination } from '../../../redux/Slices/visitManagementSlice';
import { getSiteVisitesAPI } from '../../../redux/API/VisitManagementAPI';
import EmptyPage from '../../atoms/EmptyPage/EmptyPage';

interface VisitListProps {
    data: any
    isActive: boolean;
    status: string
}

const VisitsList: React.FC<VisitListProps> = ({ status, data, isActive }) => {
    const { loading, pagination } = useAppSelector((state) => state.vistManagement);
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);

    // Fetch initial data on mount or when status changes
    // useEffect(() => {
    //     dispatch(getSiteVisitesAPI({ page: 1, visit_type: status }));
    // }, [status, dispatch]);

    // pagination
    // Fetch more data when page changes
    useEffect(() => {
        if (pagination.current_page > 1) {
            dispatch(getSiteVisitesAPI({
              page: pagination.current_page,
              visit_type: status,
            }));
        }
      }, [pagination.current_page, status, dispatch]);
      
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loading) {
                if (pagination.current_page < pagination.total_pages)
                {
                    dispatch(setVisitManangementPagination({ ...pagination, current_page: pagination.current_page + 1 }))
                }
            }
        }
    };
    return (
        <div ref={scrollRef} onScroll={ handleScroll} className={isActive ? 'active_projects_container' : 'completed_project_container'}>
            {loading ? (
                <div style={{ height: "100%", width: "100%" }} className='center_div'><Loader /> </div>
            ) : data.length === 0 ?
                <div style={{ height: "90%", width: "40%", marginLeft: "25%" }} className='center_div'><EmptyPage image={image.NO_SITE_VISITED_YET_IMAGE} text='No Visited Sites.' /></div> : (
                    <>
                        {data?.map((visit: any) => (
                            <Card key={visit.project_id} customClass="project_card" isGreen={isActive}>
                                <div className="post-card site-card" onClick={() => {
                                    dispatch(setVisitManagementActiveTab(status));
                                    navigate(`/dashboard/visit-management/visit/${visit.project_id}`);
                                }}>
                                    <div style={{ display: 'contents' }}>
                                        <div className="post-thumbnail-container">
                                            <img className="project-card-thumbnail-siteVisit" src={getImageURL(visit?.url)} alt="" onError={(e: any) => e.target.src = image.DEFAULT_BACKGROUND_IMAGE} />
                                        </div>
                                        <div className="site-visited-title">{visit.name}</div>
                                        <div className="site-visited-desc">{visit.address} {visit.city} {visit.state} {visit.zip_code}</div>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </>
                )
            }
        </div >
    );
};

export default VisitsList;
