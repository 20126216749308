import React from "react";
import { Modal } from "antd";
import { image } from "../../../app/utils/common/image";
import { rejectModalProps } from "../../../dto/reject.dto";
import "./RejectModal.scss"

interface RejectModalProps {
    rejectModalProps: rejectModalProps;
}

const RejectModal: React.FC<RejectModalProps> = ({ rejectModalProps }) => {
    const { showRejectModal, setShowRejectModal, rejectReason, setRejectReason, handleRejectedSaveBtn, heading } = rejectModalProps;

    return (
        <div>
            <Modal
                className="save-modal"
                open={showRejectModal}
                footer={null}
                // closeIcon={false}
                bodyProps={null}
                onCancel={() => setShowRejectModal(false)}
                closeIcon={<img alt="close" src={image.CLOSE_ICON} className="image_close" />
                }

            >
                <div className="denied-container">
                    <div className="modal-denied-image-header">
                        <div>
                            <div className="denied-text">{heading}</div>
                        </div>
                    </div>

                    <div className="text-area text-area-denied">
                        <textarea
                            className="textarea"
                            rows={10}
                            placeholder="Write a reject reason"
                            value={rejectReason}
                            onChange={(e) => setRejectReason(e.target.value)} />
                    </div>

                    <div className="reject-modal-button-container">
                        <button className="confirm-continue-btn-reject-modal" onClick={() => setShowRejectModal(false)}>Cancel</button>
                        <button className="btns save-changes-reject-modal" onClick={handleRejectedSaveBtn}>Submit</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default RejectModal;
