import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { MAX_TABLE_DATA_PER_PAGE } from "../../app/utils/apis/Constant";

export const SendNotificationAPI = createAsyncThunk(
  "sendNotification/sendNotificationAPI",
  async (data: { message: string }, { rejectWithValue }) => {
    try {
      const { message } = data;
      const response = await apiClient.post(
        `${ApiConstants.SEND_NOTIFICATION}`,
        { message }
      );
      return response.data;
    } catch (error: any) {
      console.log("error while sending notification: ", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const storeDeviceTokenAPI = createAsyncThunk(
  "storeDeviceToken/storeDeviceTokenAPI",
  async (data: { device_token: string }, { rejectWithValue }) => {
    try {
      const { device_token } = data;
      const response = await apiClient.post(
        `${ApiConstants.STORE_DEVICE_TOKEN}`,
        { device_token }
      );
      console.log('storeDeviceTokenAPI :>> ', response);
      return response.data;
    } catch (error: any) {
      console.log("error in store device token: ", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getSeenNotificationAPI = createAsyncThunk(
  "notification/getSeenNotificationAPI",
  async (
    { page, limit = MAX_TABLE_DATA_PER_PAGE }: { page: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(`${ApiConstants.SEEN_NOTIFCATIONS}/?page=${page}&limit=${limit}`)
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err);

    }
  }
);


export const getUnseenNotificationAPI = createAsyncThunk(
  "notification/getUnseenNotificationAPI",
  async (
    { page, limit = MAX_TABLE_DATA_PER_PAGE }: { page: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(`${ApiConstants.UNSEEN_NOTIFICATIONS}/?page=${page}&limit=${limit}`)
      return response.data.data;
    } catch (err: any) {
      console.log('Request canceled:', err.message);
      return rejectWithValue(err);

    }
  }
);


export const MakeNotificationSeenAPI = createAsyncThunk(
  "notification/MakeNotificationSeenAPI",
  async (
    data: {
      notification_ids: number[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { notification_ids } = data;
      const response = await apiClient.put(`${ApiConstants.MAKE_UNSEEN_NOTIFICATION_SEEN}`, { notification_ids });

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to update notification status");
    }
  }
);
