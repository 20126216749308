import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { image } from '../../../../app/utils/common/image';
import Input from '../../../atoms/Input/Input';
import Button from '../../../atoms/Button/Button';
import './AddManager.scss'
import { validateEmail, validatePhoneNumber } from '../../../../app/utils/common/validation';
import ErrorText from '../../../atoms/Error/error';
import { uploadFile } from '../../../../redux/API/ProjectAPI';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { createManagerAPI, updateManagerAPI } from '../../../../redux/API/ManagerAPI';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import { showErrorToast } from '../../../../app/utils/common/Toast';
import { setManagerLoading } from '../../../../redux/Slices/managerSlice';
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen';

const AddManager = () => {
    const navigate = useNavigate();
    const { currentManager, loading } = useAppSelector(state => state.manager)
    let { type } = useParams();
    const [name, setName] = useState<string>('');
    const [newProfilePhoto, setNewProfilePhoto] = useState<File>();
    const [profilePhoto, setProfilePhoto] = useState<string>();
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [isDataChange, setIsDataChange] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (type) {
            if (!["create", "edit"].includes(type)) {
                navigate("/login");
            }
        } else {
            navigate("/login");
        }
    }, [])
    useEffect(() => {
        if (currentManager) {
            if (handleDataChange()) {
                setIsDataChange(true);
            } else {
                setIsDataChange(false);
            }
        }
    }, [name, email, newProfilePhoto, phone])

    useEffect(() => {
        if (type === "edit") {
            if (currentManager) {
                setName(currentManager.name);
                setPhone(currentManager.phone_number);
                setEmail(currentManager.email ?? '');
                if (currentManager.profile_image) {
                    setProfilePhoto(currentManager.profile_image);
                }
            } else {
                navigate("/dashboard/manage-manager");
            }
        }
    }, [])
    const handleDataChange = () => {
        if (currentManager) {
            if (currentManager.name !== name || currentManager.email !== email || currentManager.phone_number !== phone || newProfilePhoto) return true;
            return false;
        }
        return false;
    }
    const handleSubmit = async () => {
        if (validatePhoneNumber(phone) && name.length > 0) {
            // api call
            let profile_image_url;
            if (newProfilePhoto) {
                try {
                    dispatch(setManagerLoading(true));
                    const imageResp = await uploadFile([newProfilePhoto], "profile");
                    profile_image_url = imageResp.data.imageUrls[0];
                } catch (err) {
                    dispatch(setManagerLoading(false));
                    showErrorToast("Error in uploading profile image.");
                    return;
                }
            }
            if (type === "create") {
                dispatch(createManagerAPI({
                    name,
                    email,
                    phone_number: phone,
                    profile_image: profile_image_url,
                    country_code: '+91'
                })).unwrap().then(() => {
                    navigate(-1);
                }).catch(err => {
                    console.log("manager creation error", err);
                })
            } else if (type === "edit" && isDataChange && currentManager) {
                let updatedData: any = {}
                if (newProfilePhoto) updatedData.profile_image = profile_image_url;
                if (currentManager?.name !== name) updatedData.name = name;
                if (currentManager?.email !== email) updatedData.email = email;
                if (currentManager?.phone_number !== phone) {
                    updatedData.phone_number = phone;
                    updatedData.country_code = "+91";
                }
                updatedData.manager_id = currentManager.manager_id;
                dispatch(updateManagerAPI(updatedData)).unwrap().then(() => {
                    navigate("/dashboard/manage-manager");
                }).catch(err => {
                    console.log("manager update error", err);
                });
            }
        } else {
            setShowError(true);
        }
    }
    return (
        <div className='create_project_container' >
            {
                <input type='file' accept='image/*' style={{ display: "none" }} ref={inputRef} onChange={(e) => {
                    if (e.target.files) {
                        setNewProfilePhoto(e.target.files[0])
                    }
                }} />
            }
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>{type === "edit" ? "Edit" : "Add"} Manager</div>
            </div>
            <div className='group-details-container broker-add-container'>
                <div className='group-container add-broker-container'>
                    <div >
                        <div className='profile-picture-container' >
                            <img src={newProfilePhoto ? URL.createObjectURL(newProfilePhoto) : profilePhoto ? getImageURL(profilePhoto) : image.DEFAULT_PROFILE_IMAGE} className='broker-profile' />
                            <img src={image.CAMERA_PHOTO} alt="camera icon" className="add-broker-profile" onClick={() => inputRef.current?.click()} />
                        </div>
                    </div>
                    <div className='broker-main-container'>
                        <div className='group-name-container'>
                            <div className='group-photo'>Name*</div>
                            <Input
                                placeholder="Enter Name"
                                type="text"
                                name="broker Name"
                                id="brokerName"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                                iconSrc={image.USER_NAME_ICON}
                                showIcon={true}
                            />
                            {showError && name.trim().length == 0 && <ErrorText message="Please provide manager's name" />}
                        </div>
                        {/* <div className='group-name-container'>
                            <div className='group-photo'>Email ID</div>
                            <Input
                                placeholder="Enter Email"
                                type="email"
                                name="broker Email"
                                id="brokerEmail"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                iconSrc={image.EmailLogo}
                                showIcon={true}
                            />
                            {showError && !validateEmail(email) && <ErrorText message="Please provide valid manager's email." />}
                        </div> */}
                        <div className='group-name-container'>
                            <div className='group-photo'>Phone Number*</div>
                            <Input
                                placeholder="Enter Phone Number"
                                type="number"
                                name="broker number"
                                id="brokerNumber"
                                value={phone}
                                onChange={(e) => { if (e.target.value.length <= 10) setPhone(e.target.value) }}
                                iconSrc={image.PhoneLogo}
                                showIcon={true}
                            />
                            {showError && !validatePhoneNumber(phone) && <ErrorText message="Please provide valid manager's phone number." />}
                        </div>
                    </div>
                    <div className='grp-submit-container' style={{ marginLeft: "0px" }}>
                        {
                            loading ? <LoaderScreen /> :
                                <Button buttonTitle={type === "create" ? "Submit" : "Update"} onClick={() => handleSubmit()} className='group_btn_submit' />
                        }
                    </div>
                </div>

            </div>



        </div>
    )
}

export default AddManager