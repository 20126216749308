import React, { useEffect, useRef, useState } from 'react'
import { image } from '../../../../app/utils/common/image'
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon'
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../../dto/confirm';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { deallocateCpAPI, getCpsForManagerAPI } from '../../../../redux/API/ManagerAPI';
import { clearManagersCpData, setManagersCPsPagination } from '../../../../redux/Slices/managerSlice';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import Loader from '../../../atoms/Loader/Loader';
import EmptyPage from '../../../atoms/EmptyPage/EmptyPage';
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen';

const ActionButtonContainer: React.FC<{ broker: any, manager: any }> = ({ broker, manager }) => {
    const [isMenuOpen, seIsMenuOpen] = useState<boolean>(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const options = [{
        name: "De allocate",
        onClick: () => {
            setRemoveModal(true);
        }
    }

    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to de allocate this broker?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { dispatch(deallocateCpAPI({ manager_id: manager.manager_id, cp_ids: [broker.cp_id] })); setRemoveModal(false); },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.ADD_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-147px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

const WorkedWithBrokers = () => {

    const scrollRef = useRef<HTMLDivElement>(null);
    const { ManagersCPs, ManagersCPsPagination, currentManager, loading: ManagerSliceLoading } = useAppSelector(state => state.manager);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        // return () => {
        //     dispatch(clearManagersCpData());
        // }
    }, [])
    useEffect(() => {
        if (currentManager && !loading) {
            setLoading(true);
            dispatch(getCpsForManagerAPI({ manager_id: currentManager.manager_id, current_page: ManagersCPsPagination.current_page })).unwrap()
                .then(() => {
                    setLoading(false);
                }).catch(() => { });
        }
    }, [ManagersCPsPagination.current_page])
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loading) {
                if (ManagersCPsPagination.current_page < ManagersCPsPagination.total_pages)
                    dispatch(setManagersCPsPagination({ ...ManagersCPsPagination, current_page: ManagersCPsPagination.current_page + 1 }))
            }
        }
    };


    return (
        <div className='group-manager-container worked-manager'>
            {ManagerSliceLoading && <LoaderScreen />}
            <div className='manager-header-flex'>
                <div className='group-manager-heading'>Worked with Brokers </div>
            </div>
            <div className='profile_data' onScroll={handleScroll} ref={scrollRef}>
                {
                    loading && ManagersCPsPagination.current_page === 1 ? <div className='center-content'><Loader isGray={true} /></div> :
                        ManagersCPs.cps.length === 0 ?
                            <div className='EmptyPageSite_container'>
                                <EmptyPage image={image.NO_MANAGER_ADDED_YET_IMAGE} text='No Broker Added yet.' />
                            </div> :
                            ManagersCPs?.cps.map(broker => (
                                <div className='profile'>
                                    <div className='profile_image_container'>
                                        <img src={broker.profile_image ? getImageURL(broker.profile_image) : image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                                    </div>
                                    <div className='data_container'>
                                        <div className='profile_data_container'>
                                            <div className='profile_name'>{broker.name}</div>
                                            <div className='profile_contact'>{broker.email}</div>

                                        </div>
                                        <div className='profile-end-flex'>
                                            <div className='profile_project_container'>
                                                <div className='project_text'>{broker.country_code} {broker.phone_number}</div>
                                            </div>
                                            <ActionButtonContainer broker={broker} manager={currentManager} />
                                        </div>
                                    </div>
                                </div>
                            ))
                }
            </div>
        </div>
    )
}

export default WorkedWithBrokers