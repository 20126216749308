// CustomRadioButton.tsx
import React from 'react';
import './Radio.scss';

interface CustomRadioButtonProps {

    name?: string;
    value?: string;
    checked?: boolean;
    radioClass?: string;  // optional custom color prop
    onClick: () => void;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
    value,
    checked,
    // default color
    radioClass,
    onClick,
}) => {
    return (
        <div className={`custom-radio-button ${radioClass}`} onClick={onClick}>
            <input
                type="radio"

                value={value}
                checked={checked}

            />
            <span className="custom-radio-button__circle"></span>
        </div>
    );
};

export default CustomRadioButton;
