import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PaginationType } from "../../dto/Pagination.dto";
import { AvailableManagersType, GroupType, GroupTypeManagers, GroupTypeManagersCPs } from "../../dto/Groups.dto";
import { GroupReducer } from "../Reducer/Group/GroupReducer";


export interface GroupSliceState {
    loading: boolean;
    groupLoading: boolean;
    groupCpsLoading: boolean;
    groups: GroupType[];
    managerUnderGroups: GroupTypeManagers[];
    CPsUnderManager: GroupTypeManagersCPs[],
    groupPagination: PaginationType;
    refreshTable: boolean;
    groupManager?: GroupType;
    availableManagers: AvailableManagersType[];
    availableManagersPagination: PaginationType;
}

const initialState: GroupSliceState = {
    loading: false,
    groupLoading: false,
    groupCpsLoading: false,
    groups: [],
    managerUnderGroups: [],
    availableManagers: [],
    CPsUnderManager: [],
    refreshTable: false,
    groupPagination: {
        total_data: 0,
        current_page: 1,
        page_size: 15,
        total_pages: 1,
    },
    availableManagersPagination: {
        total_data: 0,
        current_page: 1,
        page_size: 5,
        total_pages: 1,
    },

};

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroupPagination(state, action: PayloadAction<PaginationType>) {
            state.groups = [];
            state.groupPagination = action.payload;
        },
        setCurrentGroup: (state, action: PayloadAction<GroupType>) => {
            const { group_id, name, photo, managers } = action.payload;

            // Only update groupManager and managerUnderGroups 
            if (!state.groupManager || state.groupManager.group_id !== group_id) {
                state.groupManager = { group_id, name, photo };
            }

            if (managers && managers.length !== state.managerUnderGroups.length) {
                state.managerUnderGroups = managers.map(manager => ({
                    ...manager,
                    group_id: group_id,
                    photo: photo,
                }));
            }
            state.availableManagersPagination = {
                total_data: 0,
                current_page: 1,
                page_size: 5,
                total_pages: 1,
            }
        },
        setavailableManagerPagination(state, action: PayloadAction<PaginationType>) {
            state.availableManagersPagination = action.payload;
        },
        resetManagerUnderGroups: (state) => {
            state.managerUnderGroups = [];
            state.groups = []
        },
    },
    extraReducers: (builder) => {
        GroupReducer(builder)
    }

});

export const { setGroupPagination, setCurrentGroup, resetManagerUnderGroups, setavailableManagerPagination } = groupSlice.actions;
export default groupSlice.reducer;