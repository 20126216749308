import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PostType } from "../../../dto/post.dto";
import { PaginationType } from "../../../dto/Pagination.dto";
import Card from "../../atoms/Card/Card";
import Button from "../../atoms/Button/Button";
import { getImageURL } from "../../../app/utils/common/getImageUrl";
import "./PostManagement.scss";
import { image } from "../../../app/utils/common/image";
import LoaderScreen from "../../atoms/LoaderScreen/LoaderScreen";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/utils/hooks/reduxHook";
import {
  deletePostAPI,
  getPostRequestsAPI,
  VerifyPostAPI,
} from "../../../redux/API/PostAPI";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../app/utils/common/Toast";
import moment from "moment";
import {
  setIndividualPostLoading,
  setPostActiveTab,
  setPostLoading,
  setPostPagination,
} from "../../../redux/Slices/postSlice";
import Loader from "../../atoms/Loader/Loader";
import EmptyPage from "../../atoms/EmptyPage/EmptyPage";
import ConfirmModal from "../../atoms/confirmation-modal/confirmation-modal";
import { confirmModalProps } from "../../../dto/confirm";
import RejectModal from "../../atoms/RejectModal/RejectModal";
import { rejectModalProps } from "../../../dto/reject.dto";
import {
  checkPermission,
  permissionType,
} from "../../../app/utils/common/checkPermission";

interface PostListProps {
  activeTab: string;
}

const PostList: React.FC<PostListProps> = ({ activeTab }) => {
  const navigate = useNavigate();
  const { activePost, rejectedPosts, pendingPosts, pagination, pendingLoading, approvedLoading, rejectedLoading } =
    useAppSelector((state) => state.post);
  const [posts, setPosts] = useState<PostType[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const [AcceptPostModal, setAcceptPostModal] = useState<boolean>(false);
  const [RejectPostModal, setRejectPostModal] = useState<boolean>(false);
  const [DeletePostModal, setDeletePostModal] = useState<boolean>(false);
  const [postId, setPostId] = useState<number>(0);
  const [rejectReason, setRejectReason] = useState<string>("");

  useEffect(() => {
    console.log("🚀 ~ dispatch ~ Post_Status:", activeTab);
    // setLoading(true);
    dispatch(setPostActiveTab(activeTab));
    dispatch(setIndividualPostLoading({ status: activeTab, payload: true }));
    dispatch(getPostRequestsAPI({ current_page: 1, Post_Status: activeTab }))
      .unwrap()
      .then((data: any) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [activeTab]);


  useEffect(() => {
    // Fetch more posts on pagination change
    if (!loading && pagination.current_page > 1) {
      // setLoading(true);
      dispatch(setIndividualPostLoading({ status: activeTab, payload: true }));
      dispatch(
        getPostRequestsAPI({
          current_page: pagination.current_page,
          Post_Status: activeTab,
        })
      )

    }
  }, [pagination.current_page]);

  const acceptModalProps: confirmModalProps = {
    text: "Are you sure you want to Accept this post?",
    confirmModal: AcceptPostModal,
    setConfirmModal: setAcceptPostModal,
    buttons: ["Yes, Accept", "Cancel"],
    onPressPositive: () => {
      handleConfirmationAcceptClick();
    },
    onPressNegative: () => {
      setAcceptPostModal(false);
    },
    image: image.ADD_ICON,
  };

  const rejectModalProps: rejectModalProps = {
    heading: "Are you sure you want to Reject this post? ",
    showRejectModal: RejectPostModal,
    setShowRejectModal: setRejectPostModal,
    rejectReason: rejectReason,
    setRejectReason: setRejectReason,
    handleRejectedSaveBtn: () => {
      handleConfirmationRejectClick();
    },
  };

  const deleteModalProps: confirmModalProps = {
    text: "Are you sure you want to delete this post?",
    confirmModal: DeletePostModal,
    setConfirmModal: setDeletePostModal,
    buttons: ["Yes, Delete", "Cancel"],
    onPressPositive: () => {
      handleConfirmationDeleteClick();
    },
    onPressNegative: () => {
      setDeletePostModal(false);
    },
    image: image.ADD_ICON,
  };

  const dispatch = useAppDispatch();
  const handleAcceptClick = async (postId: number) => {
    try {
      setPostId(postId);
      setAcceptPostModal(true);
    } catch (error) {
      console.error(`Failed to approve post ${postId}:`, error);
    } finally {
    }
  };

  const handleRejectClick = async (postId: number) => {
    try {
      setPostId(postId);
      setRejectPostModal(true);
    } catch (error) {
      console.error(`Failed to reject post ${postId}:`, error);
    } finally {
    }
  };

  const handleDeleteClick = async (postId: number) => {
    console.log("postId:::: :>> ", postId);
    try {
      setPostId(postId);
      setDeletePostModal(true);
    } catch (error) {
      console.error(`Failed to delete post ${postId}:`, error);
    } finally {
    }
  };

  const handleConfirmationDeleteClick = async () => {
    setDeletePostModal(false);
    try {
      setTimeout(() => {
        setLoading(true);
      }, 500);
      dispatch(deletePostAPI({ postId, status: 3 }))
        .unwrap()
        .then(() => {
          showSuccessToast("Post deleted successfully");
          dispatch(
            getPostRequestsAPI({ current_page: 1, Post_Status: activeTab })
          )
            .unwrap()
            .finally(() => {
              setLoading(false);
            });
        });
    } catch (error) {
      console.error(`Failed to delete post ${postId}:`, error);
    } finally {
    }
  };
  const handleConfirmationAcceptClick = async () => {
    setAcceptPostModal(false);
    try {
      setTimeout(() => {
        setLoading(true);
      }, 500);
      dispatch(VerifyPostAPI({ postId, status: 2 }))
        .unwrap()
        .then(() => {
          showSuccessToast("Post approved successfully");
          dispatch(
            getPostRequestsAPI({ current_page: 1, Post_Status: activeTab })
          )
            .unwrap()
            .finally(() => {
              setLoading(false);
            });
        });
    } catch (error) {
      console.error(`Failed to approve post ${postId}:`, error);
    } finally {
    }
  };

  const handleConfirmationRejectClick = () => {
    try {
      if (rejectReason.length === 0) {
        showErrorToast("Please provide a reason for rejecting the post.");
      } else {
        setRejectPostModal(false);
        setTimeout(() => {
          setLoading(true);
        }, 500);
        dispatch(
          VerifyPostAPI({ postId, status: 3, reject_reason: rejectReason })
        )
          .unwrap()
          .then(() => {
            showErrorToast("Post rejected successfully");
            dispatch(
              getPostRequestsAPI({ current_page: 1, Post_Status: activeTab })
            )
              .unwrap()
              .finally(() => {
                setLoading(false);
              });
            setRejectReason("");
          })
          .catch(() => {
            // setLoading(false);
          });
      }
    } catch (error) {
      dispatch(setPostLoading(false));
      console.error(`Failed to reject post ${postId}:`, error);
    } finally {
      dispatch(setPostLoading(false));
    }
  };

  let debounceTimer: NodeJS.Timeout;
  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      // Trigger pagination if scrolled past 70%
      if (scrollTop + clientHeight >= scrollHeight * 0.7) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          if (pagination.current_page < pagination.total_pages) {
            dispatch(
              setPostPagination({
                ...pagination,
                current_page: pagination.current_page + 1,
              })
            );
          }
        }, 300); // Debounce scroll events to avoid multiple API calls
      }
    }
  };

  const renderBasedOnActiveTab = (posts: PostType[]) => {
    return (
      posts.length > 0 ? (
        posts.map((post) => (
          <Card
            key={post.post_id}
            customClass="project_card"
            primaryBorderColor={
              activeTab === "approved" ? "#5f9937" : "#F7681C"
            }
          >
            <div className="post-card">
              <div
                onClick={() =>
                  navigate(`/dashboard/post-requests/${post.post_id}`)
                }
                style={{ display: "contents" }}
              >
                <div className="profile_data">
                  <div className="profile">
                    <div className="profile_image_container">
                      <img
                        src={
                          post.profile_image
                            ? getImageURL(post?.profile_image)
                            : image.DEFAULT_PROFILE_IMAGE
                        }
                        className="profile_image"
                        alt="Profile"
                      />
                    </div>
                    <div className="data_container">
                      <div className="profile_data_container">
                        <div className="profile_name">{post.name}</div>
                        <div className="profile_contact">
                          {post.phone_number}
                        </div>
                      </div>
                      <div className="profile_project_container">
                        <div className="project_text">
                          {" "}
                          {post?.created_at
                            ? moment(post.created_at).fromNow()
                            : "Unknown time"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="post-thumbnail-container">
                  {/* {post.images.length > 0 && */}
                  <img
                    className="project-card-thumbnail"
                    src={
                      post?.media?.length > 0
                        ? getImageURL(post.media[0].type === 2 && post.media[0].thumbnail ? post.media[0].thumbnail : post.media[0].url)
                        : "image.BackgroundImage"
                    }
                    alt="Post Thumbnail"
                    onError={(e: any) =>
                      (e.target.src = image.DEFAULT_BACKGROUND_IMAGE)
                    }
                  />
                  {/* } */}
                </div>

                <div className="project-card-description">
                  {post.description}
                </div>
              </div>

              {activeTab !== "approved" && activeTab !== "rejected" && (
                <div className="reject-accept-btn">
                  <Button
                    buttonTitle={"Reject"}
                    onClick={() => handleRejectClick(post.post_id)}
                    className="RejectButton"
                  />
                  <Button
                    buttonTitle={"Accept"}
                    onClick={() => handleAcceptClick(post.post_id)}
                    className="RejectButton AcceptButton"
                  />
                </div>
              )}
              {activeTab === "approved" && (
                <Button
                  buttonTitle={"Delete"}
                  onClick={() => handleDeleteClick(post.post_id)}
                  className="RejectButton"
                />
              )}
            </div>
          </Card>
        ))
      ) : (
        <div className="EmptyPageSite_container_post">
          <EmptyPage
            image={image.NO_POST_ADDED_YET_IMAGE}
            text="No Request Added yet."
          />
        </div>
      )
    )
  }

  const getLoading = (activeTab: string) => {
    if (activeTab === "pending") {
      return pendingLoading;
    } else if (activeTab === "approved") {
      return approvedLoading;
    } else {
      return rejectedLoading;
    }
  }

  return (
    <>
      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className={
          activeTab === "approved"
            ? "active_projects_container"
            : "completed_project_container"
        }
      >

        {getLoading(activeTab) && pagination.current_page === 1 ? (
          <div style={{ height: "100%", width: "100%" }} className="center_div">
            <Loader />{" "}
          </div>
        ) : <>
          {
            activeTab === "pending" ? renderBasedOnActiveTab(pendingPosts)
              : activeTab === "approved" ? renderBasedOnActiveTab(activePost)
                : renderBasedOnActiveTab(rejectedPosts)
          }
        </>}



        {AcceptPostModal && <ConfirmModal confirmModalProps={acceptModalProps} />}
        {RejectPostModal && <RejectModal rejectModalProps={rejectModalProps} />}
        {DeletePostModal && <ConfirmModal confirmModalProps={deleteModalProps} />}
      </div>
      {getLoading(activeTab) && pagination.current_page > 1 && (
        <div className="loading_more_data">Loading more data...</div>
      )}
    </>
  );
};

export default PostList;
