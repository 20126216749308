import { useState } from 'react'
import "./Managers.scss"
import { image } from '../../../app/utils/common/image'
import { useNavigate } from 'react-router-dom'
import ManagerDataTable from '../../molecules/ManageManagerComponent/ManagerDataTable'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook'
import { setManagerFilters } from '../../../redux/Slices/managerSlice'
import { checkPermission, permissionType } from '../../../app/utils/common/checkPermission'
import FilterModal from '../../atoms/FilterModal/FilterModal'
import { getAllManagers } from '../../../redux/API/ManagerAPI'


const Managers = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { searchText } = useAppSelector(state => state.search);
    const { managerFilter } = useAppSelector(state => state.manager);
    const filterModalOptions = [
        {
            key: "top_performer",
            value: "Top Performer"
        },
        {
            key: "bottom_performer",
            value: "Bottom Performer"
        }
    ]
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const fetchFilterData = (sortBy: string) => {
        dispatch(setManagerFilters(sortBy))
        dispatch(getAllManagers({ page: 1, sortBy, searchText }));
    }
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    All Managers
                </div>

                {checkPermission(permissionType.MANAGER_MODULE, "create") &&
                    <div className='button_container'>
                        <div className='btn' onClick={() => setFilterModal(!filterModal)}>
                            {(managerFilter !== "" || null || undefined) && <div className='filter_count'><span>1</span></div>}
                            <img className='btn_icon' src={image.FILTER_ICON} />
                            <div>
                                Filter
                            </div>
                        </div>
                        <div className='btn' onClick={() => navigate("manager/create")}>
                            <img className='btn_icon' src={image.ADD_ICON} />
                            <div>
                                Add
                            </div>
                        </div>

                    </div>
                }
            </div>

            <div className='manager_data_table'>
                <ManagerDataTable />
            </div>
            {filterModal && (
                <div className='filter_modal_wrapper'>
                    <FilterModal
                        options={filterModalOptions}
                        handleSubmit={(data) => {
                            console.log(data)
                            fetchFilterData(data.key);
                            setFilterModal(false);
                        }}
                        handleRemoveFilter={() => {
                            dispatch(getAllManagers({ page: 1, limit: 15 }))
                            dispatch(setManagerFilters(''));
                            setFilterModal(false);
                        }}
                        filterType='manager'
                        onOutSideClick={() => setFilterModal(false)} 
                    />
                </div>
            )}
        </div >
    )
}

export default Managers