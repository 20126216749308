import React, { useEffect } from 'react'
import ProjectsTabs from '../SiteManagement/ProjectsTab'
import TopPerformerList from './TopPerformerList'
import ProjectList from '../SiteManagement/ProjectList'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook'
import { getAllActiveProjects } from '../../../redux/API/ProjectAPI'
import { setManagerPagination } from '../../../redux/Slices/managerSlice'
import Loader from '../../atoms/Loader/Loader'
import { getRegistrationRequestsAPI } from '../../../redux/API/RegistrationRequestAPI'

const LowerSection = () => {
    const dispatch = useAppDispatch();
    const { loading, pagination } = useAppSelector(state => state.project);
    useEffect(() => {
        // dispatch(getAllActiveProjects('Active'))
        return () => {
            dispatch(setManagerPagination({
                current_page: 1,
                total_data: 10,
                total_pages: 1,
                page_size: 7
            }))
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(getRegistrationRequestsAPI({ page: 1, registration_req_Status: "pending", }));
        }, [dispatch])
        
    return (

        <div className='lower_section'>
            <div className='ongoing_sites_container'>
                <div className='ongoing_header'>
                    All Sites
                </div>
                <div>
                    {/* <ProjecstTabs /> */}
                    <ProjectList isActive={true} />

                </div>
            </div>
            <div className='top_performer_list_container'>
                <div className='ongoing_header'>
                    Top Managers and Brokers
                </div>
                <div>
                    <TopPerformerList />
                </div>
            </div>
        </div>

    )
}

export default LowerSection