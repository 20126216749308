import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import axios, { CancelTokenSource } from "axios";
import { showErrorToast } from "../../app/utils/common/Toast";


let cancelToken: CancelTokenSource | undefined;
export const getSiteVisitesAPI = createAsyncThunk(
  "visitManagement/getVisitManagementAPI",
  async (
    {
      page,
      total_page = 10,
      visit_type,
    }: { page: number; total_page?: number; visit_type?: string },
    { rejectWithValue }
  ) => {
    try {
      if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      cancelToken = axios.CancelToken.source();
      const response = await apiClient.get(
        `${ApiConstants.GET_SITE_VISITS}/${visit_type}/?page=${page}&limit=${total_page}`,
        {
          cancelToken: cancelToken?.token
        }
      );

      return {
        scheduled_Data:
          visit_type === "scheduled"
            ? response.data.data.scheduled_sites_data
            : [],
        visited_Data:
          visit_type === "visited" ? response.data.data.visited_sites_data : [],
        pagination: response.data.data.pagination,
      };
    } catch (error: any) {
      if (axios.isCancel(error)) {
        // console.log('Request canceled:', error.message);
        return;
      } else {

        return rejectWithValue(error.response?.data?.error || "Failed to get projects");
      }
    }
  }
);

export const completeVisitAPI = createAsyncThunk(
  "visitManagement/completeVisitAPI",
  async (
    {
      visit_id,
      code
    }: {
      visit_id: number,
      code: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.DONE_VISIT.replace(":visitId", visit_id.toString())}`, {
        code
      }
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.error || "Failed get all brokers");
    }
  }
);


export const getSiteVisitDetailsAPI = createAsyncThunk(
  "visitManagement/getVisitManagementDetailsAPI",
  async (
    {
      page,
      total_page = 10,
      visit_type,
      project_id,
    }: {
      page: number;
      total_page?: number;
      visit_type?: string;
      project_id: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_SITE_VISIT_DETAILS}/${project_id}/${visit_type}/info?page=${page}&limit=${total_page}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all brokers");
    }
  }
);

//marks as complete api
export const markAsCompleteVisitAPI = createAsyncThunk(
  "visitManagement/markAsCompleteVisitAPI",
  async ({ visit_id }: { visit_id: number }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`${ApiConstants.MARK_AS_COMPLETE_VISIT.replace(":visitId", visit_id.toString())}`);
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.error || "Failed to mark as completed");
    }
  }
);


export const updateSaleStatusAPI = createAsyncThunk(
    "visitManagement/updateSaleStatusAPI",
    async (
        { visit_id, status, reject_reason }: { visit_id: number; status: number; reject_reason?: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await apiClient.post(
                `${ApiConstants.UPDATE_SALE_STATUS.replace(":visitId", visit_id.toString())}`,
                { status, reject_reason }
            );
            return response.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data?.error || "Failed to update site visit status");
        }
    }
);