import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import NotificationIconPng from './../../../../assets/Icons/notificationIcon.png'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { image } from '../../../app/utils/common/image';
import NotificationScreen from '../../organisms/Dashboard/Notification/NotificationScreen';
import { useNavigate } from 'react-router-dom';
import NotificationPopup from './NotificationPopup';

const NotificationContainer = styled.div`
position: relative;
height:50px;
width:50px;
justify-content: center;
border-radius:50%;
background-color: white;
 `;
const NotificationIconContainer = styled.div`
height:50px;
width:50px;
display:flex;
align-items: center;
justify-content: center;
box-shadow: 0px 10px 20px 0px #110F0E1A;
border-radius:50%;
cursor: pointer;

`;
const NotificationIcon = styled.img`
height:30px;
width:30px;
`;
const NotificationCount = styled.p`
    position: absolute;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-top: -13px;
    margin-right: -48px;
    background: var(--newThemeColor);
`
const NotificationText = styled.p`

font-family: "Rubik", sans-serif;
font-size: 14px;
font-weight: 500;
color:white;
text-align: center;
margin:0px;
`;

const NotificationTextContainer = styled.div`
width: 22px;
height: 23px;
background: #A720B9;
border-radius:50%;
text-align: center;
display:flex;
align-items: center;
justify-content: center;
position:absolute;
top:2px;
right:-6px;

`;

const Notification: React.FC = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const { pagination, unseenNotificationCount } = useAppSelector((state) => state.notification)
    return (
        <NotificationContainer onClick={() => setMenuOpen(true)} >
            {menuOpen && <NotificationPopup setMenuOpen={setMenuOpen} menuOpen={menuOpen} />}
            <NotificationIconContainer onClick={() => setIsOpen(pre => !pre)}>
                <NotificationIcon src={image.NOTIFICATION_ICON} />
                {pagination.total_data > 0 && <NotificationCount> <span style={{ fontSize: "11px" }}>{unseenNotificationCount} </span>  </NotificationCount>}
            </NotificationIconContainer>
        </NotificationContainer>
    )
}

export default Notification