import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import "./ProjectManagersTable.scss"
import { image } from '../../../../app/utils/common/image';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { PaginationType } from '../../../../dto/Pagination.dto';
import { MAX_TABLE_DATA_PER_PAGE } from '../../../../app/utils/apis/Constant';
import { getProjectManagerAPI } from '../../../../redux/API/ProjectAPI';
import { ManagerType } from '../../../../dto/Manager.dto';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';



const ProjectManagersTable: React.FC = () => {

    const { SingleProject, SingleProjectManagers } = useAppSelector(state => state.project);
    const [pagination, setPagination] = useState<PaginationType>({
        current_page: 1,
        total_data: 10,
        total_pages: 1,
        page_size: MAX_TABLE_DATA_PER_PAGE
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const columns: TableColumnsType<ManagerType> = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            render: (text, record) => (
                <img
                    src={record.profile_image ? getImageURL(record.profile_image) : image.DEFAULT_PROFILE_IMAGE}
                    alt="Profile"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
        },
        {
            title: 'Brokers Allocated',
            dataIndex: 'cps_allocated',
        },

        {
            title: 'Sites handling',
            dataIndex: 'total_projects',
        },

    ];

    useEffect(() => {
        if (SingleProject && !loading) {
            setLoading(true);
            dispatch(getProjectManagerAPI({ project_id: SingleProject.project_id, current_page: pagination.current_page })).unwrap()
                .then((data: {
                    group_managers_data: any,
                    pagination: PaginationType
                }) => {
                    if (data.pagination.current_page === pagination.current_page) {
                        setPagination(data.pagination);
                    }
                }).catch((error) => { console.log("error in fetching managers for project", error) }).finally(() => { setLoading(false) })
        }
    }, [pagination.current_page]);

    const handlePageChange = (pageNumber: number) => {
        setPagination({ ...pagination, current_page: pageNumber });
    };
    return (
        <div className='manager_dataTable' style={{ marginTop: "0" }}>
            <div>
                <TableComponent columns={columns}
                    dataSource={SingleProjectManagers}
                    loading={loading}
                    pageNumbers={Array.from({ length: pagination?.total_pages }, (_, index) => index + 1)}
                    handlePageLink={handlePageChange}
                    isPagination={pagination?.total_pages > 1}
                    totalProfile={pagination?.total_data}
                    prevPage={() => handlePageChange(pagination?.current_page - 1)}
                    nextPage={() => handlePageChange(pagination?.current_page)}
                    activePage={pagination?.current_page}
                    currentTotal={Math.min(pagination?.current_page * pagination?.page_size, (SingleProjectManagers ?? []).length)} />
            </div>
        </div>
    )
}

export default ProjectManagersTable
