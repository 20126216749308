import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { apiClient } from "../../app/helper/axiosHelper";

export const getAllRoleModulesAPI = createAsyncThunk(
    "role/getAllRoleModulesAPI",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(`${ApiConstants.GET_ROLE_MODULES}`);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error);
        }
    }
);

export const getAllRoles = createAsyncThunk(
    "role/getAllRoles",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(`${ApiConstants.GET_ALL_ROLES}`);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error);
        }
    }
);

export const updateRoleAPI = createAsyncThunk(
    "role/updateRoleAPI",
    async (data: { role_id: number, payload: any }, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${ApiConstants.UPDATE_ROLE_API.replace(":roleId", data.role_id.toString())}`, data.payload);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error);
        }
    }
);
export const deleteRoleAPI = createAsyncThunk(
    "role/deleteRoleAPI",
    async (data: { role_id: number }, { rejectWithValue }) => {
        try {
            const response = await apiClient.delete(`${ApiConstants.DELETE_ROLE_API.replace(":roleId", data.role_id.toString())}`);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error);
        }
    }
);
export const createRoleAPI = createAsyncThunk(
    "role/createRoleAPI",
    async (data: { payload: any }, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${ApiConstants.CREATE_ROLE_API}`, data.payload);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error);
        }
    }
);
