import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./Slices/authSlice";
import projectSlice from "./Slices/projectSlice";
import permissionSlice from "./Slices/permissionSlice";
import managerSlice from "./Slices/managerSlice";
// import projectSlice from "./Slices/projectSlice";
import brokerSlice from "./Slices/brokerSlice";
import groupSlice from "./Slices/groupSlice";
import postSlice from "./Slices/postSlice";
import visitManagementSlice from "./Slices/visitManagementSlice";
import registrationRequestSlice from "./Slices/registrationRequestSlice";
import allUsersSlice from "./Slices/allUsersSlice";
import notificationSlice from "./Slices/notificationSlice";
import roleSlice from "./Slices/roleSlice";
import rankSlice from "./Slices/rankSlice";
import searchSlice from "./Slices/searchSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    project: projectSlice,
    permission: permissionSlice,
    manager: managerSlice,
    broker: brokerSlice,
    group: groupSlice,
    post: postSlice,
    vistManagement: visitManagementSlice,
    registrationRequest: registrationRequestSlice,
    allUsers: allUsersSlice,
    notification: notificationSlice,
    role: roleSlice,
    rank: rankSlice,
    search: searchSlice
    // project: projectSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
