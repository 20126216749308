export const imageURL = process.env.REACT_APP_ENV === "PROD" ? "https://d11wo1jwm9osir.cloudfront.net/" : "https://d11wo1jwm9osir.cloudfront.net/";
// dev : https://d2oa61rngsgpp7.cloudfront.net/ 
// prod : https://d11wo1jwm9osir.cloudfront.net/
export const getImageURL = (url: string) => {
    if (!url) return;
    if (url.startsWith("https://")) {
        return url; // Return the original URL if it starts with https
    }
    return `${imageURL}${url}`;
}
