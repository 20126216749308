import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PostType } from "../../dto/post.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { PostReducer } from "../Reducer/Post/PostReducer";

export interface PostSliceState {
  loading: boolean;
  pendingLoading: boolean;
  approvedLoading: boolean;
  rejectedLoading: boolean;
  verifyLoading: boolean;
  posts: PostType[];
  pendingPosts: PostType[];
  activePost: PostType[];
  rejectedPosts: PostType[];
  pagination: PaginationType;
  postActiveTab: string;
}

const initialState: PostSliceState = {
  loading: false,
  pendingLoading: false,
  approvedLoading: false,
  rejectedLoading: false,
  verifyLoading: false,
  posts: [],
  pendingPosts: [],
  activePost: [],
  rejectedPosts: [],
  postActiveTab: "pending",
  pagination: {
    total_data: 0,
    current_page: 1,
    page_size: 7,
    total_pages: 1,
  },
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPostPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },

    setPostLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPostActiveTab(state, action: PayloadAction<any>) {
      state.postActiveTab = action.payload;
    },
    setPostData(state, action: PayloadAction<{
      status: string,
      payload: PostType[]
    }>) {
      if (action.payload.status === "pending")
        state.pendingPosts = []
      if (action.payload.status === "approved")
        state.activePost = []
      if (action.payload.status === "rejected")
        state.rejectedPosts = []
    },
    setIndividualPostLoading(state, action: PayloadAction<{
      status: string,
      payload: boolean
    }>) {
      if (action.payload.status === "pending")
        state.pendingLoading = action.payload.payload;
      if (action.payload.status === "approved")
        state.approvedLoading = action.payload.payload;
      if (action.payload.status === "rejected")
        state.rejectedLoading = action.payload.payload;
    }
  },

  extraReducers: (builder) => {
    PostReducer(builder);
  },
});

export const { setPostPagination, setPostLoading, setPostActiveTab, setPostData, setIndividualPostLoading } =
  postSlice.actions;

export default postSlice.reducer;
