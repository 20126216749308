import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegristrationRequestType } from "../../dto/RegistrationRequest.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { RegistrationRequestReducer } from "../Reducer/RegistrationRequest/RegistrationRequestReducer";
import { RoleModuleType, RoleType } from "../../dto/Role.dto";
import { RoleReducer } from "../Reducer/Role/RoleReducer";

export interface RoleSliceStateTypes {
  loading: boolean;
  allModules: RoleModuleType[];
  allRoles: RoleType[];
  isModulesUpdated: boolean;
  isRoleUpdated: boolean;
}

const initialState: RoleSliceStateTypes = {
  loading: false,
  allModules: [],
  allRoles: [],
  isModulesUpdated: true,
  isRoleUpdated: true,
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // RegistrationRequestReducer(builder);
    RoleReducer(builder);
  },
});
export const { } = roleSlice.actions;

export default roleSlice.reducer;
