export const image = {
  LOGO: require("../../../assets/images/logo.png"),
  Add_IMAGE_PLACEHOLDER: require("../../../assets/images/addImage.svg"),
  CLOSE_ICON: require("../../../assets/Icons/cancelIcon.png"),
  BUILDING_IMAGE: require("../../../assets/images/building.jpg"),
  // Video: require("../../../assets/images/Video.mp4"),
  LoginPageImage: require("../../../assets/images/LoginPageImage.png"),
  LoginPageLogo: require("../../../assets/images/LoginPanelLogo.png"),
  EmailLogo: require("../../../assets/images/EmailLogo.png"),
  PasswordLogo: require("../../../assets/images/Password.png"),
  PhoneLogo: require("../../../assets/images/phoneIcon.png"),
  showPasswordIcon: require("../../../assets/images/showPasswordIcon.png"),
  BackgroundImage: require("../../../assets/images/background_compress.png"),
  PROFILE_DOWN_ICON: require("../../../assets/Icons/ProfileNavbar/profile_downIcon.png"),
  NOTIFICATION_ICON: require("../../../assets/Icons/ProfileNavbar/notification.png"),
  DEFAULT_PROFILE_IMAGE: require("../../../assets/images/DefaultProfilePic.png"),
  SITE_WHITE_ICON: require("../../../assets/Icons/Dashboard_stats/site_ongoing.png"),
  SITE_COMPLETED_ICON: require("../../../assets/Icons/Dashboard_stats/sites_completed.png"),
  MANAGERS_WORLDWIDE_ICON: require("../../../assets/Icons/Dashboard_stats/manager_world_wide.png"),
  BROKERS_WORLDWIDE_ICON: require("../../../assets/Icons/Dashboard_stats/broker_worldwide.png"),
  CAMERA_PHOTO: require("../../../assets/images/CameraImage.png"),
  PROFILE_PHOTO: require("../../../assets/images/ProfilePicture.png"),
  NAME_LOGO: require("../../../assets/images/NameLogo.png"),
  BACK_ICON: require("../../../assets/images/BackIcon.png"),
  EDIT_ICON: require("../../../assets/images/EditIcon.png"),
  PROFILE_ICON: require("../../../assets/Icons/ProfileNavbar/profile.png"),
  LOGOUT_ICON: require("../../../assets/Icons/ProfileNavbar/logout.png"),
  LOGOUT_SCREEN_ICON: require("../../../assets/images/LogoutIcon.png"),
  FILTER_ICON: require("../../../assets/images/FilterIcon.png"),
  ADD_ICON: require("../../../assets/images/AddIcon.png"),
  ADD_IMAGE_PLACEHOLDER: require("../../../assets/images/AddImage.png"),
  PDF_PLACEHOLDER_IMAGE: require("../../../assets/images/pdfImage.png"),
  CLOSE_ICON_GRAY: require("../../../assets/Icons/closeIcon.png"),
  ADD_PDF_PLACEHOLDER: require("../../../assets/images/Addpdf.png"),
  ADD_VIDEO_PLACEHOLDER: require("../../../assets/images/addVideoImage.png"),
  PREV_LIGHT_ICON: require("../../../assets/images/ARB Ventures/prev-icon.png"),
  PREV_ICON: require("../../../assets/images/ARB Ventures/prev-light-icon.png"),
  EMAIL_WHITE_ICON: require("../../../assets/images/EmailWhiteIcon.png"),
  CALL_WHITE_ICON: require("../../../assets/images/CallWhiteIcon.png"),
  ADD_INCENTIVE_ICON: require("../../../assets/Icons/Sitemanagement/IncenetivePlus.png"),
  VIDEO_PLAY_ICON: require("../../../assets/Icons/Sitemanagement/videoPlayIcon.png"),
  SAMPLE_VIDEO: require("../../../assets/Video/testVodeo.mov"),
  ADD_PLUS_WHITE_ICON: require("../../../assets/Icons/Sitemanagement/AddWhiteIcon.png"),
  CONTACT_US_IMAGE: require("../../../assets/images/contactUs.png"),
  USER_NAME_ICON: require("../../../assets/Icons/usernameIcon.png"),
  REMOVE_USER_ICON: require("../../../assets/Icons/Modal_Icons/deleteUser.png"),
  REMOVE_GROUP_ICON: require("../../../assets/Icons/Modal_Icons/deleteUser.png"),
  ARB_WHITE_LOGO_IMAGE: require("../../../assets/images/ARB_White.png"),
  NO_MANAGER_ADDED_YET_IMAGE: require("../../../assets/images/NoManagerYet.png"),
  NO_SITE_VISITED_YET_IMAGE: require("../../../assets/images/Visit Management Empty.png"),
  DEFAULT_BACKGROUND_IMAGE: require("../../../assets/images/Default_Background_Image.png"),
  NOTIFICATION_ALARM: require("../../../assets/images/NotificationAlarm.png"),
  EXCEL_ICON: require("../../../assets/images/excel_icon.png"),
  NO_POST_ADDED_YET_IMAGE: require("../../../assets/images/Manage Post Empty.png"),
  NO_REGISTRATION_REQUEST_ADDED_YET_IMAGE: require("../../../assets/images/Regi Request Empty.png"),

};
