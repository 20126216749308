import React from 'react'
import { useNavigate } from 'react-router-dom';
import { image } from '../../../../app/utils/common/image';
import { Tabs } from 'antd';
import PostRequestDetailsCard from '../../../molecules/PostManagement/PostRequestDetailsCard';
import { useAppDispatch } from '../../../../app/utils/hooks/reduxHook';
import { setPostPagination } from '../../../../redux/Slices/postSlice';

const PostRequestDetailScreen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return (
        <div className='single_site'>
            <div className='sitename-header'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    dispatch(setPostPagination({ current_page: 1, total_pages: 1, total_data: 10, page_size: 10 }));
                    navigate(-1);
                }} />
                <div className='header_text'><span style={{ color: "#5f9937", marginRight: "20px" }}>Post Requests </span> Post Request Details </div>

            </div>
            <div>
                <PostRequestDetailsCard />
            </div>
        </div>
    )
}

export default PostRequestDetailScreen
