import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'antd';
import "./SingleSiteDetails.scss"
import { image } from '../../../../app/utils/common/image';
import Modal from '../../../atoms/Modal/Modal';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import { updateSingleSiteAPI } from '../../../../redux/API/ProjectAPI';

interface SingleSiteDataPropsType {
  setRefreshData: (data: boolean) => void;
  setLoading: (data: boolean) => void;
}

const SingleSiteDetails: React.FC<SingleSiteDataPropsType> = ({ setRefreshData, setLoading }) => {
  const [addIncentiveModal, setAddIncentiveModal] = useState(false);
  const { SingleProject } = useAppSelector(state => state.project);
  const [incentiveValue, setIncentiveValue] = useState<string | undefined>();
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<{
    url: string,
    id: number,
    type: number,
    thumbnail?: string,

  }>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setIncentiveValue(SingleProject?.incentive)
  }, [SingleProject?.incentive])

  function extractActualFilename(path: string): string {
    const fullFilename = path.substring(path.lastIndexOf('/') + 1); // Get the part after the last '/'
    const actualFilename = fullFilename.substring(fullFilename.lastIndexOf('-') + 1); // Get the part after the last '-'
    return actualFilename;
  }

  function formatNumberToText(number: number): string {
    const formatValue = (value: number): string => {
      return value.toFixed(5).replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''); // Remove trailing zeros, keep up to 2 decimals
    };

    if (number >= 10000000) {
      const croreValue = parseFloat(formatValue(number / 10000000));
      return `${croreValue} Cr`;
    } else if (number >= 100000) {
      const lakhValue = parseFloat(formatValue(number / 100000));
      return `${lakhValue} L`;
    } else if (number >= 1000) {
      const thousandValue = parseFloat(formatValue(number / 1000));
      return `${thousandValue} K`;
    }
    return number.toString();
  }


  const handleUpdateIncentive = () => {
    if (SingleProject) {
      setLoading(true);
      dispatch(updateSingleSiteAPI({ project_id: SingleProject?.project_id, incentive: incentiveValue })).unwrap().then(() => {
        setRefreshData(true);
      }).catch(() => { }).finally(() => {
        setLoading(false);
      });
    }
  }
  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };
  return (
    <div className='single_site_details'>
      <div className='left_section'>
        <div className='carosaul_container'>
          <Carousel autoplay autoplaySpeed={3000} >
            {SingleProject?.media_urls.site_photos.map(media => (
              <div>
                <div className='carosaul_image_container'>
                  <img src={getImageURL(media.url)} className='carosaul_image' />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className='pdf-container'>
          {SingleProject?.media_urls.site_PDFs.map(pdf => (
            <div className='pdf-div'>
              <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf-img' />
              <div className='pdf_name'>{extractActualFilename(pdf.url)}</div>
            </div>
          ))}

        </div>
      </div>
      <div className='right_section'>
        <div className='upper_section'>
          <div className='project_data'>
            <div className='title'>{SingleProject?.name}</div>
            <div className='address'>{SingleProject?.address}</div>
            <div className='project_desc'>
              {SingleProject?.description}
            </div>
          </div>
          <div className='project_stats'>
            <div className='price'>
              <div className='price_text'>₹{formatNumberToText(parseInt(SingleProject?.unit_price ?? "0"))}</div>
              <div className='sub_text'>Unit Price</div>
            </div>
            <div className='incentive_container' onClick={(e) => setAddIncentiveModal(true)}>
              {SingleProject?.incentive ?
                <>
                  <div >
                    <span className='points'>{formatNumberToText(parseInt(SingleProject.incentive ?? "0"))}</span>
                    <span className='points_text'>&nbsp;points</span>
                  </div>
                  <div className='sub_text'>Incentive</div>
                  <img className='edit_icon' src={image.EDIT_ICON} />
                </>
                :
                <>
                  <img src={image.ADD_INCENTIVE_ICON} className='addIcon' />
                  <div className='sub_text'>Add Incentive</div>
                </>
              }
            </div>
          </div>
        </div>
        <div className='lower_section'>
          <Carousel autoplay autoplaySpeed={3000} >
            {SingleProject?.media_urls.site_flyers.map(media => (
              <div>
                <div className='flyer_image_container'>
                  <img src={getImageURL(media.type === 2 && media.thumbnail ? media.thumbnail : media.url)} className='flyer_image' style={{ height: "300px", width: "300px" }} />
                  {media.type === 2 &&
                    <img className='video_icon' src={image.ADD_VIDEO_PLACEHOLDER} onClick={() => {
                      setSelectedVideo(media);
                      setShowVideoModal(true);
                    }} />
                  }
                </div>
              </div>

            ))
            }
          </Carousel>
          {/* <div className='site_flyer'>
            <img src={SingleProject?.media_urls.site_flyers[0] ? getImageURL(SingleProject?.media_urls.site_flyers[0].url) : image.BUILDING_IMAGE} />
          </div> */}
        </div>
      </div>

      {
        addIncentiveModal &&
        <Modal>
          <div className='add_incentive_modal'>
            <div className='center_div incentive_content'>
              <div className='text'>Add Incentive Points</div>
              <div className='input_container'>
                <input type="string" value={incentiveValue} onChange={(e) => {
                  setIncentiveValue(e.target.value);
                }} />
              </div>
              <div className='btn_container'>
                <button className='cancel_btn' onClick={(e) => setAddIncentiveModal(false)}>
                  Cancel
                </button>
                <button className='submit_btn' onClick={() => handleUpdateIncentive()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
      {
        showVideoModal &&
        <Modal isClose={true} onClose={() => setShowVideoModal(false)}>
          <div className='single_video_container'>
            <video
              ref={videoRef}
              className='video'
              controls={true}
              autoPlay={false}
              onClick={handleVideoClick}
              width="500"
            >
              <source src={getImageURL(selectedVideo?.url ?? "")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal>
      }
    </div>
  )
}

export default SingleSiteDetails
