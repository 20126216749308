import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { UpdateProjectPayloadType } from "../../dto/Projects.dto";
import { MAX_TABLE_DATA_PER_PAGE } from "../../app/utils/apis/Constant";
import axios, { CancelTokenSource } from "axios";


let cancelToken: CancelTokenSource | undefined;


export const uploadFile = async (files: File[], folder_name: string) => {
  let formData = new FormData();
  formData.append("folder_name", folder_name);
  files.forEach((file) => {
    formData.append("files", file);
  });
  const response = await apiClient.post(ApiConstants.UPLOAD_FILE, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
export const deleteMedia = async (data: { image_path: string[] }) => {
  const response = await apiClient.post(ApiConstants.DELETE_FILE, data);
  return response.data;
};

export const getAllActiveProjects = createAsyncThunk(
  "project/getAllActiveProjects",
  async (data: { status: string, current_page: number, limit?: number, searchText?: string }, { rejectWithValue }) => {
    try {
      if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      cancelToken = axios.CancelToken.source();

      const response = await apiClient.get(
        `${ApiConstants.GETALL_PROJECT}?searchText=${data.searchText ?? ""}&status=${data.status}&page=${data.current_page}&limit=${data.limit ?? MAX_TABLE_DATA_PER_PAGE}`

        , {
          cancelToken: cancelToken.token
        });
      return response.data.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      } else {

        return rejectWithValue(error.response?.data || "Failed to get projects");
      }
    }
  }
);

export const getSingleProjectAPI = createAsyncThunk(
  "project/getSingleProjectAPI",
  async (data: { project_id: string }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        ApiConstants.GET_SINGLE_PROJECT.replace(
          ":projectId",
          data.project_id.toString()
        )
      );
      return { site: response.data.data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const updateSingleSiteAPI = createAsyncThunk(
  "project/updateSingleSiteAPI",
  async (data: UpdateProjectPayloadType, { rejectWithValue }) => {
    try {
      let { project_id, ...req_payload } = data;
      const response = await apiClient.post(
        ApiConstants.UPDATE_SINGLE_PROJECT.replace(
          ":projectId",
          data.project_id.toString()
        ),
        req_payload
      );
      return { updated_fields: data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getProjectGroupsAPI = createAsyncThunk(
  "project/getProjectGroupsAPI",
  async (
    data: { project_id: number; current_page: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_PROJECT_GROUPS.replace(
          ":projectId",
          data.project_id.toString()
        )}?page=${data.current_page}&limit=${data.limit ?? MAX_TABLE_DATA_PER_PAGE
        }`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getProjectManagerAPI = createAsyncThunk(
  "project/getProjectManagerAPI",
  async (
    data: { project_id: number; current_page: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_PROJECT_MANAGERS.replace(
          ":projectId",
          data.project_id.toString()
        )}?page=${data.current_page}&limit=${data.limit ?? MAX_TABLE_DATA_PER_PAGE
        }`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const createProjectAPI = createAsyncThunk(
  "project/createProjectAPI",
  async (data: any, { rejectWithValue }) => {
    try {

      const response = await apiClient.post(ApiConstants.CREATE_PROJECT, data);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const updateSiteAPI = createAsyncThunk(
  "project/updateSiteAPI",
  async (data: any, { rejectWithValue }) => {
    try {
      let { project_id, ...payload } = data
      const response = await apiClient.post(ApiConstants.UPDATE_SITE.replace(
        ":projectId",
        data.project_id.toString()
      ), payload);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);
