import React, { useEffect, useRef, useState } from 'react'
import { image } from '../../../../app/utils/common/image';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmModalProps } from '../../../../dto/confirm';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { createGroupAPI, getAllGroupsManagers, updateGroupAPI } from '../../../../redux/API/GroupApi';
import { uploadFile } from '../../../../redux/API/ProjectAPI';
import { showErrorToast } from '../../../../app/utils/common/Toast';
import { getCpsForManagerAPI } from '../../../../redux/API/ManagerAPI';
import { clearManagersCpData, setManagersCPsPagination } from '../../../../redux/Slices/managerSlice';
import { resetManagerUnderGroups, setCurrentGroup } from '../../../../redux/Slices/groupSlice';
import Button from '../../../atoms/Button/Button';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import ManageGroupHeader from './ManageGroupHeader';
import GroupMBComponent from './GroupMBComponet/GroupMBComponent';
import ManagerUnderGroupTable from '../AddManagerUnderGroup/AddManagerUnderGroup';
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen';
import './ManageGroupDetails.scss'

const ManageGroupDetails = () => {
    const navigate = useNavigate();
    let { type } = useParams();
    const dispatch = useAppDispatch();
    const inputRef = useRef<HTMLInputElement>(null);

    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [newProfilePhoto, setNewProfilePhoto] = useState<File>();
    const [profilePhoto, setProfilePhoto] = useState<string | null>();
    const [showError, setShowError] = useState<boolean>(false);
    const [isDataChange, setIsDataChange] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingBroker, setLoadingBroker] = useState<boolean>(false);
    const [currentManager, setCurrentManager] = useState<number>()
    const [showManagerTable, setShowManagerTable] = useState<boolean>(false)
    const [selectedManager, setSelectedManager] = useState<number[]>([]);
    const [managerData, setManagerData] = useState<any[]>([]);
    const [removedManagerIds, setRemovedManagerIds] = useState<number[]>([]);

    const { groupManager, groupLoading, managerUnderGroups,
        availableManagers, availableManagersPagination, 
        loading: managerLoading,groupPagination
     } = useAppSelector(state => state.group);
    const { pagination, loading: loadingManager, ManagersCPsPagination, ManagersCPs, managers } = useAppSelector(state => state.manager);   

    useEffect(() => {
        if (type === "edit" || type === "details") {
            if (groupManager && groupManager.group_id) {
                if (!managerUnderGroups.length) {
                    dispatch(getAllGroupsManagers({ group_id: groupManager.group_id }))
                        .unwrap()
                        .then((managersData) => {
                            dispatch(setCurrentGroup({ ...groupManager, managers: managersData }));
                        })
                        .catch((err) => {
                            // console.log("Error fetching group managers:", err);
                        })
                }
            } else {
                navigate("/dashboard/manage-group");
            }
        }
    }, [type, groupManager?.group_id, managerUnderGroups.length, pagination?.current_page]);

    useEffect(() => {
        if (type) {
            if (!["create", "edit", "details"].includes(type)) {
                navigate("/login");
            }
        } else {
            navigate("/login");
        }
        return () => {
            dispatch(resetManagerUnderGroups());
            dispatch(clearManagersCpData())
        }
    }, []);

    useEffect(() => {
        if (groupManager) {
            if (handleDataChange()) {
                setIsDataChange(true);
            } else {
                setIsDataChange(false);
            }
        }
    }, [name, newProfilePhoto, selectedManager]);

    useEffect(() => {
        if (type === "edit" || type === "details") {
            if (groupManager) {
                setName(groupManager.name);
                setProfilePhoto(groupManager.photo);

                if (type === "details") {
                    // In "details" mode, we only need to fetch managers and display them
                    dispatch(getAllGroupsManagers({ group_id: groupManager.group_id }))
                        .unwrap()
                        .then((managersData) => {
                            dispatch(setCurrentGroup({ ...groupManager, managers: managersData }));
                        })
                        .catch((err) => {
                        });
                }
            } else {
                navigate("/dashboard/manage-group");
            }
        }
    }, [type, groupManager]);



    const handleDataChange = () => {
        if (groupManager) {
            if (groupManager.name !== name.trim() || newProfilePhoto || selectedManager.length > 0) return true;
            return false;
        }
        return false;
    }

    // Show Data according to type
    const getManagersToDisplay = () => {
        return type === 'create' ? managerData : [...managerData, ...managerUnderGroups];
    };

    const managerToDisplay = getManagersToDisplay()

    // Get CPS Under selected manager
    useEffect(() => {
        if (currentManager && !loadingBroker) {
            setLoadingBroker(true);
            dispatch(getCpsForManagerAPI({ manager_id: currentManager, current_page: ManagersCPsPagination.current_page }))
                .unwrap()
                .then(() => {
                    setLoadingBroker(false);
                }).catch((error) => {
                    setLoadingBroker(false);
                });
        }
    }, [currentManager, ManagersCPsPagination.current_page]);

    // Remove Manager when type is create and edit
    const handleRemoveManager = (managerId: number) => {
        if (type === "create") {
            setSelectedManager(prev => prev.filter(id => id !== managerId));
            setManagerData((prevManagers) => prevManagers.filter((manager) => manager.manager_id !== managerId));
            if (currentManager === managerId) {
                setCurrentManager(undefined);
                dispatch(clearManagersCpData()); // Clear broker data
            }
        } else if (type === "edit") {
            setSelectedManager(prev => prev.filter(id => id !== managerId));
            if (groupManager) {
                setIsLoading(true)
                dispatch(updateGroupAPI({ delete_manager_ids: [managerId], group_id: groupManager?.group_id }))
                    .unwrap()
                    .then(() => {
                        setRemovedManagerIds(prev => [...prev, managerId]);
                        if (currentManager === managerId) {
                            setCurrentManager(undefined);
                            dispatch(clearManagersCpData()); // Clear broker data
                        }
                    })
                    .catch(err => {
                        // console.log("Group update error", err);
                    }).finally(() => {
                        setIsLoading(false);
                    });
            }

        }
    };

    const handleSubmit = async () => {
        if (name.trim().length > 0) {
            if (type === "create" && selectedManager.length === 0) {
                showErrorToast("Please select at least one manager to create a group.");
                return;
            }
            setIsLoading(true);
            let profile_image_url;

            // Only upload image if there is a new profile photo
            if (newProfilePhoto) {
                try {
                    const imageResp = await uploadFile([newProfilePhoto], "profile");
                    profile_image_url = imageResp.data.imageUrls[0];
                } catch (err) {
                    showErrorToast("Error in uploading profile image.");
                    setIsLoading(false);
                    return;
                }
            }

            if (type === "create") {
                // Only create a new group if there are actual changes
                if (selectedManager.length > 0) {
                    setIsLoading(true)
                    dispatch(createGroupAPI({
                        name: name.trim(),
                        photo: profile_image_url,
                        manager_ids: selectedManager
                    })).unwrap().then(() => {
                        navigate("/dashboard/manage-group");
                    }).catch(err => {
                    }).finally(() => {
                        setIsLoading(false)
                    })
                }
            } else if (type === "edit" && isDataChange && groupManager) {
                let updatedData: any = {}
                if (groupManager?.name !== name.trim()) updatedData.name = name;
                if (newProfilePhoto) updatedData.photo = profile_image_url;
                updatedData.group_id = groupManager.group_id;
                if (selectedManager.length) updatedData.add_manager_ids = selectedManager;
                dispatch(updateGroupAPI(updatedData)).unwrap().then(() => {
                    navigate("/dashboard/manage-group");
                }).catch(err => {
                }).finally(() => {
                    setIsLoading(false); // Stop loader
                });
            }
        } else {
            setShowError(true);
        }
    }

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this manager?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_USER_ICON
    };

    return (
        <div className={`${!showManagerTable && 'create_project_container'}`} >
            {
                <input type='file' accept='image/*' style={{ display: "none" }} ref={inputRef} onChange={(e) => {
                    if (e.target.files) {
                        setNewProfilePhoto(e.target.files[0])
                    }
                }} />
            }
            {isLoading && <LoaderScreen />}
            {!showManagerTable && <>
                <div className='create_project_header_container'>
                    <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                        navigate(-1);
                    }} />
                    <div className='header_text'>{type === "details" && <span style={{ color: "#5f9937", marginRight: "20px" }}>Manage Groups </span>}{`${type === "create" ? 'Create Group' : type === "edit" ? 'Edit Group' : 'Group Details'} `}</div>
                </div>
                <div className='group-details-container'>
                    <ManageGroupHeader name={name} setName={setName} newProfilePhoto={newProfilePhoto} showError={showError} profilePhoto={profilePhoto} inputRef={inputRef} isEditMode={type !== "details"} />
                    <GroupMBComponent
                        groupLoading={groupLoading}
                        loadingManager={loadingManager}
                        type={type}
                        showError={showError}
                        setShowManagerTable={setShowManagerTable}
                        currentManager={currentManager}
                        setCurrentManager={setCurrentManager}
                        ManagersCPs={ManagersCPs}
                        loadingBroker={loadingBroker}
                        handleRemoveManager={handleRemoveManager}
                        removedManagerIds={removedManagerIds}
                        managerToDisplay={managerToDisplay}
                        ManagersCPsPagination={ManagersCPsPagination}
                        pagination={pagination}
                    />
                    {type !== "details" && (
                        <div className='grp-submit-container'>
                            <Button buttonTitle={type === "create" ? "Submit" : "Update"} onClick={handleSubmit} className='group_btn_submit' />
                        </div>
                    )}
                </div></>}
            {showManagerTable && <ManagerUnderGroupTable
                setShowManagerTable={setShowManagerTable}
                setManagerData={setManagerData}
                selectedManager={selectedManager}
                setSelectedManager={setSelectedManager}
                // availableManagers={availableManagers}
                managers={managers}
                pagination={pagination}
                // availableManagersPagination={availableManagersPagination}
                // managerLoading={managerLoading}
                loadingManager={loadingManager}
                groupManager={groupManager} />}

            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

export default ManageGroupDetails