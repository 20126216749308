import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import React, { act } from "react";
import { PostSliceState } from "../../Slices/postSlice";
import { PaginationType } from "../../../dto/Pagination.dto";
import { showErrorToast } from "../../../app/utils/common/Toast";
import { getRegistrationRequestsAPI } from "../../API/RegistrationRequestAPI";
import { RegristrationRequestType } from "../../../dto/RegistrationRequest.dto";
import { RegistrationReqSliceState } from "../../Slices/registrationRequestSlice";

export const RegistrationRequestReducer = (
  builder: ActionReducerMapBuilder<RegistrationReqSliceState>
) => {
  builder
    .addCase(getRegistrationRequestsAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getRegistrationRequestsAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          user_data: RegristrationRequestType[];
          pagination: PaginationType;
          status: string;
        }>
      ) => {
        if (
          action.payload.pagination.current_page ===
          state.pagination.current_page
        ) {
          //   if (action.payload.status === "pending") {
          state.requests = action.payload?.user_data;
          state.pagination = action.payload.pagination;
          //   }
          //   state.pagination = action.payload.pagination;
          state.loading = false;
        }
      }
    )
    .addCase(
      getRegistrationRequestsAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
          state.loading = false;
        } else {
          showErrorToast("Error in manager creation.");
        }
      }
    );

  // .addCase(VerifyPostAPI.pending, (state) => {
  //   state.verifyLoading = true;
  // })
  // .addCase(VerifyPostAPI.fulfilled, (state, action: PayloadAction<any>) => {
  //   console.log("action.payload :>> ", action.payload);

  //   state.verifyLoading = false;
  // })
  // .addCase(VerifyPostAPI.rejected, (state, action: PayloadAction<any>) => {
  //   if (action.payload.message) {
  //     showErrorToast(action.payload.message);
  //     state.verifyLoading = false;
  //   } else {
  //     showErrorToast("Error in manager creation.");
  //   }
  // });
};
