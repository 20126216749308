import React, { useEffect, useState } from 'react';
import "./SingleSite.scss";
import { Tabs } from 'antd';
import SingleSiteDetails from '../../../../molecules/SiteManagement/SingleSiteDetails/SingleSiteDetails';
import { image } from '../../../../../app/utils/common/image';
import TrainingVideo from '../../../../molecules/SiteManagement/TrainingVideos/TrainingVideo';
import ProjectManagersTable from '../../../../molecules/SiteManagement/ManagerListTable/ProjectManagersTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../app/utils/hooks/reduxHook';
import { getSingleProjectAPI } from '../../../../../redux/API/ProjectAPI';
import LoaderScreen from '../../../../atoms/LoaderScreen/LoaderScreen';
import GroupTableForSingleSite from '../../../../molecules/SiteManagement/GroupListTable/GroupTableForSingleSite';
import { clearSingleSiteData } from '../../../../../redux/Slices/projectSlice';
import { checkPermission, permissionType } from '../../../../../app/utils/common/checkPermission';

const ActiveTabHeader = (data: { value: string }) => (
    <div className='tab_selected'>
        {data.value}

    </div>
)

const SingleSite = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { SingleProject } = useAppSelector(state => state.project);
    const [loading, setLoading] = useState(false);
    const [refreshData, setRefreshData] = useState(true);
    const { id } = useParams();
    useEffect(() => {
        if (id && refreshData) {
            setLoading(true);
            dispatch(getSingleProjectAPI({ project_id: id })).unwrap().then(() => { }).catch(() => {
                navigate("/error");
            }).finally(() => {
                setLoading(false);
                setRefreshData(false);
            });
        }
    }, [refreshData, id])




    return (
        <div className='single_site'>
            <div className='sitename-header'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>{SingleProject?.name}
                    {checkPermission(permissionType.SITE_MODULE, "update") &&
                        <img style={{ height: "50px", width: "50px", cursor: "pointer" }} src={image.EDIT_ICON} onClick={() => {
                            navigate("/dashboard/site-management/edit")
                        }} />
                    }
                </div>
            </div>
            {loading ? <LoaderScreen /> : <Tabs
                defaultActiveKey="1"
                tabBarStyle={{
                    borderBottom: '1px solid #C4C4C4', // Underline color for tabs
                }}
                items={[
                    {
                        label: <ActiveTabHeader value='Details' />,
                        key: '1',
                        children: <SingleSiteDetails setLoading={setLoading} setRefreshData={setRefreshData} />,
                    },
                    {
                        label: <ActiveTabHeader value='Training Videos' />,
                        key: '2',
                        children: <TrainingVideo />,
                    },
                    {
                        label: <ActiveTabHeader value='Groups' />,
                        key: '3',
                        children: <GroupTableForSingleSite />,
                    },
                    {
                        label: <ActiveTabHeader value='Managers' />,
                        key: '4',
                        children: <ProjectManagersTable />,
                    }
                ]}
            />}
        </div>
    )
}

export default SingleSite