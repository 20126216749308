import React, { useEffect } from 'react'
import CreateProjectModal from '../../../molecules/Project/CreateProject/CreateProject';
import './Project.scss'
import { image } from '../../../../app/utils/common/image';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/utils/hooks/reduxHook';

const CreateProjectPage = () => {
    const navigate = useNavigate();
    const { type } = useParams();
    const { SingleProject } = useAppSelector(state => state.project)
    useEffect(() => {
        if (type === "edit") {
            if (!SingleProject) {
                navigate("/dashboard");
            }
        } else if (type === "create") {

        } else {
            navigate("/dashboard");
        }
    }, [])
    return (
        <div className='create_project_container' >
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>Site Management</div>
            </div>
            <CreateProjectModal type={type ?? "create"} />
        </div>
    )
}

export default CreateProjectPage