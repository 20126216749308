import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationType } from "../../dto/Pagination.dto";
import {
  VisitManagementDetails,
  visitManagementType,
} from "../../dto/VisitManagement.dto";
import VisitManagementReducer from "../Reducer/VisitManagement/VisitManagementReducer";

export interface VisitSliceState {
  scheduledData: visitManagementType[];
  visitedData: visitManagementType[];
  pagination: PaginationType;
  loading: boolean;
  scheduledDetailsData: VisitManagementDetails[];
  visitedDetailsData: VisitManagementDetails[];
  visitActiveTab: string;
}

const initialState: VisitSliceState = {
  scheduledData: [],
  visitedData: [],
  visitActiveTab: "scheduled",
  scheduledDetailsData: [],
  visitedDetailsData: [],
  pagination: {
    total_data: 0,
    current_page: 1,
    page_size: 7,
    total_pages: 1,
  },
  loading: false,
};

const visitManagementSlice = createSlice({
  name: "siteVisit",
  initialState,
  reducers: {
    setVisitManangementPagination( state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },

    setVisitManagementLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setVisitManagementActiveTab(state, action: PayloadAction<any>) {
      state.visitActiveTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    VisitManagementReducer(builder);
  },
});

export const {
  setVisitManangementPagination,
  setVisitManagementLoading,
  setVisitManagementActiveTab,
} = visitManagementSlice.actions;

export default visitManagementSlice.reducer;
