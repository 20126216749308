import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { PaginationType } from "../../../dto/Pagination.dto";
import { showErrorToast } from "../../../app/utils/common/Toast";
import { getAllUsersAPI } from "../../API/AllUsersAPI";
import { AllUsersSliceState } from "../../Slices/allUsersSlice";
import { AllUsersType } from "../../../dto/AllUsers.dto";

export const AllUsersReducer = (
  builder: ActionReducerMapBuilder<AllUsersSliceState>
) => {
  builder
    .addCase(getAllUsersAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getAllUsersAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          user_data: AllUsersType[];
          pagination: PaginationType;
          role_id: number;
        }>
      ) => {
        if (
          action.payload.pagination.current_page ===
          state.pagination.current_page
        ) {
          //   if (action.payload.status === "pending") {
          state.allUsers = action.payload?.user_data;
          state.pagination = action.payload.pagination;
          //   }
          //   state.pagination = action.payload.pagination;
          state.loading = false;
        }
      }
    )
    .addCase(getAllUsersAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
        state.loading = false;
      } else {
        showErrorToast("Error in getting all users.");
      }
    });
};
