import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmModalProps } from '../../../../dto/confirm';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import { image } from '../../../../app/utils/common/image';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import Loader from '../../../atoms/Loader/Loader';
import moment from 'moment';
import Modal from '../../../atoms/Modal/Modal';
import QRScanner from '../../../atoms/QRScanner/QRScanner';
import "./VisitTables.scss"
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import Button from '../../../atoms/Button/Button';
import { rejectModalProps } from '../../../../dto/reject.dto';
import RejectModal from '../../../atoms/RejectModal/RejectModal';
import { getSiteVisitDetailsAPI, updateSaleStatusAPI } from '../../../../redux/API/VisitManagementAPI';
import { error } from 'console';
import { setVisitManagementLoading } from '../../../../redux/Slices/visitManagementSlice';
import { showSuccessToast } from '../../../../app/utils/common/Toast';

const ActionButtonContainer = () => {
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [qrModal, setQRModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const [data, setData] = useState('');
    const [error, setError] = useState('');
    const options = [
            {
            name: "Sale Done And Not Done",
            onClick: () => {
                navigate("manager/create")
            }
        },
        {
            name: "Approve And Reject",
            onClick: () => {
                navigate("manager/create")
            }
        }
    ];



    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {/* {qrModal && <Modal isClose={true} onClose={() => setQRModal(false)} >
                <QRScanner />
            </Modal>} */}

        </div>
    )
}


const VisitedVisitTable: React.FC = () => {
    const navigate = useNavigate()
    const { visitedDetailsData, loading } = useAppSelector(state => state.vistManagement)
    const [isMobileView, setIsMobileView] = useState(false);
    const [AcceptSaleVisitModal, setAcceptSaleVisitModal] = useState(false);
    const [RejectSiteVisitModal, setRejectSiteVisitModal] = useState(false);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [visitId, setVisitId] = useState<number>();
    const [status, setStatus] = useState<number>();
    // const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    const acceptModalProps: confirmModalProps = {
        text:
        status === 1
            ? "Do you really want to mark this as done?"
            : status === 2
            ? "Do you really want to confirm the sale as done?"
                    : "Do you really want to mark this as done?",
        confirmModal: AcceptSaleVisitModal,
        setConfirmModal: setAcceptSaleVisitModal,
        buttons: ["Yes, Accept", "Cancel"],
        onPressPositive: () => {handleAcceptSaleClick()},
        onPressNegative: () => { setAcceptSaleVisitModal(false)},
        image: image.ADD_ICON,
    };

    const rejectModalProps: rejectModalProps = {
        heading: "Are you sure you want to Reject this sale?",
        showRejectModal: RejectSiteVisitModal,
        setShowRejectModal: setRejectSiteVisitModal,
        rejectReason: rejectReason,
        setRejectReason: setRejectReason,
        handleRejectedSaveBtn: () => {handleRejectSaleClick()},
    };

    const handleAcceptClick = (visitId: number, currentStatus: number) => {
        setVisitId(visitId);
        setStatus(currentStatus); // Set the status dynamically
        setAcceptSaleVisitModal(true);
    }
    const handleRejectClick = (visitId: number, currentStatus: number) => {
        setVisitId(visitId);
        setRejectSiteVisitModal(true);
        setStatus(currentStatus);
    };

        const handleAcceptSaleClick = () => {
        setAcceptSaleVisitModal(false);
        dispatch(setVisitManagementLoading(true));
        if (visitId) {
            dispatch(updateSaleStatusAPI({ visit_id: visitId, status: 3 })).unwrap().then((result) => {
                showSuccessToast("Site completed successfully.");
                if (id) {
                    dispatch(getSiteVisitDetailsAPI({ project_id: id, visit_type: "visited", page: 1 }));
                }
                dispatch(setVisitManagementLoading(false));
            }).catch((err:any) => {
                console.log('err :>> ', err);
                dispatch(setVisitManagementLoading(false));
            }).finally(() => {
                dispatch(setVisitManagementLoading(false));
            });
        }

    }
    const handleRejectSaleClick = () => {
        setRejectSiteVisitModal(false);
        dispatch(setVisitManagementLoading(true));
        if (visitId && status) {
            const rejectStatus = status === 1 ? 4 : status === 2 ? 5 : undefined;
            if (rejectStatus) {
                dispatch(
                    updateSaleStatusAPI({ visit_id: visitId, status: rejectStatus, reject_reason: rejectReason, }))
                    .unwrap()
                    .then((result) => {
                        showSuccessToast("Site Rejected successfully.");
                        if (id) {
                            dispatch(getSiteVisitDetailsAPI({ project_id: id, visit_type: "visited", page: 1 }));
                        }
                        console.log('Reject Success:', result);
                    })
                    .catch((err: any) => {
                        console.error('Reject Error:', err);
                    })
                    .finally(() => {
                        dispatch(setVisitManagementLoading(false));
                    });
            }
        }  
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1000);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const columns: TableColumnsType = isMobileView ? [
        {
            title: 'Profile',
            dataIndex: 'profile',
            render: (text, record) => (
                <img
                    src={record.profile}
                    alt="Profile"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
        },
        {
            title: 'Date',
            dataIndex: 'visitDate',
        },
        {
            title: 'Customer Name',
            dataIndex: 'name',
            render: (text, record) => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: 'Customer Phone Number',
            dataIndex: 'phoneNumber',
        },

        {
            title: 'Broker Name',
            dataIndex: 'cpName',
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     render: (text, record) => <ActionButtonContainer />,
        // },

    ]
        : [
            {
                title: 'Profile',
                dataIndex: 'profile',
                render: (text, record) => (
                    <img
                        src={record.profile}
                        alt="Profile"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    />
                ),
            },
            {
                title: 'Date',
                dataIndex: 'visitDate',
            },
            {
                title: 'Customer Name',
                dataIndex: 'name',
                render: (text, record) => (
                    <div>
                        {text}
                    </div>
                ),
            },
            {
                title: 'Customer Phone Number',
                dataIndex: 'phoneNumber',
            },
            {
                title: 'Broker Name',
                dataIndex: 'cpName',
            },
            {
                title: 'Broker Phone Number',
                dataIndex: 'cpPhoneNumber',
            },
            {
                title: 'Time Slot',
                dataIndex: 'timeSlot',
            },
            {
                title: 'Sale Status',
                dataIndex: 'saleStatus',
                render: (text, record) => (
                    <div>
                        {record.saleStatus === 1 ? "Sale Pending" :record.saleStatus === 2 ? "Sale Initiated": record.saleStatus === 3? "sold": record.saleStatus === 4?"Reject By CP":record.saleStatus === 5?"Reject By Admin":"Undefined"}
                    
                    </div>
                ),
            },
            {
                title: 'Reject Reason',
                dataIndex: 'rejectReason',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                render: (_, record) => (
                    <div className="reject-accept-btn">
                        {record.saleStatus === 3 ? (
                            <p className='sold_text'>Sold</p>
                        ) : (
                                <>
                                    {(record.saleStatus === 4 || record.saleStatus === 5)?"":
                                        <Button
                                            buttonTitle={
                                                record.saleStatus === 1
                                                    ? "Sale Not Initiated"
                                                    : record.saleStatus === 2
                                                        ? "Sale Not Confirmed"
                                                        : ""
                                            }
                                            onClick={() => handleRejectClick(record.visitId, record.saleStatus)}
                                            className="RejectButton"
                                        />}
                                <Button
                                    buttonTitle={
                                        (record.saleStatus === 1 || record.saleStatus === 4 || record.saleStatus === 5)
                                            ? "Sale Initiated"
                                            : record.saleStatus === 2
                                            ? "Sale confirmed"
                                            : ""
                                    }
                                    onClick={() => handleAcceptClick(record.visitId, record.saleStatus)}
                                    className="RejectButton AcceptButton"
                                />
                            </>
                        )}
                    </div>
                ),
            },
            
        ];
    const dataSource = visitedDetailsData?.map((record, i) => ({
        key: i,
        visitId: record.visit_id,
        profile: record.cp_image ? getImageURL(record.cp_image) : image.DEFAULT_PROFILE_IMAGE,
        name: record.name,
        phoneNumber: record.phone_number,
        cpName: record.cp_name,
        cpPhoneNumber: record.cp_phone_number || 'N/A',
        visitDate: moment(record.visit_date).format('MM/DD/YYYY'),
        timeSlot: `${record.start_time} - ${record.end_time}`,
        saleStatus: record.sale_status,
        rejectReason: record.reject_reason,

    }));

    const [currentPage, setCurrentPage] = useState(1);
    const pageNumbers: any[] = [];
    const [pagination, setPagination] = useState({
        totalData: dataSource?.length,
        currentPage: 1,
    });
    const pageSize = 7;
    const totalPages = Math?.ceil(dataSource?.length / pageSize);

    // Get data for the current page
    const currentData = dataSource?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers?.push(i);
    }
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        // setIsScheduledView(true)
    };
    return (
        <div className='visit_data_table'>
            <div>
                {loading ? <div className='center_div'><Loader /></div> :
                    <TableComponent
                        columns={columns}
                        dataSource={currentData}
                        pageNumbers={pageNumbers}
                        handlePageLink={handlePageChange}
                        totalProfile={pagination.totalData}
                        prevPage={() => handlePageChange(currentPage - 1)}
                        nextPage={() => handlePageChange(currentPage + 1)}
                        activePage={currentPage}
                        currentTotal={Math?.min(currentPage * pageSize, dataSource?.length)} />
                }
            </div>
            {AcceptSaleVisitModal && <ConfirmModal confirmModalProps={acceptModalProps} />}
            {RejectSiteVisitModal && <RejectModal rejectModalProps={rejectModalProps} />}
        </div>
        
    )
}

export default VisitedVisitTable;
