import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmModalProps } from '../../../../dto/confirm';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import { image } from '../../../../app/utils/common/image';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import Loader from '../../../atoms/Loader/Loader';
import moment from 'moment';
import Modal from '../../../atoms/Modal/Modal';
import QRScanner from '../../../atoms/QRScanner/QRScanner';
import { completeVisitAPI, getSiteVisitDetailsAPI, markAsCompleteVisitAPI } from '../../../../redux/API/VisitManagementAPI';
import { showErrorToast, showSuccessToast } from '../../../../app/utils/common/Toast';
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import { checkPermission, permissionType } from '../../../../app/utils/common/checkPermission';
import EmptyPage from '../../../atoms/EmptyPage/EmptyPage';


const ActionButtonContainer: React.FC<{
    record_id: number
}> = ({
    record_id
}) => {
        const [isMenuOpen, seIsMenuOpen] = useState(false);
        const [qrModal, setQRModal] = useState<boolean>(false);
        const navigate = useNavigate();
        const [loading, setLoading] = useState(false);
        const [markAsCompleteModal, setMarkAsCompleteModal] = useState<boolean>(false);
        const dispatch = useAppDispatch();
        const { visitActiveTab } = useAppSelector(state => state.vistManagement)
        const { id } = useParams<{ id: string }>();

        const options = [
            {
                name: "Mark as complete",
                onClick: () => {
                    if (checkPermission(permissionType.VISIT_MODULE, "update")) {
                        setMarkAsCompleteModal(true);
                    }else if (checkPermission(permissionType.VISIT_MODULE, "QR")) {
                        setQRModal(true);
                    } else {
                        showErrorToast("You do not have the required permissions.");
                    }
                }
            }
        ];

        const confirmModalProps: confirmModalProps = {
            text: "Are you sure you want to mark as complete?",
            confirmModal: markAsCompleteModal,
            setConfirmModal: setMarkAsCompleteModal,
            buttons: ['Yes, Done', 'Cancel'],
            onPressPositive: () => { handleConfirmationMarkAsDoneClick() },
            onPressNegative: () => { setMarkAsCompleteModal(false) },
            image: image.ADD_ICON
        };
    
        const handleGetData = (data: string) => {
            setLoading(true);
            dispatch(completeVisitAPI({ visit_id: record_id, code: data })).unwrap().then(() => {
                setLoading(false);
                setQRModal(false);
                showSuccessToast("Successfully marked as visited.")
            }).catch((error) => {
                showErrorToast(error);
                setLoading(false);
            })
        }

        const handleConfirmationMarkAsDoneClick = () => {
            setMarkAsCompleteModal(false);
            setLoading(true);
            dispatch(markAsCompleteVisitAPI({ visit_id: record_id })).unwrap().then(() => {
                setLoading(false);
                if (id) {
                    dispatch(getSiteVisitDetailsAPI({ project_id: id, visit_type: 'scheduled', page: 1 }));
                }
        
                showSuccessToast("Successfully marked as visited.")
                // dispatch()
            }).catch((error) => {
                showErrorToast(error);
                setLoading(false);
            })
        }
        return (
            <div className='action_tab' style={{ position: "relative" }}>
                {loading && <div><LoaderScreen /></div>}
                <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                    <ActionIcon />
                </div>
                {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
                {qrModal && checkPermission(permissionType.VISIT_MODULE, "QR") && <Modal isClose={true} onClose={() => setQRModal(false)} >
                    <QRScanner handleGetData={(data) => { handleGetData(data) }} loading={loading} />
                </Modal>}

                {markAsCompleteModal && checkPermission(permissionType.VISIT_MODULE, "update") && (
                  <ConfirmModal confirmModalProps={confirmModalProps} />
                )}
            </div>
        )
    }

const ScheduledVisitTable: React.FC = () => {
    const navigate = useNavigate()
    const { scheduledDetailsData, visitedDetailsData, loading, visitActiveTab } = useAppSelector(state => state.vistManagement)

    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1000);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const columns: TableColumnsType = isMobileView ? [
        {
            title: 'Profile',
            dataIndex: 'profile',
            render: (text, record) => (
                <img
                    src={record.profile}
                    alt="Profile"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
        },
        {
            title: 'Date',
            dataIndex: 'visitDate',
        },
        {
            title: 'Customer Name',
            dataIndex: 'name',
            render: (text, record) => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: 'Customer Phone Number',
            dataIndex: 'phoneNumber',
        },

        {
            title: 'Broker Name',
            dataIndex: 'cpName',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <ActionButtonContainer record_id={record.visit_id} />
            ),
        },

    ]
        : [
            {
                title: 'Profile',
                dataIndex: 'profile',
                render: (text, record) => (
                    <img
                        src={record.profile}
                        alt="Profile"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    />
                ),
            },
            {
                title: 'Date',
                dataIndex: 'visitDate',
            },
            {
                title: 'Customer Name',
                dataIndex: 'name',
                render: (text, record) => (
                    <div>
                        {text}
                    </div>
                ),
            },
            {
                title: 'Customer Phone Number',
                dataIndex: 'phoneNumber',
            },
            {
                title: 'Broker Name',
                dataIndex: 'cpName',
            },
            {
                title: 'Broker Phone Number',
                dataIndex: 'cpPhoneNumber',
            },
            {
                title: 'Time Slot',
                dataIndex: 'timeSlot',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                render: (text, record) => <ActionButtonContainer record_id={record.visit_id} />,
            },
        ];
    const dataSource = scheduledDetailsData?.map((record, i) => ({
        key: i,
        visit_id: record.visit_id,
        profile: record.cp_image ? getImageURL(record.cp_image) : image.DEFAULT_PROFILE_IMAGE, // fallback to a default image if cp_image is not available
        name: record.name,
        phoneNumber: record.phone_number,
        cpName: record.cp_name,
        cpPhoneNumber: record.cp_phone_number || 'N/A',
        visitDate: moment(record.visit_date).format('MM/DD/YYYY'),
        timeSlot: `${record.start_time} - ${record.end_time}`,
    }));

    const [currentPage, setCurrentPage] = useState(1);
    const pageNumbers: any[] = [];
    const [pagination, setPagination] = useState({
        totalData: dataSource?.length,
        currentPage: 1,
    });
    const pageSize = 7;
    const totalPages = Math?.ceil(dataSource?.length / pageSize);

    // Get data for the current page
    const currentData = dataSource?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers?.push(i);
    }
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        // setIsScheduledView(true)
    };
    return (
        <div className='visit_data_table'>
            {loading ? <div className='center_div'><Loader /></div> :
                scheduledDetailsData.length > 0 ?
                    <div>
                        <TableComponent
                            columns={columns}
                            dataSource={currentData}
                            pageNumbers={pageNumbers}
                            handlePageLink={handlePageChange}
                            totalProfile={pagination.totalData}
                            prevPage={() => handlePageChange(currentPage - 1)}
                            nextPage={() => handlePageChange(currentPage + 1)}
                            activePage={currentPage}
                            currentTotal={Math?.min(currentPage * pageSize, dataSource?.length)} />
                    </div> :
                    <div style={{ height: "40%", width: "40%" }} className='center_div'>
                        <EmptyPage image={image.NO_SITE_VISITED_YET_IMAGE} text='No Scheduled Visit Available.' />
                    </div>
            }
        </div>
    )


}

export default ScheduledVisitTable;
