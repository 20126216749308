import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { MAX_TABLE_DATA_PER_PAGE } from "../../app/utils/apis/Constant";

export const getAllGroups = createAsyncThunk(
  "group/getAllGroups",
  async (
    {
      page,
      total_page = MAX_TABLE_DATA_PER_PAGE,
      searchText = "",
    }: { page: number; total_page?: number; searchText?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_ALL_GROUPS}/?page=${page}&searchText=${searchText ?? ""}&limit=${total_page}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all groups");
    }
  }
);

export const getAllGroupsManagers = createAsyncThunk(
  "group/getAllGroupsManagers",
  async (
    data: { group_id: number; current_page?: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.UPDATE_GROUP}/${data.group_id}/managers?page=${data.current_page ?? 1
        }&limit=${data.limit ?? 5}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all groups");
    }
  }
);

export const getAvailableManagers = createAsyncThunk(
  "group/getAvailableManagers",
  async (
    data: { current_page: number; limit?: number; searchText?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_AVAILABLE_MANAGERS}?page=${data.current_page
        }&searchText=${data.searchText ?? ""}&limit=${data.limit ?? MAX_TABLE_DATA_PER_PAGE
        }`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all groups");
    }
  }
);

export const getAllGroupsManagersCPs = createAsyncThunk(
  "group/getAllGroupsManagersCPs",
  async (
    data: { group_id: number; manager_id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.UPDATE_GROUP}/${data.group_id}/managers/${data.manager_id}/CPs`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all groups");
    }
  }
);

export const createGroupAPI = createAsyncThunk(
  "group/createGroupAPI",
  async (
    data: {
      name: string;
      photo: string;
      manager_ids: number[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.CREATE_GROUP}`,
        data
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const updateGroupAPI = createAsyncThunk(
  "group/updateGroupAPI",
  async (
    data: {
      group_id: number;
      name?: string;
      photo?: string;
      add_manager_ids?: number[];
      delete_manager_ids?: number[];
    },
    { rejectWithValue }
  ) => {
    try {
      let { group_id, ...reqObj } = data;
      const response = await apiClient.put(
        `${ApiConstants.UPDATE_GROUP}/${group_id}/update`,
        reqObj
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "Failed to create manager"
      );
    }
  }
);

export const deleteGroupAPI = createAsyncThunk(
  "group/deleteGroupAPI",
  async (data: { group_id: number }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `${ApiConstants.UPDATE_GROUP}/${data.group_id}/delete`
      );
      return {
        payload: response.data.data,
        group_id: data.group_id,
      };
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "Failed to create manager"
      );
    }
  }
);
