import React from "react";
import Lottie from "lottie-react";
import loaderGrayAnimation from "../../../assets/loader/gray_loader.json";
import loaderGreenAnimation from "../../../assets/loader/green_loader.json";


interface LoaderPropsType {
    height?: number;
    width?: number;
    isGray?: boolean;
}

const Loader: React.FC<LoaderPropsType> = ({ height = 200, width = 200, isGray = false }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: `${height}px`, width: `${width}px` }}>
            <Lottie animationData={isGray ? loaderGrayAnimation : loaderGreenAnimation} loop={true} />
        </div>
    );
};

export default Loader;
