import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegristrationRequestType } from "../../dto/RegistrationRequest.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { RegistrationRequestReducer } from "../Reducer/RegistrationRequest/RegistrationRequestReducer";
import { AllUsersReducer } from "../Reducer/AllUsers/AllUsersReducer";
import { AllUsersType } from "../../dto/AllUsers.dto";

export interface AllUsersSliceState {
  loading: boolean;
  verifyLoading: boolean;
  allUsers: AllUsersType[];
  pagination: PaginationType;
}

const initialState: AllUsersSliceState = {
  loading: false,
  verifyLoading: false,
  allUsers: [],
  pagination: {
    total_data: 0,
    current_page: 1,
    page_size: 7,
    total_pages: 1,
  },
};

const allUsersSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    setAllUsersPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },

    setAllUsersLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    AllUsersReducer(builder);
  },
});
export const { setAllUsersPagination, setAllUsersLoading } =
  allUsersSlice.actions;

export default allUsersSlice.reducer;
