import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SearchSliceStateType {
    searchText: string;
}

const initialState: SearchSliceStateType = {
    searchText: "",
};

const searchSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        }
    },
    extraReducers: (builder) => {
    },
});
export const { setSearchText } = searchSlice.actions;

export default searchSlice.reducer;
