import React from "react";
import { image } from "../../../app/utils/common/image";
import Card from "../../atoms/Card/Card";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/utils/hooks/reduxHook";

const HomepageStats = () => {
  const { pagination } = useAppSelector((state) => state.broker);
  const { pagination: managerPagination } = useAppSelector(
    (state) => state.manager
  );
  const { pagination: projectPagination } = useAppSelector((state) => state.project);

  const { pagination: registrationRequestPagination } = useAppSelector((state) => state.registrationRequest);
  const navigate = useNavigate();

  let StatsData = [
    {
      value: projectPagination?.total_data || 0,
      label: "Total Sites",
      percentage: "+0.5%",
      icon: image.SITE_WHITE_ICON,
      navigateTo: "/dashboard/site-management",
    },
    {
      value: managerPagination.total_data,
      label: "Managers Worldwide",
      percentage: "+32%",
      icon: image.MANAGERS_WORLDWIDE_ICON,
      navigateTo: "/dashboard/manage-manager",
    },
    {
      value: pagination.total_data || 0,
      label: "Brokers Worldwide",
      percentage: "+26.5%",
      icon: image.BROKERS_WORLDWIDE_ICON,
      navigateTo: "/dashboard/manage-broker",
    },
    {
      value: registrationRequestPagination.total_data,
      label: "Registration Requests",
      percentage: "+20.5%",
      icon: image.SITE_COMPLETED_ICON,
      navigateTo: "/dashboard/registration-request",
    },
  ];

  const handleCardClick = (navigateTo: any) => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  };
  return (
    <div className="stats_container">
      <div className="stats_header">Dashboard</div>
      <div className="stats_card_container">
        {StatsData.map((stat, index) => (
          <Card
            key={index}
            rotation={180}
            primaryBorderColor="#5f993700"
            secondaryBorderColor="white"
          >
            <div
              className="stats_card"
              onClick={() => handleCardClick(stat.navigateTo)}
            >
              <div className="stats_data_container">
                <div className="stats_number">{stat.value}</div>
                <div className="stats_label">{stat.label}</div>
                {/* <div>
                  <span className="stats_increment_percentage">
                    {stat.percentage}{" "}
                  </span>
                  <span className="stats_span">than last month</span>
                </div> */}
              </div>
              <div className="stats_icon_container">
                <div className="stats_icon">
                  <img className="stats_icon_image" src={stat.icon} />
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HomepageStats;
