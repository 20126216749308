import DashboardGreen from "../assets/Icons/sidebar/dashboard_green.png";
import SiteGreen from "../assets/Icons/sidebar/site_green.png";
import ManagerGreen from "../assets/Icons/sidebar/manager_green.png";
import BrokerGreen from "../assets/Icons/sidebar/broker_green.png";
import GroupGreen from "../assets/Icons/sidebar/group_green.png";
import PostGreen from "../assets/Icons/sidebar/post_green.png";
import RegistrationGreen from "../assets/Icons/sidebar/registration_green.png";
import DashboardWhite from "../assets/Icons/sidebar/dashboardwhite.png";
import SiteWhite from "../assets/Icons/sidebar/siteManageWhite.png";
import ManagerWhite from "../assets/Icons/sidebar/managerWhite.png";
import BrokerWhite from "../assets/Icons/sidebar/manageBrokerWhite.png";
import GroupWhite from "../assets/Icons/sidebar/groupWhite.png";
import PostWhite from "../assets/Icons/sidebar/postRequestWhite.png";
import RegistrationWhite from "../assets/Icons/sidebar/registrationWhite.png";
import ContactUsGreen from "../assets/Icons/sidebar/contactusgreen.png";
import ContactUsWhite from "../assets/Icons/sidebar/contactuswhite.png";
import RankingGreen from "../assets/Icons/sidebar/Ranking.png";
import RankingWhite from "../assets/Icons/sidebar/RankingWhite.png";
import VisitGreen from "../assets/Icons/sidebar/VisitManagement.png";
import VisitWhite from "../assets/Icons/sidebar/visitWhite.png";
import RoleGreen from "../assets/Icons/sidebar/RoleManagement.png";
import RoleWhite from "../assets/Icons/sidebar/RoleWhite.png";


import { permissionType } from "../app/utils/common/checkPermission";

export const sidebarOptions = [
  {
    key: "Dashboard",
    flag: "Dashboard",
    icon: DashboardGreen,
    whiteIcon: DashboardWhite,
    redirection: "/dashboard/",
    permission: permissionType.SITE_MODULE,
  },
  {
    key: "Site Management",
    flag: "site_management",
    icon: SiteGreen,
    whiteIcon: SiteWhite,
    redirection: "/dashboard/site-management",
    permission: permissionType.SITE_MODULE,
  },
  {
    key: "Visit Management",
    flag: "visit_management",
    icon: VisitGreen,
    whiteIcon: VisitWhite,
    permission: permissionType.VISIT_MODULE,
    redirection: "/dashboard/visit-management",
  },
  {
    key: "Manage Managers",
    flag: "manage_managers",
    icon: ManagerGreen,
    whiteIcon: ManagerWhite,
    redirection: "/dashboard/manage-manager",
    permission: permissionType.MANAGER_MODULE,
  },
  {
    key: "Manage Brokers",
    flag: "manage_brokers",
    icon: BrokerGreen,
    whiteIcon: BrokerWhite,
    redirection: "/dashboard/manage-broker",
    permission: permissionType.BROKER_MODULE,
  },
  {
    key: "Manage Groups",
    flag: "manage_groups",
    icon: GroupGreen,
    whiteIcon: GroupWhite,
    redirection: "/dashboard/manage-group",
    permission: permissionType.GROUP_MODULE,
  },
  {
    key: "Post Requests",
    flag: "post_request",
    icon: PostGreen,
    whiteIcon: PostWhite,
    redirection: "/dashboard/post-requests",
    permission: permissionType.POST_MODULE,
  },
  {
    key: "Registration Requests",
    flag: "registration_request",
    icon: RegistrationGreen,
    whiteIcon: RegistrationWhite,
    redirection: "/dashboard/registration-request",
    permission: permissionType.USER_REGISTRATION_REQUEST,
  },
  {
    key: "Role Management",
    flag: "role_management",
    icon: RoleGreen,
    whiteIcon: RoleWhite,
    redirection: "/dashboard/role-management",
    // permission: permissionType.USER_REGISTRATION_REQUEST,
  },
  {
    key: "Rank Management",
    flag: "rank_management",
    icon: RankingGreen,
    whiteIcon: RankingWhite,
    redirection: "/dashboard/rank-management",
    // permission: permissionType.USER_REGISTRATION_REQUEST,
  },
  // {
  //   key: "Manage User Management",
  //   flag: "manage_user_management",
  //   icon: RegistrationGreen,
  //   whiteIcon: RegistrationWhite,
  //   redirection: "/dashboard/manage-user-management",
  //   permission: permissionType.USER_MODULE,
  // },

  {
    key: "All Users",
    flag: "all_users",
    icon: RegistrationGreen,
    whiteIcon: RegistrationWhite,
    redirection: "/dashboard/all-users",
    permission: permissionType.USER_MODULE,
  },
  {
    key: "Contact us",
    flag: "contact_us",
    icon: ContactUsGreen,
    whiteIcon: ContactUsWhite,
    redirection: "/dashboard/contact-us",
  },
];

export interface OptionProps {
  isActive: boolean;
  isExpanded: boolean;
}
