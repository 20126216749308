import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { RoleSliceStateTypes } from "../../Slices/roleSlice";
import { getAllRoles } from "../../API/RoleAPI";
import { RoleType } from "../../../dto/Role.dto";
import { showErrorToast } from "../../../app/utils/common/Toast";
import { createRankAPI, getAllRank, updateRankAPI } from "../../API/RankAPI";
import { RankModuleType } from "../../../dto/Rank.dto";
import { RankSliceStateTypes } from "../../Slices/rankSlice";

export const RankReducer = (
  builder: ActionReducerMapBuilder<RankSliceStateTypes>
) => {
  builder
    .addCase(getAllRank.pending, (state) => {
      state.loading = true;
    })
    .addCase(
        getAllRank.fulfilled,
      (state, action: PayloadAction<RankModuleType[]>) => {
        state.loading = false;
        state.allRanks = action.payload;
      }
    )
    .addCase(getAllRank.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
        state.loading = true;
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in fetching all ranks.");
      }
    })
  
    .addCase(updateRankAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      updateRankAPI.fulfilled,
      (state, action: PayloadAction<RankModuleType[]>) => {
        state.loading = false;
      }
    )
    .addCase(updateRankAPI.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
        state.loading = true;
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in creating rank.");
      }
    })
  
    .addCase(createRankAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      createRankAPI.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    )
    .addCase(createRankAPI.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
        state.loading = true;
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in creating rank.");
      }
    })

};
