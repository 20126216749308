import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { RoleSliceStateTypes } from "../../Slices/roleSlice";
import { createRoleAPI, deleteRoleAPI, getAllRoleModulesAPI, getAllRoles, updateRoleAPI } from "../../API/RoleAPI";
import { RoleModuleType, RoleType } from "../../../dto/Role.dto";
import { showErrorToast, showSuccessToast } from "../../../app/utils/common/Toast";

export const RoleReducer = (
    builder: ActionReducerMapBuilder<RoleSliceStateTypes>
) => {
    builder
        .addCase(getAllRoles.pending, (state) => {
            state.loading = true;
        })
        .addCase(
            getAllRoles.fulfilled,
            (state, action: PayloadAction<RoleType[]>) => {
                state.loading = false;
                state.allRoles = action.payload.filter((r) => r.role_id !== 1);
                state.isRoleUpdated = false;
            }
        )
        .addCase(getAllRoles.rejected, (state, action: PayloadAction<any>) => {
            if (action.payload.message) {
                showErrorToast(action.payload.message);
            } else {
                showErrorToast("Error in fetching all roles.");
            }
        })
        .addCase(getAllRoleModulesAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(
            getAllRoleModulesAPI.fulfilled,
            (state, action: PayloadAction<RoleModuleType[]>) => {
                state.loading = false;
                state.allModules = action.payload;
                state.isModulesUpdated = false;
            }
        )
        .addCase(getAllRoleModulesAPI.rejected, (state, action: PayloadAction<any>) => {
            if (action.payload.message) {
                showErrorToast(action.payload.message);
            } else {
                showErrorToast("Error in fetching all role modules.");
            }
        })
        .addCase(updateRoleAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(
            updateRoleAPI.fulfilled,
            (state, action: PayloadAction<RoleModuleType[]>) => {
                state.loading = false;
                state.isRoleUpdated = true;
                showSuccessToast("Role updated successfully.")
            }
        )
        .addCase(updateRoleAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (action.payload.message) {
                showErrorToast(action.payload.message);
            } else {
                showErrorToast("Error in role update.");
            }
        })
        .addCase(deleteRoleAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(
            deleteRoleAPI.fulfilled,
            (state, action: PayloadAction<RoleModuleType[]>) => {
                state.loading = false;
                state.isRoleUpdated = true;
                showSuccessToast("Role deleted successfully.")
            }
        )
        .addCase(deleteRoleAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (action.payload.message) {
                showErrorToast(action.payload.message);
            } else {
                showErrorToast("Error in role update.");
            }
        })
        .addCase(createRoleAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(
            createRoleAPI.fulfilled,
            (state, action: PayloadAction<RoleModuleType[]>) => {
                state.loading = false;
                state.isRoleUpdated = true;
                showSuccessToast("Role created successfully.")
            }
        )
        .addCase(createRoleAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (action.payload.message) {
                showErrorToast(action.payload.message);
            } else {
                showErrorToast("Error in role update.");
            }
        })
};
