import React from 'react'
import AllUsersTable from '../../molecules/AllUsersTable/AllUsersTable'

const AllUsers = () => {
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    All Users
                </div>
            </div>

            <div className='manager_data_table'>
                <AllUsersTable />
            </div>
        </div>
    )
}

export default AllUsers
