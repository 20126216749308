import React from 'react';
import "./LoaderScreen.scss"
import Loader from '../Loader/Loader';

interface LoaderScreenProps {
    isGray?: boolean;
}

const LoaderScreen: React.FC<LoaderScreenProps> = ({ isGray = false }) => {
    return (
        <div className='loader_screen'>
            <Loader height={300} width={300} isGray={isGray} />
        </div>
    )
}

export default LoaderScreen