import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrokerType } from "../../dto/Broker.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { BrokerReducer } from "../Reducer/Broker/BrokerReducer";
import { ManagerType } from "../../dto/Manager.dto";
import { BrokerSiteVisitType } from "../../dto/BrokerSiteVisit.dto";

export interface BrokerSliceState {
  loading: boolean;
  brokers: BrokerType[];
  pagination: PaginationType;
  refreshTable: boolean;
  currentBroker?: BrokerType;
  brokerFilter: string;
  managers?: ManagerType[];
  CPsSitePagination: PaginationType;
  CPsManagerPagination: PaginationType;
  brokerSiteVisit: BrokerSiteVisitType[];
  selectedFilter?: string;
}
const initialState: BrokerSliceState = {
  loading: false,
  brokers: [],
  brokerSiteVisit: [],
  refreshTable: false,
  brokerFilter: "",
  CPsSitePagination: {
    total_data: 0,
    current_page: 1,
    page_size: 5,
    total_pages: 1,
  },
  CPsManagerPagination: {
    total_data: 0,
    current_page: 1,
    page_size: 5,
    total_pages: 1,
  },

  pagination: {
    total_data: 0,
    current_page: 1,
    page_size: 10,
    total_pages: 1,
  },
};
const brokerSlice = createSlice({
  name: "broker",
  initialState,
  reducers: {
    setBrokerPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },

    setCurrentBroker(state, action: PayloadAction<BrokerType>) {
      // console.log("set current manager", action.payload.cp_id);
      state.currentBroker = action.payload;
    },

    setBrokerLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setBrokersManagerPagination(state, action: PayloadAction<PaginationType>) {
      state.CPsManagerPagination = action.payload;
    },
    setBrokerFilter(state, action: PayloadAction<string>) {
      state.brokerFilter = action.payload;
    },
    resetBrokerFilter(state) {
      state.brokerFilter = '';
    },
    setBrokerIncentiveLoader(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    BrokerReducer(builder);
  },
});

export const {
  setBrokerPagination,
  setCurrentBroker,
  setBrokerLoading,
  setBrokerFilter,
  resetBrokerFilter,
  setBrokerIncentiveLoader,
  setBrokersManagerPagination,
} = brokerSlice.actions;
export default brokerSlice.reducer;
