import React, { useEffect } from 'react'
import { Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import ManagerList from './ManagerList';
import BrokerList from './BrokerList';
import { getTopBrokers } from '../../../redux/API/BrokerAPI';
import { getAllManagers, getTopManagers } from '../../../redux/API/ManagerAPI';
import Loader from '../../atoms/Loader/Loader';
import { checkPermission, permissionType } from '../../../app/utils/common/checkPermission';


const TopPerformerList = () => {
  const dispatch = useAppDispatch();

  const { brokers, pagination: brokerPagination, loading: loadingBroker, } = useAppSelector((state) => state.broker);
  const { managers, pagination: managerPagination } = useAppSelector((state) => state.manager);

  //top broker api
  useEffect(() => {
    dispatch(getTopBrokers({ page: brokerPagination?.current_page }));
  }, [dispatch, brokerPagination?.current_page]);

  //top manager api



  const ManagerTabHeader = (data: { number: any }) => (
    <div className='tab_selected'>
      Managers
      <div className='active_project_number'>{data.number}</div>
    </div>
  )
  const BrokerTabHeader = (data: { number: any }) => (
    <div className='tab_selected'>
      Brokers
      <div className='completed_projects_number'>{data.number}</div>
    </div>
  )
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{
          borderBottom: '1px solid #C4C4C4', // Underline color for tabs
        }}

        items={[
          checkPermission(permissionType.MANAGER_MODULE, "read") && {
            label: <ManagerTabHeader number={managerPagination.total_data} />,
            key: '1',
            children: <ManagerList />,
          },
          checkPermission(permissionType.BROKER_MODULE, "read") && {
            label: <BrokerTabHeader number={brokerPagination.total_data} />,
            key: '2',
            children: <BrokerList />,
          },
        ].filter(Boolean) as { key: string; label: React.ReactNode }[]}
      />
    </div>

  )
}

export default TopPerformerList;