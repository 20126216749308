import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import { VisitSliceState } from "../../Slices/visitManagementSlice";
import {
  getSiteVisitDetailsAPI,
  getSiteVisitesAPI,
  updateSaleStatusAPI,
} from "../../API/VisitManagementAPI";
import { showErrorToast } from "../../../app/utils/common/Toast";
import {
  VisitManagementDetails,
  visitManagementType,
} from "../../../dto/VisitManagement.dto";
import { PaginationType } from "../../../dto/Pagination.dto";
const VisitManagementReducer = (
  builder: ActionReducerMapBuilder<VisitSliceState>
) => {
  builder
    .addCase(getSiteVisitesAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getSiteVisitesAPI.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload) {
          // Append new data only for subsequent pages
          if (state.pagination.current_page === 1) {
            state.scheduledData = action.payload.scheduled_Data;
            state.visitedData = action.payload.visited_Data;
          } else {
            state.scheduledData = [
              ...(state.scheduledData),
              ...(action.payload.scheduled_Data),
            ];
            state.visitedData = [
              ...(state.visitedData),
              ...(action.payload.visited_Data),
            ];
          }
    
          // Merge new pagination data without resetting current_page
          state.pagination = {
            ...state.pagination,
            ...action.payload.pagination,
          };
    
          state.loading = false;
        }
      }
    )
        .addCase(
      getSiteVisitesAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
          state.loading = false;
        } else {
          showErrorToast("Error in getting site visits!");
        }
      }
    )

    .addCase(getSiteVisitDetailsAPI.pending, (state) => {
      state.loading = true;
    })

    .addCase(
      getSiteVisitDetailsAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          scheduled_visit_data: VisitManagementDetails[];
          visited_visit_data: VisitManagementDetails[];
          pagination: PaginationType;
          visit_type: string;
        }>
      ) => {
        state.loading = false;
        state.scheduledDetailsData = action.payload.scheduled_visit_data; // Store scheduled visit data
        state.visitedDetailsData = action.payload.visited_visit_data;
        state.pagination = action.payload.pagination; // Store pagination data
        state.visitActiveTab = action.payload.visit_type;
      }
    )
    .addCase(
      getSiteVisitDetailsAPI.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.message) {
          showErrorToast(action.payload.message);
          state.loading = false;
        } else {
          showErrorToast("Error in getting site visit details!");
        }
      }
  )
  .addCase(updateSaleStatusAPI.pending, (state) => {
    state.loading = true;
  })
  .addCase(updateSaleStatusAPI.fulfilled, (state, action: PayloadAction<any>) => {
    console.log("action.payload :>> ", action.payload);

    state.loading = false;
  })
  .addCase(updateSaleStatusAPI.rejected, (state, action: PayloadAction<any>) => {
    if (action.payload.message) {
      showErrorToast(action.payload.message);
      state.loading = false;
    } else {
      showErrorToast("Error in update site status.");
    }
  })
};

export default VisitManagementReducer;
