import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { image } from '../../../../app/utils/common/image';
import ProjectsTabs from '../../../molecules/SiteManagement/ProjectsTab';
import VisitTab from '../../../molecules/VisitManagement/VisitTab';

const VisitManagement = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className='site_management_page_container'>
                <div className='site_management_header'>
                    <div className='header_text'>
                        Visit Management
                    </div>
                    {/* <div className='button_container'>
                        <div className='btn'>
                            <img className='btn_icon' src={image.FILTER_ICON} />
                            <div>
                                Filter
                            </div>
                        </div>
                        <div className='btn' onClick={() => navigate("create")}>
                            <img className='btn_icon' src={image.ADD_ICON} />
                            <div>
                                Add
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='project_list_container'>
                    <VisitTab />
                </div>
            </div>
        </div>
    )
}

export default VisitManagement
