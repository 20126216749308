import React, { FC, useRef, useState } from 'react'
import { image } from '../../../../../app/utils/common/image'
import { getImageURL } from '../../../../../app/utils/common/getImageUrl';
import { useAppDispatch } from '../../../../../app/utils/hooks/reduxHook';
import { confirmModalProps } from '../../../../../dto/confirm';
import { setManagerPagination, setManagersCPsPagination } from '../../../../../redux/Slices/managerSlice';
import AddButton from '../../../../atoms/CustomButton/AddButton/AddButton'
import ActionIcon from '../../../../atoms/ActionIcon/ActionIcon';
import ErrorText from '../../../../atoms/Error/error';
import CustomRadioButton from '../../../../atoms/RadioButton/Radio';
import Loader from '../../../../atoms/Loader/Loader';
import EmptyPage from '../../../../atoms/EmptyPage/EmptyPage';
import OptionMenu from '../../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../../atoms/confirmation-modal/confirmation-modal';
import './GroupMBComponent.scss';
import { setavailableManagerPagination } from '../../../../../redux/Slices/groupSlice';


const ActionButtonContainer: React.FC<{ manager: any, handleRemoveManager: (id: number) => void }> = ({ manager, handleRemoveManager }) => {

    const [isMenuOpen, seIsMenuOpen] = useState<boolean>(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const options = [{
        name: "Remove",
        onClick: () => {
            setRemoveModal(true);
        }
    }

    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this manager?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { handleRemoveManager(manager.manager_id); setRemoveModal(false); },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.ADD_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-147px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}
interface GroupMBComponentProps {
    groupLoading: boolean,
    loadingManager: boolean,
    type: any;
    showError: boolean;
    setShowManagerTable: React.Dispatch<React.SetStateAction<boolean>>
    currentManager: number | undefined,
    setCurrentManager: React.Dispatch<React.SetStateAction<number | undefined>>;
    ManagersCPs: any,
    loadingBroker: boolean,
    handleRemoveManager: (managerId: number) => void,
    removedManagerIds: number[]
    managerToDisplay: any,
    ManagersCPsPagination: any,
    pagination: any
}

const GroupMBComponent: FC<GroupMBComponentProps> = (props) => {
    const { groupLoading, loadingManager, type, showError = false, setShowManagerTable, currentManager, setCurrentManager, ManagersCPs, loadingBroker, handleRemoveManager, removedManagerIds, managerToDisplay, ManagersCPsPagination, pagination } = props;
    const loadingState = type === 'create' ? loadingManager : groupLoading;
    const dispatch = useAppDispatch();

    const handleRadioChange = (managerId: any) => {
        setCurrentManager(managerId)
    }
    const scrollRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loadingBroker) {
                if (ManagersCPsPagination.current_page < ManagersCPsPagination.total_pages)
                    dispatch(setManagersCPsPagination({ ...ManagersCPsPagination, current_page: ManagersCPsPagination.current_page + 1 }))
            }
        }
    };

    const handleScrollGroupManager = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loadingState) {
                if (pagination.current_page < pagination.total_pages)
                    dispatch(setavailableManagerPagination({ ...pagination, current_page: pagination.current_page + 1 }))
            }
        }
    };

    return (
        <div className='group-main-manager-container'>
            <div className='group-manager-container'>
                <div className='manager-header-flex'>
                    <div className='group-manager-heading'>Managers</div>
                    {type !== 'details' && <AddButton onclick={() => { setShowManagerTable(true) }} plusIcon={true} buttonClass='add_btn' />}
                </div>

                {!loadingState ? (
                    <div className="profile_data" onScroll={handleScrollGroupManager} ref={scrollRef}>
                        {showError && managerToDisplay.length === 0 && < ErrorText message='Please select manager' />}
                        {managerToDisplay?.length > 0 ? (
                            managerToDisplay.map((manager: any) => (
                                !removedManagerIds.includes(manager.manager_id) && <div key={manager.manager_id} className="profile">
                                    <CustomRadioButton
                                        onClick={() => {
                                            handleRadioChange(manager.manager_id)
                                        }}
                                        checked={currentManager === manager.manager_id}
                                    />

                                    <div className="profile_image_container">
                                        <img
                                            src={
                                                manager.profile_image
                                                    ? getImageURL(manager.profile_image)
                                                    : image.DEFAULT_PROFILE_IMAGE
                                            }
                                            className="profile_image"
                                            alt="Profile"
                                        />
                                    </div>
                                    <div className="data_container">
                                        <div className="profile_data_container">
                                            <div className="profile_name">{manager.name}</div>
                                        </div>
                                        <div className="profile-end-flex">
                                            <div className="profile_project_container">
                                                <div className="project_text">
                                                    {manager.country_code} {manager.phone_number}
                                                </div>
                                            </div>
                                            {type !== 'details' && <ActionButtonContainer manager={manager} handleRemoveManager={handleRemoveManager} />}
                                        </div>
                                    </div>

                                </div>
                            ))
                        ) : (
                            <div className="EmptyPageSite_container">
                                <EmptyPage
                                    image={image.NO_MANAGER_ADDED_YET_IMAGE}
                                    text="No managers found."
                                />
                            </div>
                        )}

                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Loader />
                    </div>
                )}
            </div>

            {/* Brokers */}
            <div className='group-manager-container'>
                <div className='manager-header-flex'>
                    <div className='group-manager-heading'>Brokers</div>
                </div>
                {!loadingBroker ? (
                    <div className="profile_data" onScroll={handleScroll} ref={scrollRef}>
                        {ManagersCPs.cps.length > 0 ? (
                            ManagersCPs.cps.map((cps: any) => (
                                cps ? (
                                    <div className="profile" key={cps.id}>
                                        <div className="profile_image_container">
                                            <img src={cps.profile_image ? getImageURL(cps.profile_image) : image.DEFAULT_PROFILE_IMAGE} alt="Profile" className="profile_image" />
                                        </div>
                                        <div className="data_container">
                                            <div className="profile_data_container">
                                                <div className="profile_name">{cps.name}</div>
                                                {/* <div className="profile_contact">{cps.email}</div> */}
                                            </div>
                                            <div className="profile-end-flex">
                                                <div className="profile_project_container">
                                                    <div className="project_text">
                                                        {cps.country_code} {cps.phone_number}
                                                    </div>
                                                </div>
                                                {/* <ActionIcon /> */}
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            ))
                        ) : (
                            <div className='EmptyPageSite_container'>
                                <EmptyPage image={image.NO_MANAGER_ADDED_YET_IMAGE} text='No Any Broker added yet!' />
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    )
}

export default GroupMBComponent