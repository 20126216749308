import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

export const getAllUsersAPI = createAsyncThunk(
  "getAllUsers/getAllUsersAPI",
  async (
    {
      page,
      total_page = 10,
      role_id,
      searchText = "",
    }: {
      page: number;
      total_page?: number;
      role_id: number;
      searchText?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_ALL_USERS}/?page=${page}&searchText=${searchText ?? ""}&limit=${total_page}&role_id=${role_id}`
      );
      return {
        user_data: response.data.data.user_data,
        pagination: response.data.data.pagination,
        role_id: role_id,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all users");
    }
  }
);
