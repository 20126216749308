import React from 'react';
import "./EmptyPage.scss";

interface EmptyPageProps {
  image: any;
  text: string;
  className?: string;
}
const EmptyPage: React.FC<EmptyPageProps> = (props) => {
  const { image, text } = props;
  return (
    <div className='EmptyPage_main'>
      <img className='EmptyPage_Image' src={image} alt="" />
      <p className='EmptyPage_text'>{text}</p>
    </div>
  )
}

export default EmptyPage
