import React, { useState } from 'react'
import Button from '../../../atoms/Button/Button'
import "./RoleManagement.scss"
import { useNavigate } from 'react-router-dom'
import { checkPermission } from '../../../../app/utils/common/checkPermission'
import { image } from '../../../../app/utils/common/image'
import AllRolesTable from './AllRolesTable'
import RolesPermissionModal from '../../../atoms/RolesPermissionModal/RolesPermissionModal'

const AllRoles: React.FC = () => {

    const navigate = useNavigate();
    const [createRoleModal, setCreateRoleModal] = useState(false);
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Manage Roles
                </div>
                <div className='button_container'>
                    {/* <div className='btn'>
                            <img className='btn_icon' src={image.FILTER_ICON} />
                            <div>
                                Filter
                            </div>
                        </div> */}
                    <div className='btn' onClick={() => { setCreateRoleModal(true) }}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>

            <div className='manager_data_table'>
                <AllRolesTable />
            </div>
            {createRoleModal && <RolesPermissionModal isEdit={false} setOpenPermissionModal={setCreateRoleModal} />}
        </div >
    )
}

export default AllRoles
