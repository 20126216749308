export const ApiConstants = {
  SENDOTP: "/api/auth/send-otp",
  LOGIN: "/api/auth/login",
  RESENDOTP: "/api/auth/resend-otp",
  REFRESHTOKEN: "/api/auth/refresh-access-token",
  REGISTER_OTP: "/api/auth/register/sendotp",
  LOGOUT: "/api/auth/logout",
  USER_PROFILE: "/api/profile",
  UPLOAD_FILE: "/api/file/upload",
  GETALL_PROJECT: "/api/admin/project/all",
  UPDATE_USER_DATA: "/api/profile/info",
  GET_ALL_MANAGERS: "/api/admin/manager/all",
  GET_TOP_MANAGERS: "/api/admin/manager/top-managers",
  CREATE_MANAGER: "/api/admin/manager/create",
  UPDATE_MANAGER: "/api/admin/manager",
  MANAGE_MANAGER_COMMON: "/api/admin/manager",
  GET_ALL_BROKERS: "/api/admin/channel-partner/all",
  GET_TOP_BROKERS: "/api/admin/channel-partner/top-cps",
  GET_ALL_GROUPS: "/api/admin/group/all",
  CREATE_GROUP: "/api/admin/group/create",
  UPDATE_GROUP: "/api/admin/group",
  CREATE_BROKER: "/api/admin/channel-partner/create",
  UPDATE_BROKER: "/api/admin/channel-partner",
  DELETE_BROKER: "/api/admin/channel-partner",
  GET_CPs_MANAGER: "/api/admin/channel-partner",
  DEALLOCATE_CPS_FROM_MANAGER: "/api/admin/manager/deallocate/cps",
  // GET_CPs_MANAGER:'/api/admin/channel-partner/:cpId/managers'
  GET_CPs_SITE_VISITS: "/api/admin/channel-partner",
  GET_AVAILABLE_CPS_FOR_MANAGER: "/api/admin/channel-partner/available-cps",
  ALLOCATE_CPS: "/api/admin/manager/allocate/cps",
  GET_AVAILABLE_MANAGERS: "/api/admin/manager/available-managers",
  GET_SINGLE_PROJECT: "/api/admin/project/:projectId/info",
  UPDATE_SINGLE_PROJECT: "/api/admin/project/:projectId/update",
  GET_PROJECT_GROUPS: "/api/admin/project/:projectId/groups",
  GET_PROJECT_MANAGERS: "/api/admin/project/:projectId/managers",
  POST_REQUEST: "/api/admin/post/requests",
  // VERIFY_POST: "/api/admin/post/:postId/verify"
  VERIFY_POST: "/api/admin/post",
  DELETE_POST: "/api/admin/post",
  CREATE_PROJECT: "/api/admin/project/create",
  DELETE_FILE: "/api/file/delete",
  GET_SITE_VISITS: "/api/admin/site-visits",
  GET_SITE_VISIT_DETAILS: "/api/admin/site-visits/project",
  UPDATE_SITE: "/api/admin/project/:projectId/update",
  GET_REGISTRATION_REQUEST: "/api/admin/user/requests",
  VERIFY_REGISTRATION_REQ: "/api/admin/user",
  APPROVE_ALL_REGISTRATION_REQ: "/api/admin/user/approve/all",
  DONE_VISIT: "/api/admin/site-visits/:visitId/complete",
  MARK_AS_COMPLETE_VISIT: "/api/admin/site-visits/:visitId/mark-complete",
  SEND_NOTIFICATION: "/api/notification/send",
  GET_ALL_USERS: "/api/admin/user/all",
  STORE_DEVICE_TOKEN: "/api/auth/store-device-token",
  SEEN_NOTIFCATIONS: "/api/notification/seen",
  UNSEEN_NOTIFICATIONS: "/api/notification/unseen",
  MAKE_UNSEEN_NOTIFICATION_SEEN: "/api/notification/seen",
  GET_ROLE_MODULES: "/api/admin/role/modules",
  GET_ALL_ROLES: "/api/admin/role/all",
  UPDATE_ROLE_API: "/api/admin/role/:roleId/update",
  DELETE_ROLE_API: "/api/admin/role/:roleId/delete",
  CREATE_ROLE_API: "/api/admin/role/create",
  GET_ALL_RANKS: "/api/admin/rank/all",
  UPDATE_RANK_API: "/api/admin/rank/:rankId/update",
  CREATE_RANK_API: "/api/admin/rank/create",
  DELETE_RANK_API: "/api/admin/rank",
  CREATE_POST_API: "/api/post/create",
  UPDATE_SALE_STATUS: "/api/admin/site-visits/:visitId/update/sale",
  UPDATE_INCENTIVES: "/api/admin/incentive",
};
