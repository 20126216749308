import React, { useEffect } from 'react'
import ScheduledVisitTable from '../VisitTables/ScheduledVisitTable'
import { image } from '../../../../app/utils/common/image'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook'
import { getSiteVisitDetailsAPI } from '../../../../redux/API/VisitManagementAPI'
import VisitedVisitTable from '../VisitTables/VisitedVisitTable'

const SingleVisitDetails: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    const { visitedDetailsData, loading, visitActiveTab } = useAppSelector(state => state.vistManagement)

    useEffect(() => {
        if (id && visitActiveTab) {
            dispatch(getSiteVisitDetailsAPI({ project_id: id, visit_type: visitActiveTab, page: 1 }));
        }
    }, [id, visitActiveTab]);
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='single_site'>
                    <div className='sitename-header'>
                        <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                            navigate(-1);
                        }} />
                        <div className='header_text'><span style={{ color: "#5f9937", marginRight: "20px" }}>Visit Management </span> Visit Management Details </div>
                    </div>
                </div>
                {/* <div className='button_container'> */}
                {/* <div className='btn'>
                        <img className='btn_icon' src={image.FILTER_ICON} />
                        <div>
                            Filter
                        </div>
                    </div> */}
                {/* <div className='btn' onClick={() => navigate("manager/create")}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Add
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
            {visitedDetailsData?.length > 0 ? <VisitedVisitTable /> : <ScheduledVisitTable />}
        </div >
    )
}

export default SingleVisitDetails
