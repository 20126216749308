import React, { FC, useEffect, useState } from 'react';
import type { TableColumnsType } from 'antd';
import { image } from '../../../../app/utils/common/image';
import Button from '../../../atoms/Button/Button';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import { useNavigate } from 'react-router-dom';
import { confirmModalProps } from '../../../../dto/confirm';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { deleteBrokerAPI, getAllBrokers, upadateIncentiveAPI } from '../../../../redux/API/BrokerAPI';
import { BrokerType } from '../../../../dto/Broker.dto';
import { setBrokerIncentiveLoader, setBrokerPagination, setCurrentBroker } from '../../../../redux/Slices/brokerSlice';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import { checkPermission, permissionType } from '../../../../app/utils/common/checkPermission';
import Modal from '../../../atoms/Modal/Modal';
import { showErrorToast, showSuccessToast } from '../../../../app/utils/common/Toast';
import ErrorText from '../../../atoms/Error/error';

interface DataType {
    cp_id: number;
    key: React.Key;
    profile_image: string;
    name: string;
    phone_number: number;
    email: string;
    site_visits: number;
    referral_code: string;
    refer_by: string;
    referrer_name: string;
    referrals: number;
    referrer_country_code: string;
    referrer_phone_number: string;
    incentive: number;
}

const ActionButtonContainer: React.FC<{ Broker: any, pagination: any }> = ({ Broker, pagination }) => {
    const navigate = useNavigate()
    const { currentBroker, loading } = useAppSelector(state => state.broker)
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const [incentiveModal, setIncentiveModal] = useState<boolean>();
    const [incentiveValue, setIncentiveValue] = useState<string | undefined>();
    const [invalidIncentive, setInvalidIncentive] = useState<boolean>();

    const dispatch = useAppDispatch();
    const options = [
        checkPermission(permissionType.BROKER_MODULE, 'update') && {
            name: "Edit",
            onClick: () => {
                dispatch(setCurrentBroker(Broker));
                navigate("broker/edit");
            }
        },
        checkPermission(permissionType.BROKER_MODULE, 'delete') &&
        {
            name: "Remove",
            onClick: () => {
                dispatch(setCurrentBroker(Broker));
                setRemoveModal(true);
            }
        },
        checkPermission(permissionType.INCENTIVE_MODULE, 'update') &&
        {
            name: "Release Incentive",
            onClick: () => {
                dispatch(setCurrentBroker(Broker));
                setIncentiveModal(true);
            }
        }
    ].filter(Boolean) as { name: string; onClick: () => void }[]; // Explicitly cast to the correct type

    useEffect(() => {
        setIncentiveValue(Broker.incentive);
    }, [Broker.incentive])

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this broker?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: async () => {
            let cpIdToDelete: any = {}
            cpIdToDelete = currentBroker?.cp_id;

            if (cpIdToDelete) {
                try {
                    setRemoveModal(false);
                    await dispatch(deleteBrokerAPI({ cpId: cpIdToDelete }));

                    dispatch(getAllBrokers({ page: pagination.current_page }));
                } catch (error) {
                    console.error("Failed to delete broker:", error);
                    setRemoveModal(false);
                }
            }
        },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_USER_ICON
    };

    const handleUpdateIncentive = () => {
        dispatch(setBrokerIncentiveLoader(true));
        if (currentBroker?.cp_id && incentiveValue) {
            const incentive = Number(incentiveValue);
            if (incentive > 0 && incentive <= Broker.incentive) {        
                setIncentiveModal(false);
                dispatch(upadateIncentiveAPI({incentive : incentive , userId: currentBroker?.cp_id})).unwrap()
                .then((result) => {
                    dispatch(setBrokerIncentiveLoader(false));
                    dispatch(getAllBrokers({ page: 1 }));
                    showSuccessToast("Incentive updated successfully");
                })
                .catch((error) => {
                    showErrorToast("Error updating incentive")
                    dispatch(setBrokerIncentiveLoader(false));
                });
            } else {
                setInvalidIncentive(true);
                dispatch(setBrokerIncentiveLoader(false));
            }
        }
    }
    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}
            {incentiveModal && 
             <Modal>
                <div className='add_incentive_modal'>
                <div className='center_div incentive_content'>
                    <div className='text'>Release Incentive Points</div>
                    <div className='input_container'>
                        <input type="number" value={incentiveValue} onChange={(e) => {
                            setIncentiveValue(e.target.value);
                            const incentive = Number(incentiveValue);
                            if (incentive > 0 && incentive <= Broker.incentive) {
                                    setInvalidIncentive(false);
                            }               
                            }} />
                            </div>
                            {invalidIncentive && <ErrorText message= "Please enter valid incentive"/> } 
                    <div className='btn_container'>
                    <button className='cancel_btn' onClick={(e) => setIncentiveModal(false)}>
                        Cancel
                    </button>
                    <button className='submit_btn' onClick={() => handleUpdateIncentive()}>
                        Submit
                    </button>
                    </div>
                </div>
                </div>
           </Modal>
            }
        </div>
    )
}

const ManageBrokerTable: FC = () => {
    const [brokerData, setBrokerData] = useState<BrokerType[]>([])
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { brokers, loading, pagination, selectedFilter } = useAppSelector(state => state.broker);
    const { searchText } = useAppSelector(state => state.search);


    useEffect(() => {
        if (!loading)
            dispatch(getAllBrokers({ page: pagination.current_page, sortBy: selectedFilter, searchText }))
    }, [pagination.current_page])

    useEffect(() => {
        setBrokerData(brokers)
    }, [brokers])

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Group Photo',
            dataIndex: 'Photo',
            render: (text, record) => {
                return (
                    <img
                        src={record.profile_image ? getImageURL(record.profile_image) : image.DEFAULT_PROFILE_IMAGE}
                        alt="Profile"
                        onError={(e: any) => e.target.src = image.DEFAULT_PROFILE_IMAGE}
                        className='reg-profile'
                    />
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => (
                // currentBroker
                // onClick={() => navigate(`/broker/${record.cp_id}`)}  // Navigate to the details page

                <div onClick={() => navigate(`${record.cp_id}`)} style={{ cursor: "pointer" }}>
                    {record.name}
                </div>
            )
        },
        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     render: (text, record) => (
        //         <div>
        //             {record.email}
        //         </div>
        //     )
        // },

        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            render: (text, record) => (
                <div onClick={() => navigate(`${record.cp_id}`)} style={{ cursor: "pointer" }}>
                    {record.phone_number}
                </div>
            )
        },
        {
            title: "Incentive",
            dataIndex: "incentive"
        },
        {
            title: 'Referral Code',
            dataIndex: 'referral_code',
            render: (text, record) => (
                <div>{record.referral_code}</div>
            )
        },
        {
            title: 'Referrals',
            dataIndex: 'referrals',
            render: (text, record) => (
                <div>{record.referrals}</div>
            )
        },
        {
            title: 'Sites Visited',
            dataIndex: 'sitesVisited',
            render: (text, record) => (
                <div onClick={() => navigate(`${record.cp_id}`)} style={{ cursor: "pointer" }}>
                    {record.site_visits}
                </div>
            )
        },

        {
            title: 'Referraled By',
            dataIndex: 'referrer_name',
            render: (text, record) => (
                <div>{record.referrer_name}</div>
            )
        },

        {
            title: 'Referrer Phone Pumber',
            dataIndex: 'referrer_phone_number',
            render: (text, record) => (
                <div>{record.referrer_phone_number}</div>
            )
        },

        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <ActionButtonContainer Broker={record} pagination={pagination} />
            ),
        },
    ];

    const handlePageChange = (pageNumber: number) => {
        if (!loading) {
            let tempData = { ...pagination };
            tempData.current_page = pageNumber
            dispatch(setBrokerPagination(tempData));
        }
    };

    return (
        <div className='registration_dataTable'>
            <TableComponent columns={columns}
                dataSource={brokerData}
                loading={loading}
                pageNumbers={Array.from({ length: pagination.total_pages }, (_, index) => index + 1)}
                handlePageLink={handlePageChange}
                isPagination={pagination.total_pages > 1}
                totalProfile={pagination.total_data}
                prevPage={() => handlePageChange(pagination.current_page - 1)}
                nextPage={() => handlePageChange(pagination.current_page + 1)}
                activePage={pagination.current_page}
                currentTotal={Math.min(pagination.current_page * pagination.page_size, brokerData.length)} />
        </div>
    );
};

export default ManageBrokerTable;