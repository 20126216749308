import React, { useState } from 'react'
import RegistrationReqTable from '../../molecules/RegistrationReqTable/RegistrationReqTable';
import './RegistrationRequest.scss'
import ConfirmModal from '../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../dto/confirm';
import { image } from '../../../app/utils/common/image';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { approveAllRegistrationReqAPI, getRegistrationRequestsAPI } from '../../../redux/API/RegistrationRequestAPI';
import { showSuccessToast } from '../../../app/utils/common/Toast';
import Button from "../../atoms/Button/Button";

const RegistrationRequest = () => {

    const [openApproveAllModal, setOpenApproveAllModal] = useState(false);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const { pagination, requests } = useAppSelector((state) => state.registrationRequest);
    const acceptModalProps: confirmModalProps = {
        text: "Are you sure you want to approve all users?",
        confirmModal: openApproveAllModal,
        setConfirmModal: setOpenApproveAllModal,
        buttons: ["Yes, Approve All", "Cancel"],
        onPressPositive: () => { handleConfirmationAcceptAllClick(); },
        onPressNegative: () => { setOpenApproveAllModal(false); },
        image: image.ADD_ICON,
    };
    const handleApproveAll = () => {
        setOpenApproveAllModal(true);
    }
    const handleConfirmationAcceptAllClick = () => {
        setLoading(true);
        setOpenApproveAllModal(false);
        dispatch(approveAllRegistrationReqAPI()).unwrap().then(() => {
            showSuccessToast("All registration requests accepted successfully!");
            dispatch(getRegistrationRequestsAPI({ page: pagination.current_page, registration_req_Status: "pending", })).unwrap().then(() => {
                setLoading(false);
            }).finally(() => {
                // setLoading(false);
            });
        }).catch((error) => {
        })
    }
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Registration Request
                </div>
                {/* <button className='select_approve_all_button' onClick={handleApproveAll}>
                       Approve All
                </button> */}
                {requests.length > 0 &&
                    <Button
                        buttonTitle="Approve All"
                        onClick={handleApproveAll}
                        className="RejectButton AcceptButton"
                    />
                }
            </div>

            <div className='manager_data_table'>
                <RegistrationReqTable />
            </div>
            {openApproveAllModal && <ConfirmModal confirmModalProps={acceptModalProps} />}
        </div>
    )
}

export default RegistrationRequest