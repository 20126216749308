import React from 'react'
import { image } from '../../../../app/utils/common/image'
import { useNavigate } from 'react-router-dom'
import RankTable from './RankTable'

const RankManagement = () => {
    const navigate = useNavigate()
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Manage Rank
                </div>
                <div className='button_container'>
                    <div className='btn' onClick={() => { navigate('rank/create') }}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>

            <div className='manager_data_table'>
                {/* <AllRolesTable /> */}
                <RankTable/>
            </div>
        </div >
    )
}

export default RankManagement
