import React, { FC, useEffect, useState } from 'react'
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import "./GroupTableForSingleSite.scss"
import { image } from '../../../../app/utils/common/image';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { confirmModalProps } from '../../../../dto/confirm';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import { PaginationType } from '../../../../dto/Pagination.dto';
import { MAX_TABLE_DATA_PER_PAGE } from '../../../../app/utils/apis/Constant';
import { ProjectGroupType } from '../../../../dto/Projects.dto';
import { getProjectGroupsAPI, updateSingleSiteAPI } from '../../../../redux/API/ProjectAPI';
import { setProjectGroupPagination } from '../../../../redux/Slices/projectSlice';
import { showSuccessToast } from '../../../../app/utils/common/Toast';


const ActionButtonContainer: FC<{ Group: ProjectGroupType, handleRemoveGroup: (id: number) => void }> = ({ Group, handleRemoveGroup }) => {
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const options = [
        {
            name: "Remove",
            onClick: () => {
                setRemoveModal(true)
            }
        }
    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this group from site?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => {
            handleRemoveGroup(Group.group_id);
            setRemoveModal(false);
        },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_GROUP_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}
const GroupTableForSingleSite: React.FC = () => {
    const navigate = useNavigate();
    const { SingleProject, SingleProjectGroups, SingleProjectGroupsPagination } = useAppSelector(state => state.project);
    const [loading, setLoading] = useState(false);
    const [isRefreshTable, setIsRefreshTable] = useState(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (SingleProject) {
            setLoading(true);
            dispatch(getProjectGroupsAPI({ project_id: SingleProject.project_id, current_page: 1 })).unwrap().then(() => { }).catch(() => { }).finally(() => { setLoading(false) });
        }
    }, [SingleProjectGroupsPagination.current_page]);

    useEffect(() => {
        if (isRefreshTable && SingleProject) {
            setLoading(true);
            dispatch(getProjectGroupsAPI({ project_id: SingleProject.project_id, current_page: 1 })).unwrap().then(() => { }).catch(() => { }).finally(() => { setLoading(false); setIsRefreshTable(false) });
        }
    }, [isRefreshTable]);

    const handleRemoveGroup = (id: number) => {
        if (SingleProject) {
            setLoading(true);
            dispatch(updateSingleSiteAPI({ delete_group_ids: [id], project_id: SingleProject?.project_id })).unwrap()
                .then(() => {
                    showSuccessToast("Group removed successfully.");
                    setIsRefreshTable(true);
                }).catch(() => {
                    setLoading(false);
                })
        }
    }

    const columns: TableColumnsType<ProjectGroupType> = [
        {
            title: 'Group Photo',
            dataIndex: 'Photo',
            render: (text, record) => {
                return (
                    <img
                        src={record.photo ? getImageURL(record.photo) : image.DEFAULT_PROFILE_IMAGE}
                        alt="Profile"
                        className='reg-profile'
                    />
                )
            },
        },
        {
            title: 'Group Name',
            dataIndex: 'name',
            render: (text, record) => (
                <div >
                    {record.name}
                </div>
            )
        },
        {
            title: 'Managers',
            dataIndex: 'managers',
            render: (text, record) => {
                const MAX_VISIBLE_IMAGES = 4; // Limit of visible images
                return (
                    <div className='group_list_container'>
                        {record.manager_profiles.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: any, index: number) => (
                            <img key={index} src={imgSrc ? getImageURL(imgSrc) : image.DEFAULT_PROFILE_IMAGE} alt={`Member ${index + 1}`} />
                        ))}
                        {record.manager_profiles?.length > MAX_VISIBLE_IMAGES && (
                            <div className="total_members_class">
                                +{record.manager_profiles.length - MAX_VISIBLE_IMAGES}
                            </div>
                        )}
                    </div>
                );
            },
        },
        // {
        //     title: 'Brokers',
        //     dataIndex: 'brokers',
        //     render: (text, record) => {
        //         const MAX_VISIBLE_IMAGES = 4;
        //         return (
        //             <div className='group_list_container'>
        //                 {record.cp_profiles?.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: any, index: number) => (
        //                     <img key={index} src={imgSrc ? getImageURL(imgSrc) : image.DEFAULT_PROFILE_IMAGE} alt={`Cp ${index + 1}`} />
        //                 ))}
        //                 {record.cp_profiles?.length > MAX_VISIBLE_IMAGES && (
        //                     <div className="total_members_class">
        //                         +{record.cp_profiles.length - MAX_VISIBLE_IMAGES}
        //                     </div>
        //                 )}
        //             </div>
        //         );
        //     },
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <ActionButtonContainer Group={record} handleRemoveGroup={handleRemoveGroup} />
            ),
        },
    ];





    const handlePageChange = (pageNumber: number) => {
        // setCurrentPage(pageNumber);
        setProjectGroupPagination({ ...SingleProjectGroupsPagination, current_page: pageNumber });
    };
    return (
        <div className='registration_dataTable'>

            <TableComponent columns={columns}
                dataSource={SingleProjectGroups}
                loading={loading}
                pageNumbers={Array.from({ length: SingleProjectGroupsPagination?.total_pages }, (_, index) => index + 1)}
                handlePageLink={handlePageChange}
                isPagination={SingleProjectGroupsPagination?.total_pages > 1}
                totalProfile={SingleProjectGroupsPagination?.total_data}
                prevPage={() => handlePageChange(SingleProjectGroupsPagination?.current_page - 1)}
                nextPage={() => handlePageChange(SingleProjectGroupsPagination?.current_page)}
                activePage={SingleProjectGroupsPagination?.current_page}
                currentTotal={Math.min(SingleProjectGroupsPagination?.current_page * SingleProjectGroupsPagination?.page_size, (SingleProjectGroups ?? []).length)} />


        </div>
    )


}

export default GroupTableForSingleSite
