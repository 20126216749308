import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { BrokerSliceState } from "../../Slices/brokerSlice";
import {
  createBrokerAPI,
  deleteBrokerAPI,
  getAllBrokers,
  getCPsManagerAPI,
  getCPsSiteVisitAPI,
  getTopBrokers,
  updateBrokerAPI,
} from "../../API/BrokerAPI";
import { PaginationType } from "../../../dto/Pagination.dto";
import { BrokerType } from "../../../dto/Broker.dto";
import { showErrorToast, showSuccessToast } from "../../../app/utils/common/Toast";
import { ManagerType } from "../../../dto/Manager.dto";
import { BrokerSiteVisitType } from "../../../dto/BrokerSiteVisit.dto";

export const BrokerReducer = (
  builder: ActionReducerMapBuilder<BrokerSliceState>
) => {
  builder
    //get brokers
    .addCase(getAllBrokers.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getAllBrokers.fulfilled,
      (
        state,
        action: PayloadAction<{
          cp_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        if (action.payload) {


          state.loading = false;

          if (
            state.pagination.current_page ===
            action.payload.pagination.current_page
          ) {
            state.pagination = action.payload.pagination;
            state.brokers = action.payload.cp_data;
          }
        }
      }
    )
    .addCase(getAllBrokers.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })
    .addCase(getTopBrokers.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getTopBrokers.fulfilled,
      (
        state,
        action: PayloadAction<{
          top_cps: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        if (
          state.pagination.current_page ===
          action.payload.pagination.current_page
        ) {
          state.pagination = action.payload.pagination;
          if (action.payload.pagination.current_page === 1) {
            state.brokers = action.payload.top_cps;
          } else {
            state.brokers = [...state.brokers, ...action.payload.top_cps];
          }
          state.loading = false;
        }
      }
    )
    .addCase(getTopBrokers.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    .addCase(createBrokerAPI.pending, (state) => {
      state.loading = true;
    })

    .addCase(
      createBrokerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          broker_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        state.brokers = [];
      }
    )
    .addCase(createBrokerAPI.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in broker creation.");
      }
    })

    //update
    .addCase(updateBrokerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      updateBrokerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          broker_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        state.brokers = [];
      }
    )
    .addCase(updateBrokerAPI.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    //delete
    .addCase(deleteBrokerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      deleteBrokerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          broker_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        state.brokers = [];
        showSuccessToast("Broker deleted successfully.")
      }
    )

    .addCase(deleteBrokerAPI.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    // get site visits for a specific broker (cpId)
    .addCase(getCPsSiteVisitAPI.pending, (state) => { })
    .addCase(
      getCPsSiteVisitAPI.fulfilled,
      (state, action: PayloadAction<BrokerSiteVisitType[]>) => {
        // console.log("action.payload :>> ", action.payload);

        state.brokerSiteVisit = action.payload;
      }
    )
    .addCase(
      getCPsSiteVisitAPI.rejected,
      (state, action: PayloadAction<any>) => {
        showErrorToast("Failed to load managers.");
      }
    );
};
