import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { ProjectState } from "../../Slices/projectSlice";
import {
  getAllActiveProjects,
  getProjectGroupsAPI,
  getProjectManagerAPI,
  getSingleProjectAPI,
  updateSingleSiteAPI,
} from "../../API/ProjectAPI";
import {
  ProjectGroupType,
  ProjectListType,
  SingleProjectType,
  UpdateProjectPayloadType,
} from "../../../dto/Projects.dto";
import { showErrorToast } from "../../../app/utils/common/Toast";
import { PaginationType } from "../../../dto/Pagination.dto";
import { ManagerType } from "../../../dto/Manager.dto";

interface ProjectResponse {
  success: boolean;
  status_code: number;
  message: string;
  data: {
    project_data: ProjectListType[];
    pagination: Pagination;
  };
}
interface Pagination {
  total_data: number;
  current_page: number;
  page_size: number;
  total_pages: number;
}

export const projectReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
) => {
  builder
    //to get all projects
    .addCase(getAllActiveProjects.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getAllActiveProjects.fulfilled,
      (state, action: PayloadAction<any>) => {
        // state.status = "succeeded";
        if (action.payload) {
          if (action.payload.pagination.current_page === 1) {
            state.pagination = action.payload.pagination;
            state.ActiveProjects = action.payload.project_data;
          } else {
            state.ActiveProjects = [...state.ActiveProjects,
            ...action.payload.project_data.filter(
              (project: any) => !state.ActiveProjects.some((p) => p.project_id === project.project_id)
            )];
          }
          state.loading = false;
        }
      }
    )
    .addCase(
      getAllActiveProjects.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    )
    .addCase(getSingleProjectAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getSingleProjectAPI.fulfilled,
      (state, action: PayloadAction<{ site: SingleProjectType }>) => {
        state.loading = false;
        state.SingleProject = action.payload.site;
      }
    )
    .addCase(
      getSingleProjectAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
        } else {
          showErrorToast("Error fetching project");
        }
        state.loading = false;
      }
    )
    .addCase(updateSingleSiteAPI.pending, (state) => { })
    .addCase(
      updateSingleSiteAPI.fulfilled,
      (
        state,
        action: PayloadAction<{ updated_fields: UpdateProjectPayloadType }>
      ) => { }
    )
    .addCase(
      updateSingleSiteAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
        } else {
          showErrorToast("Error update project");
        }
      }
    )
    .addCase(getProjectGroupsAPI.pending, (state) => { })
    .addCase(
      getProjectGroupsAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          groups_data: ProjectGroupType[];
          pagination: PaginationType;
        }>
      ) => {
        if (
          action.payload.pagination.current_page ===
          state.SingleProjectGroupsPagination.current_page
        ) {
          state.SingleProjectGroups = action.payload.groups_data;
          state.SingleProjectGroupsPagination = action.payload.pagination;
        }
      }
    )
    .addCase(
      getProjectGroupsAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
        } else {
          showErrorToast("Error in fetching project's group");
        }
      }
    )
    .addCase(getProjectManagerAPI.pending, (state) => { })
    .addCase(
      getProjectManagerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          group_managers_data: ManagerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.SingleProjectManagers = action.payload.group_managers_data;
      }
    )
    .addCase(
      getProjectManagerAPI.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.message) {
          showErrorToast(action.payload.message);
        } else {
          showErrorToast("Error in fetching project's group");
        }
      }
    );
};
