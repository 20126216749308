import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

export const getAllRank = createAsyncThunk(
    "rank/getAllRank",
    async (_, { rejectWithValue }) => {
      try {
        const response = await apiClient.get(`${ApiConstants.GET_ALL_RANKS}`);
        return response.data.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.error);
      }
    }
  );
  
  export const updateRankAPI = createAsyncThunk(
    "rank/updateRankAPI",
    async (data: {id: number, payload: any }, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${ApiConstants.UPDATE_RANK_API.replace(":rankId", data.id.toString())}`, data.payload);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error);
        }
    }
  );

export const createRankAPI = createAsyncThunk(
    "rank/createRankAPI",
  async (data: { 
    name: string;
    level: number;
    videos: number;
    referrals: number;
    site_visits: number;
    }, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${ApiConstants.CREATE_RANK_API}`, data);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error);
        }
    }
);   
  
export const deleteRankAPI = createAsyncThunk(
  "rank/deleteRankAPI",
  async (data: { rank_Id: number }, { rejectWithValue }) => {
    try {  
      const response = await apiClient.delete(
        `${ApiConstants.DELETE_RANK_API}/${data.rank_Id}/delete`
      );
      return {
        payload: response.data.data,
        rankId: data.rank_Id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

