import { Spin, Table, Typography } from 'antd'
import React, { FC } from 'react'
import Pagination from '../Pagination/Pagination';
import "./TableComponent.scss";
import Loader from '../Loader/Loader';
import EmptyPage from '../EmptyPage/EmptyPage';
import { image } from '../../../app/utils/common/image';

interface TableComponentProps {
    columns: any[],
    dataSource: any[],
    pageNumbers: number[];
    handlePageLink: (page: number) => void;
    prevPage: () => void;
    nextPage: () => void;
    totalProfile: number;
    activePage: number;
    currentTotal: number;
    loading?: boolean;
    isPagination?: boolean;
    RecordType?: any;
    emptyImage?: string
    emptyText?: string
}

const TableComponent: FC<TableComponentProps> = (props) => {
    const { columns, loading = false, dataSource, pageNumbers, isPagination = false, handlePageLink, prevPage, nextPage, totalProfile, activePage, currentTotal, emptyImage = image.NO_MANAGER_ADDED_YET_IMAGE, emptyText = "No data available" } = props

    return (
        <div className='table'>
            <Table<any>
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                loading={{
                    spinning: loading,
                    indicator: <div className=' loading_text'><Loader /></div>,
                }}

                locale={{
                    emptyText:
                        <div className='center_div'>
                            <div style={{ maxWidth: "50%", height: "50%", padding: "5rem 0px" }}>
                                <EmptyPage image={emptyImage} text={emptyText} />
                            </div>
                        </div>
                }}
            />
            {isPagination &&
                <Pagination pageNumbers={pageNumbers}
                    handlePageLink={handlePageLink}
                    totalProfile={totalProfile}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    activePage={activePage}
                    currentTotal={currentTotal} />
            }
        </div>
    )
}

export default TableComponent