import React, { FC } from 'react'
import Input from '../../../atoms/Input/Input'
import { image } from '../../../../app/utils/common/image'
import { BrokerType } from '../../../../dto/Broker.dto'
import { getImageURL } from '../../../../app/utils/common/getImageUrl';

interface ManageBrokerHeaderProps {
    currentBrokerDetails: BrokerType;
}

const ManageBrokerHeader: FC<ManageBrokerHeaderProps> = ({ currentBrokerDetails }) => {

    return (
        <div className='group-container broker-container'>
            <div style={{ position: "relative" }}>
                <div >
                    <img className='broker-profile' src={currentBrokerDetails.profile_image ? getImageURL(currentBrokerDetails?.profile_image) : image.DEFAULT_PROFILE_IMAGE} alt="" />
                    {/* <img src={image.CAMERA_PHOTO} alt="camera icon" className="broker-camera_image" /> */}
                </div>
            </div>
            <div className='broker-main-container'>
                <div className='group-name-container'>
                <div className='group-photo'>Name</div>
                    <Input
                        placeholder="Enter Name"
                        type="text"
                        name="broker Name"
                        id="brokerName"
                        value={currentBrokerDetails?.name}
                        onChange={() => { }}
                        iconSrc={image.PhoneLogo}
                        showIcon={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Phone Number</div>
                    <Input
                        placeholder="Enter Phone Number"
                        type="number"
                        name="broker number"
                        id="brokerNumber"
                        value={currentBrokerDetails?.phone_number}
                        onChange={() => { }}
                        iconSrc={image.PhoneLogo}
                        showIcon={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Referral Code</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="Referral Code"
                        id="referralCode"
                        value={currentBrokerDetails?.referral_code}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
               
                <div className='group-name-container'>
                    <div className='group-photo'>Referral Name</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="Referral Name"
                        id="referrerName"
                        value={currentBrokerDetails?.referrer_name}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Referral Phone Number</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="Referral Phone Number"
                        id="referralPhoneNumber"
                        value={currentBrokerDetails?.referrer_phone_number}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Refer By</div>
                    <Input
                        placeholder=""
                        type="text"
                        name="broker Name"
                        id="brokerName"
                        value={currentBrokerDetails?.refer_by}
                        onChange={() => { }}
                        // iconSrc={image.PhoneLogo}
                        showIcon={true}
                        disabled={true}
                    />
                </div>
            </div>

        </div>
    )
}

export default ManageBrokerHeader