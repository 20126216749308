import React from "react"
import { image } from "../../../../app/utils/common/image";
import { useNavigate } from "react-router-dom";
import CreatePost from "../../../molecules/PostManagement/CreatePost/CreatePost";

const CreatePostPage = () => {
    const navigate = useNavigate()
    return (
        <div className='create_project_container' >
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>Create Post</div>
            </div>
            <CreatePost />
        </div>
    )
}

export default CreatePostPage