import React, { FC, useEffect, useState } from 'react';
import { type TableColumnsType } from 'antd';
import { image } from '../../../../app/utils/common/image';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { getAllGroups } from '../../../../redux/API/GroupApi';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import { PaginationType } from '../../../../dto/Pagination.dto';
import "./SelectModal.scss";
import { ProjectGroupType } from '../../../../dto/Projects.dto';
import Checkbox from '../../../atoms/Checkbox/Checkbox';
import { setGroupPagination } from '../../../../redux/Slices/groupSlice';

const AddGroupTable: FC<{
    selectedGroups: ProjectGroupType[],
    onChangeData: (key: string, value: ProjectGroupType[]) => void;
    setIsSelectGroupModalOpen: (data: boolean) => void;
}> = ({
    selectedGroups,
    onChangeData,
    setIsSelectGroupModalOpen
}) => {
        const navigate = useNavigate()
        const [loading, setLoading] = useState<boolean>(false);
        const { groups, groupPagination } = useAppSelector(state => state.group);
        const [newSelectedGroups, setNewSelectedGroups] = useState<any[]>(selectedGroups);
        const [pageAdded, setPageAdded] = useState<number[]>([]);
        const dispatch = useAppDispatch();
        const [isSelectAll, setIsSelectAll] = useState(false);
        const [selectedCps, setSelectedCps] = useState<number[]>([]);

        useEffect(() => {
            if (!loading) {
                setLoading(true);
                dispatch(getAllGroups({ page: groupPagination?.current_page, total_page: 5 })).unwrap()
                    .then((data: {
                        group_data: any,
                        pagination: PaginationType
                    }) => {

                    }).catch(() => { }).finally(() => {
                        setLoading(false);
                    })
            }
        }, [groupPagination?.current_page]);

        useEffect(() => {
            return () => {
                dispatch(setGroupPagination({ ...groupPagination, current_page: 1 }));
            }
        }, [])

        const isAllSelected = groups?.every(group =>
            newSelectedGroups?.some(selected => selected.group_id === group.group_id)
        );
    
        // Handles the selection of individual groups
        const handleGroupsSelection = (selectedGroup: ProjectGroupType) => {
            const exists = newSelectedGroups.find(group => group.group_id === selectedGroup.group_id);
            if (exists) {
                setNewSelectedGroups(newSelectedGroups.filter(group => group.group_id !== selectedGroup.group_id));
            } else {
                setNewSelectedGroups([...newSelectedGroups, selectedGroup]);
            }
        };
    
        const handleSelectAllGroups = () => {
            if (isAllSelected) {
                // Deselect all groups on the current page
                setNewSelectedGroups(
                    newSelectedGroups.filter(
                        selected => !groups.some(group => group.group_id === selected.group_id)
                    )
                );
            } else {
                // Select all groups on the current page
                const updatedSelection = [
                    ...newSelectedGroups,
                    ...groups.filter(
                        group => !newSelectedGroups.some(selected => selected.group_id === group.group_id)
                    )
                ];
                setNewSelectedGroups(updatedSelection);
            }
        };

        const handleAllocate = () => {
            if (newSelectedGroups.length > 0) {
                onChangeData("select-groups", newSelectedGroups);
                setIsSelectGroupModalOpen(false)
            } else {

            }
        }
        const columns: TableColumnsType<ProjectGroupType> = [
            {
                title: 'Group Photo',
                dataIndex: 'Photo',
                render: (text, record) => {
                    return (
                        <img
                            src={record.photo ? getImageURL(record.photo) : image.DEFAULT_PROFILE_IMAGE}
                            alt="Profile"
                            className='reg-profile'
                        />
                    )
                },
            },
            {
                title: 'Group Name',
                dataIndex: 'name',
                render: (text, record) => (
                    <div >
                        {record.name}
                    </div>
                )
            },
            {
                title: 'Managers',
                dataIndex: 'managers',
                render: (text, record) => {
                    const MAX_VISIBLE_IMAGES = 4; // Limit of visible images
                    return (
                        <div className='group_list_container'>
                            {record.manager_profiles.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: any, index: number) => (
                                <img key={index} src={imgSrc ? getImageURL(imgSrc) : image.DEFAULT_PROFILE_IMAGE} alt={`Member ${index + 1}`} />
                            ))}
                            {record.manager_profiles?.length > MAX_VISIBLE_IMAGES && (
                                <div className="total_members_class">
                                    +{record.manager_profiles.length - MAX_VISIBLE_IMAGES}
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                title: 'Brokers',
                dataIndex: 'brokers',
                render: (text, record) => {
                    const MAX_VISIBLE_IMAGES = 4;
                    return (
                        <div className='group_list_container'>
                            {record.cp_profiles.length > 0 ? (
                                <>
                                    {record.cp_profiles?.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: any, index: number) => (
                                        <img key={index} src={imgSrc ? getImageURL(imgSrc) : image.DEFAULT_PROFILE_IMAGE} alt={`Cp ${index + 1}`} />
                                    ))}
                                    {record.cp_profiles?.length > MAX_VISIBLE_IMAGES && (
                                        <div className="total_members_class">
                                            +{record.cp_profiles.length - MAX_VISIBLE_IMAGES}
                                        </div>
                                    )}
                                </>
                            )
                                : "No Brokers allocated."
                            }
                        </div>
                    );
                },
            },
            {
                title: 'Action',
                dataIndex: 'action',
                render: (text, record) => (
                    <div>
                        <Checkbox
                            checked={newSelectedGroups.some(group => group.group_id === record.group_id)}
                            onChange={() => handleGroupsSelection(record)}
                        />
                    </div>
                ),
            },
        ];

        const handlePageChange = (pageNumber: number) => {
            if (!loading) {
                let tempData = { ...groupPagination };
                tempData.current_page = pageNumber
                dispatch(setGroupPagination(tempData));
            }
        };
    console.log('newSelectedGroups :>> ', newSelectedGroups);
        return (
            <div className='select_group_table'>
                <div>
                    <h2 style={{ textAlign: "center" }}>Select Group</h2>
                </div>
                <div className='select-all-button-container'>
                    <p>Select All</p>
                    <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAllGroups}
                    />

                </div>
                <div style={{ minHeight: "300px" }}>
                    <TableComponent columns={columns}
                        dataSource={groups}
                        loading={loading}
                        pageNumbers={Array.from({ length: groupPagination?.total_pages }, (_, index) => index + 1)}
                        handlePageLink={handlePageChange}
                        isPagination={groupPagination?.total_pages > 1}
                        totalProfile={groupPagination?.total_data}
                        prevPage={() => handlePageChange(groupPagination?.current_page - 1)}
                        nextPage={() => handlePageChange(groupPagination?.current_page)}
                        activePage={groupPagination?.current_page}
                        currentTotal={Math.min(groupPagination?.current_page * groupPagination?.page_size, groups?.length)} />

                </div>

                <div className='center_div create_project_btn_container' style={{ marginBottom: "10px" }}>
                    {/* <button className='cancel_btn' onClick={() => {}}>
                    Cancel
                </button> */}
                    <button className='submit_btn' onClick={() => { handleAllocate() }}>
                        Allocate
                    </button>
                </div>
            </div>

        );
    };

export default AddGroupTable;