import React, { useState } from 'react'
import Modal from '../Modal/Modal'
import Loader from '../Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import Input from '../Input/Input';
import { image } from '../../../app/utils/common/image';
import "./RankEditModal.scss"
import { RankDetail } from '../../../dto/Rank.dto';
import { getAllRank, updateRankAPI } from '../../../redux/API/RankAPI';
import { showSuccessToast } from '../../../app/utils/common/Toast';
import { setPostLoading } from '../../../redux/Slices/postSlice';
import { setRankLoading } from '../../../redux/Slices/rankSlice';
interface rankProps {
  setIsEditModalOpen: (data: boolean) => void,
  currentRank: any

}
const RankEditModal: React.FC<rankProps> = props => {
  const { setIsEditModalOpen, currentRank } = props;
  const [rankDetails, setRankDetails] = useState({
    name: currentRank.name,
    level: currentRank.level,
    referrals: currentRank.referrals,
    videos: currentRank.videos,
    site_visits: currentRank.site_visits
  });
  const dispatch = useAppDispatch();
  // console.log('rankDetails :>> ', rankDetails);
  const { loading, allRanks } = useAppSelector((state) => state.rank);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRankDetails((prev) => ({ ...prev, [name]: value }))
  };

  const handleUpdateClick = () => {
    dispatch(setRankLoading(true));
    setIsEditModalOpen(false);
    dispatch(updateRankAPI({ id: currentRank.id, payload: rankDetails })).unwrap().then(() => {
      showSuccessToast("Rank Updated Successfully!");
      dispatch(getAllRank()).then(() => {
        dispatch(setRankLoading(false))
      })
    }).catch((error) => {
      dispatch(setRankLoading(false))
    }).finally(() => {

    })
  }
  return (
    <div>
      <Modal
        isClose={true}
        onClose={() => { setIsEditModalOpen(false) }}
      >
        <div>
          <Modal isClose={true} onClose={() => setIsEditModalOpen(false)}>
            {currentRank && (
              <div className='rank_main'>
                <div className="rankTitle">{currentRank.name}</div>
                <hr></hr>
                <div style={{ paddingTop: "20px" }}>
                  <div className="rank-main-container">
                    <div className="rank-name-container">
                      <div className="rank_input_heading">Rank Name</div>
                      <Input
                        className='rank_input_border'
                        placeholder="Enter Rank Name"
                        type="text"
                        name="name"
                        id="rankName"
                        value={rankDetails.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="rank-name-container">
                      <div className="rank_input_heading">Level</div>
                      <Input
                        placeholder="Enter Level Number"
                        type="number"
                        name="level"
                        id="level"
                        value={rankDetails.level}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="rank-name-container">
                      <div className="rank_input_heading">Videos</div>
                      <Input
                        placeholder="Enter Videos Number"
                        type="number"
                        name="videos"
                        id="video"
                        value={rankDetails.videos}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="rank-name-container">
                      <div className="rank_input_heading">Referral Number</div>
                      <Input
                        placeholder="Enter Referral Number"
                        type="number"
                        name="referrals"
                        id="Referral"
                        value={rankDetails.referrals}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="rank-name-container">
                      <div className="rank_input_heading">Site Visits</div>
                      <Input
                        placeholder="Enter Site Visits"
                        type="number"
                        name="site_visits"
                        id="siteVisits"
                        value={rankDetails.site_visits}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="rank-button-container">
                    <button onClick={() => setIsEditModalOpen(false)} className="confirm-continue-btn"> Cancel </button>
                    <button className="btns save-changes" onClick={handleUpdateClick} > Update </button>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </Modal>
    </div>
  )
}

export default RankEditModal
