import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActiveCPsType,
  ManagerType,
  ManagersCPType,
  ManagersProjectType,
} from "../../dto/Manager.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { ManagerReducer } from "../Reducer/Manager/ManagerReducer";

export interface ManagerSliceState {
  loading: boolean;
  managers: ManagerType[];
  managerFilter: string;
  pagination: PaginationType;
  refreshTable: boolean;
  currentManager?: ManagerType;
  ManagersProjectPagination: PaginationType;
  ManagersCPsPagination: PaginationType;
  ManagersProject: ManagersProjectType[];
  ManagersCPs: {
    manager_id: number;
    cps: ManagersCPType[];
  };
  availableCPsPagination: PaginationType;
  activeCps: ActiveCPsType[];
}

const initialState: ManagerSliceState = {
  loading: false,
  managers: [],
  refreshTable: false,
  ManagersProject: [],
  managerFilter: "",
  ManagersCPs: {
    manager_id: -1,
    cps: [],
  },
  ManagersProjectPagination: {
    total_data: 0,
    current_page: 1,
    page_size: 10,
    total_pages: 1,
  },
  ManagersCPsPagination: {
    total_data: 0,
    current_page: 1,
    page_size: 10,
    total_pages: 1,
  },
  availableCPsPagination: {
    total_data: 0,
    current_page: 1,
    page_size: 10,
    total_pages: 1,
  },
  pagination: {
    total_data: 0,
    current_page: 1,
    page_size: 10,
    total_pages: 1,
  },
  activeCps: [],
};

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    setManagerPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },
    setCurrentManager(state, action: PayloadAction<ManagerType>) {
      state.currentManager = action.payload;
      state.ManagersProjectPagination = {
        total_data: 0,
        current_page: 1,
        page_size: 5,
        total_pages: 1,
      };
      state.ManagersCPsPagination = {
        total_data: 0,
        current_page: 1,
        page_size: 5,
        total_pages: 1,
      };
      state.availableCPsPagination = {
        total_data: 0,
        current_page: 1,
        page_size: 5,
        total_pages: 1,
      };
    },
    setManagersProjectPagination(state, action: PayloadAction<PaginationType>) {
      state.ManagersProjectPagination = action.payload;
    },
    setManagersCPsPagination(state, action: PayloadAction<PaginationType>) {
      state.ManagersCPsPagination = action.payload;
    },
    setManagerLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setavailableCPsPagination(state, action: PayloadAction<PaginationType>) {
      state.availableCPsPagination = action.payload;
    },
    setManagers(state, action: PayloadAction<ManagerType[]>) {
      state.managers = action.payload;
    },
    setManagerFilters(state, action: PayloadAction<string>) {
      state.managerFilter = action.payload;
    },
    resetManagerFilter(state) {
      state.managerFilter = '';
  },
    clearManagersCpData(state) {
      state.ManagersCPs.cps = [];
      state.ManagersCPsPagination.current_page = 1;
    },
    
    clearManagerData(state) {
      state.managers = [];
      state.pagination = {
        total_data: 0,
        current_page: 1,
        page_size: 10,
        total_pages: 1,
      }
    }
  },
  extraReducers: (builder) => {
    ManagerReducer(builder);
  },
});

export const {
  setManagerPagination,
  setCurrentManager,
  setManagersProjectPagination,
  setManagersCPsPagination,
  setManagerLoading,
  setavailableCPsPagination,
  clearManagersCpData,
  setManagerFilters,
  resetManagerFilter,
  clearManagerData,
  setManagers
} = managerSlice.actions;
export default managerSlice.reducer;
