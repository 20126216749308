import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../atoms/Card/Card'
import { getImageURL } from '../../../../app/utils/common/getImageUrl'
import { image } from '../../../../app/utils/common/image'
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook'
import { getManagersProjectAPI } from '../../../../redux/API/ManagerAPI'
import { setManagersCPsPagination, setManagersProjectPagination } from '../../../../redux/Slices/managerSlice'
import EmptyPage from '../../../atoms/EmptyPage/EmptyPage'
import Loader from '../../../atoms/Loader/Loader'

const ManagersSites = () => {

    const scrollRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const { ManagersProject, ManagersProjectPagination, currentManager } = useAppSelector(state => state.manager);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (currentManager) {
            setLoading(true);
            dispatch(getManagersProjectAPI({ manager_id: currentManager.manager_id, current_page: ManagersProjectPagination.current_page })).unwrap().then(() => { }).catch(() => { }).finally(() => { setLoading(false) });
        }
    }, [ManagersProjectPagination.current_page])
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loading) {
                if (ManagersProjectPagination.current_page < ManagersProjectPagination.total_pages)
                    dispatch(setManagersCPsPagination({ ...ManagersProjectPagination, current_page: ManagersProjectPagination.current_page + 1 }))
            }
        }
    };

    const MAX_VISIBLE_IMAGES = 3; // Limit of visible images
    // const images = record?.managers || []; // Fallback if record.managers is undefined
    const images = [image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,]

    return (
        <div className='site-visited-container'>
            <div className='site-title'>Site  Handling</div>
            <div className='active_projects_container active-sites' ref={scrollRef} onScroll={handleScroll}>

                {loading ?
                    <div className='center-content'><Loader /></div>
                    :

                    ManagersProject.length === 0 ?
                        <div className='EmptyPageSite_container'>
                            <EmptyPage image={image.NO_SITE_VISITED_YET_IMAGE} text='No Site Available' />
                        </div>
                        :
                        ManagersProject.map(manager => (
                            <Card customClass='project_card' >

                                <div className='post-card site-card'>
                                    <div style={{ display: "contents" }}>

                                        <div className='post-thumbnail-container'>
                                            <img className='project-card-thumbnail' src={getImageURL(manager.url)} />
                                        </div>
                                        <div className='site-visited-title'>{manager.name}</div>
                                        <div className='site-visited-desc'>{manager.address}</div>
                                        <div className='site-visited-group'>
                                            <div className='group_list_container total-list-site'>
                                                {images?.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: string, index: number) => (
                                                    <img key={index} src={imgSrc} alt={`Member ${index + 1}`} />
                                                ))}
                                                {images?.length > MAX_VISIBLE_IMAGES && (
                                                    <div className="total_members_class total-member-site">
                                                        +{(manager.total_managers + manager.total_cps)}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='site-visited-manager'>Managers & Brokers</div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        ))
                }

            </div>
        </div>

    )
}

export default ManagersSites
