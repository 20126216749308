import React, { useEffect, useState } from 'react'
import "./AddBrokerUnderManager.scss"
import { Button, TableColumnsType } from 'antd'
import { useNavigate } from 'react-router-dom'
import { image } from '../../../../app/utils/common/image'
import TableComponent from '../../../atoms/TableComponent/Tablecomponent'
import Checkbox from '../../../atoms/Checkbox/Checkbox'
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook'
import { allocateCPsAPI, getAvailableCPsAPI } from '../../../../redux/API/ManagerAPI'
import { ActiveCPsType } from '../../../../dto/Manager.dto'
import { setavailableCPsPagination, setManagerFilters } from '../../../../redux/Slices/managerSlice'
import { getImageURL } from '../../../../app/utils/common/getImageUrl'
import FilterModal from '../../../atoms/FilterModal/FilterModal'
import { setBrokerFilter } from '../../../../redux/Slices/brokerSlice'

const BrokerUnderManagerTable = () => {
    const navigate = useNavigate();
    const { activeCps, availableCPsPagination, loading, currentManager, managerFilter } = useAppSelector(state => state.manager);
    const { searchText } = useAppSelector(state => state.search);
    const [selectedCps, setSelectedCps] = useState<number[]>([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const filterModalOptions = [
        {
            key: "most_site_visits",
            value: "Most Site Visits"
        },
        {
            key: "least_site_visits",
            value: "Least Site Visits"
        },
        {
            key: "most_referrals",
            value: "Most Referrals"
        },
        {
            key: "least_referrals",
            value: "Least Referrals"
        }
    ]

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!loading)
            dispatch(getAvailableCPsAPI({ current_page: availableCPsPagination.current_page }));
        if (!currentManager)
            navigate("/dashboard/manage-manager")
    }, [availableCPsPagination.current_page]);

    const handleSelection = (id: number) => {
        if (selectedCps.includes(id)) setSelectedCps(selectedCps.filter(_id => id !== _id));
        else setSelectedCps([...selectedCps, id]);
    }
    const handleSelectAllCps = () => {
        const currentCpIds = activeCps.map(record => record.cp_id);
        const allSelected = currentCpIds.every(id => selectedCps.includes(id));
        if (allSelected) {
            // Remove current page's records from selectedCps
            setSelectedCps(selectedCps.filter(id => !currentCpIds.includes(id)));
        } else {
            // Add current page's records to selectedCps, avoiding duplicates
            const updatedSelection = Array.from(new Set([...selectedCps, ...currentCpIds]));
            setSelectedCps(updatedSelection);
        }
        setIsSelectAll(!isSelectAll)
    };

    const columns: TableColumnsType<ActiveCPsType> = [
        {
            title: 'Broker Profile',
            dataIndex: 'Photo',
            render: (text, record) => {
                return (
                    <img
                        src={record.profile_image ? getImageURL(record.profile_image) : image.DEFAULT_PROFILE_IMAGE}
                        alt="Profile"
                        className='reg-profile'
                        onClick={() => { }}
                    />
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
        },
        {
            title: 'Sites Visited',
            dataIndex: 'site_visits',
        },
        {
            title: 'Referral Code',
            dataIndex: 'referral_code',
            render: (text, record) => (
                <div>{record.referral_code}</div>
            )
        },
        {
            title: 'Referral Name',
            dataIndex: 'referrer_name',
            render: (text, record) => (
                <div>{record.referrer_name}</div>
            )
        },
        {
            title: 'Refer By Code',
            dataIndex: 'refer_by',
            render: (text, record) => (
                <div>{record.refer_by}</div>
            )
        },
        {
            title: 'Referrer Phone Pumber',
            dataIndex: 'referrer_phone_number',
            render: (text, record) => (
                <div>{record.referrer_phone_number}</div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <div>
                    <Checkbox checked={selectedCps.includes(record.cp_id)} onChange={() => { handleSelection(record.cp_id) }} />
                </div>
            ),
        },
    ];

    // const handlePageChange = (pageNumber: number) => {
    //         if (isSelectAll) {
    //             setSelectedCps([]);
    //             setIsSelectAll(false);
    //         }
    //     dispatch(setavailableCPsPagination({ ...availableCPsPagination, current_page: pageNumber }));
    // };

    const handlePageChange = (pageNumber: number) => {
        dispatch(setavailableCPsPagination({ ...availableCPsPagination, current_page: pageNumber }));
        // setIsSelectAll(false);
    };

    const handleAllocateCp = () => {
        if (currentManager) {
            dispatch(allocateCPsAPI({ cp_ids: selectedCps, manager_id: currentManager?.manager_id })).unwrap().then(() => {
                navigate(-1);
            }).catch((err) => { })
        }
    }
    const fetchFilterData = (sortBy: string) => {
        dispatch(getAvailableCPsAPI({ current_page: 1, sortBy: sortBy, searchText }));
        dispatch(setBrokerFilter(sortBy))
    }
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='broker_under_manager_header'>
                    <img src={image.BACK_ICON} className='back_icon' onClick={() => navigate(-1)} />
                    <span className='manager_name' >{currentManager?.name}</span><span >(Manager)</span>
                    <div className='allocate_broker'>Allocate Broker</div>
                </div>
                <div className='button_container'>
                    {(managerFilter !== "" || null || undefined) && <div className='filter_count'><span>1</span></div>}
                    <div className='btn' onClick={() => setFilterModal(!filterModal)}>
                        <img className='btn_icon' src={image.FILTER_ICON} />
                        <div>
                            Filter
                        </div>
                    </div>
                </div>
            </div>

            <div className='select-all-button-container'>
                <p>Select All</p>
                <Checkbox
                    //checks if every record on the current page is in selectedCps
                    checked={activeCps.every(record => selectedCps.includes(record.cp_id))}
                    onChange={() => { handleSelectAllCps() }}
                />
            </div>

            <div className='manager_dataTable'>
                <div>
                    <TableComponent columns={columns}
                        dataSource={activeCps}
                        loading={loading}
                        pageNumbers={Array.from({ length: availableCPsPagination.total_pages }, (_, index) => index + 1)}
                        handlePageLink={handlePageChange}
                        isPagination={availableCPsPagination.total_pages > 1}
                        totalProfile={availableCPsPagination.total_data}
                        prevPage={() => handlePageChange(availableCPsPagination.current_page - 1)}
                        nextPage={() => handlePageChange(availableCPsPagination.current_page + 1)}
                        activePage={availableCPsPagination.current_page}
                        currentTotal={Math.min(availableCPsPagination.current_page * availableCPsPagination.page_size, (activeCps ?? []).length)} />
                </div>
            </div>

            {
                selectedCps.length > 0 &&
                <div className='center_div create_project_btn_container' style={{ marginBottom: "10px" }}>
                    <button className='cancel_btn' onClick={() => setSelectedCps([])}>
                        Cancel
                    </button>
                    <button className='submit_btn' onClick={() => handleAllocateCp()}>
                        Allocate
                    </button>
                </div>
            }
            {filterModal && (
                <div className='filter_modal_wrapper'>
                    <FilterModal
                        options={filterModalOptions}
                        handleSubmit={(data) => {
                            console.log(data)
                            fetchFilterData(data.key);
                            setFilterModal(false);
                        }}
                        handleRemoveFilter={() => {
                            dispatch(getAvailableCPsAPI({ current_page: 1, limit: 15 }))
                            setFilterModal(false);
                        }}
                        filterType='manager'
                        containerClassName={"filter_modal_container_2"}
                        onOutSideClick={() => setFilterModal(false)}
                    />

                </div>
            )}
        </div>
    )
}

export default BrokerUnderManagerTable