import React, { useRef, useState } from "react"
import { image } from "../../../../app/utils/common/image";
import { useNavigate } from "react-router-dom";
import { confirmModalProps } from "../../../../dto/confirm";
import ConfirmModal from "../../../atoms/confirmation-modal/confirmation-modal";
import { createPostAPI } from "../../../../redux/API/PostAPI";
import { showErrorToast } from "../../../../app/utils/common/Toast";
import { PostMediaType, CreatePostType } from "../../../../dto/post.dto";
import { useAppDispatch } from "../../../../app/utils/hooks/reduxHook";
import LoaderScreen from "../../../atoms/LoaderScreen/LoaderScreen";
import './CreatePost.scss'
import { uploadFile } from "../../../../redux/API/ProjectAPI";

const CreatePost = () => {
    const navigate = useNavigate();
    const inputSitePostRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch()
    const [newPost, setNewPost] = useState<{
        type: Number,
        file: File,
        thumbnail?: File,
        url: string,
    }[]>([]);
    const [discardChangeModal, setDiscardChangeModal] = useState<boolean>(false);
    const [postText, setPostText] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to discard all changes",
        confirmModal: discardChangeModal,
        setConfirmModal: setDiscardChangeModal,
        buttons: ["Yes, Discard", "Cancel"],
        onPressPositive: () => {
            setDiscardChangeModal(false);
            handleCancel();
        },
        onPressNegative: () => {
            setDiscardChangeModal(false);
        },
        image: image.REMOVE_GROUP_ICON,
    };

    const onChangeData = async (key: string, value: any) => {
        switch (key) {
            case "post-photos":
                if (newPost.length + value.length > 3) {
                    showErrorToast("You can only add up to 3 media files.");
                    return;
                }
                let files = value;
                const updatedPayload = await Promise.all(
                    files.map(async (file: File) => {
                        if (file) {
                            const fileType = file.type.split("/")[0];
                            if (fileType === "image") {
                                return {
                                    type: 1,
                                    file: file,
                                    url: URL.createObjectURL(file),
                                };
                            } else if (fileType === "video") {
                                const thumbnail = await generateThumbnail(file);
                                console.log(thumbnail, 'thumbnail');

                                return {
                                    type: 2,
                                    file: file,
                                    thumbnail: thumbnail,
                                    url: thumbnail ? URL.createObjectURL(thumbnail) : null,
                                };
                            }
                        }
                        return null; // Handle case where file might be invalid
                    })
                );

                // Filter out any null results if needed
                const filteredPayload = updatedPayload.filter(Boolean);
                console.log("🚀 ~ updatedPayload ~ thumbnail:", filteredPayload)
                setNewPost([...newPost, ...filteredPayload]);
                return;
            case "post-text":
                setPostText(value);
                return;
            default:
                return;
        }
    }

    const removeMedia = (key: string, index: number) => {
        switch (key) {
            case "post-photos":
                // let filteredPostBlobUrls = photoUrls.filter(
                //   (data, dataIndex) => index !== dataIndex
                // );

                let filteredpostPhotos = newPost.filter(
                    (data, dataIndex) => index !== dataIndex
                );
                // setPhotoUrls(filteredSiteBlobUrls);
                setNewPost(filteredpostPhotos);
                return;
            default:
                return;
        }
    };

    const handleCancel = () => {
        setNewPost([]);
        setPostText('')
    }

    const generateThumbnail = (file: File): Promise<File | null> => {
        return new Promise((resolve, reject) => {
            const videoUrl = URL.createObjectURL(file);
            const videoElement = document.createElement("video");

            videoElement.src = videoUrl;

            videoElement.onloadeddata = () => {
                videoElement.currentTime = 2; // Capture time for thumbnail
            };

            videoElement.onseeked = () => {
                const canvasElement = document.createElement("canvas");
                canvasElement.width = videoElement.videoWidth;
                canvasElement.height = videoElement.videoHeight;
                const context = canvasElement.getContext("2d");

                if (context) {
                    context.drawImage(
                        videoElement,
                        0,
                        0,
                        canvasElement.width,
                        canvasElement.height
                    );

                    // Convert canvas to Blob and create a File object
                    canvasElement.toBlob((blob) => {
                        if (blob) {
                            const thumbnailFile = new File(
                                [blob],
                                `${file.name}-thumbnail.png`,
                                { type: "image/png" }
                            );
                            resolve(thumbnailFile);
                        } else {
                            resolve(null);
                        }

                        // Clean up the URL
                        URL.revokeObjectURL(videoUrl);
                    }, "image/png");
                } else {
                    reject(new Error("Failed to get canvas context"));
                }
            };

            videoElement.onerror = () => {
                reject(new Error("Failed to load video"));
                URL.revokeObjectURL(videoUrl);
            };
        });
    };

    const handleSubmit = async () => {
        if (!postText.trim() && newPost.length === 0) {
            showErrorToast("Please add content to create a post.");
            return;
        }

        setLoading(true);
        let uploadedPost: any = [];

        if (newPost.length > 0) {
            for (let post of newPost) {
                let uploadedFile = await uploadFile([post.file], "posts")
                if (post.type === 1) {
                    uploadedPost.push({
                        type: uploadedFile.data.imageUrls[0].type,
                        url: uploadedFile.data.imageUrls[0].url
                    })
                } else if (post.type === 2 && post.thumbnail) {
                    let thumbnailURL = await uploadFile([post.thumbnail], "posts");
                    uploadedPost.push({
                        type: uploadedFile.data.imageUrls[0].type,
                        url: uploadedFile.data.imageUrls[0].url,
                        thumbnail: thumbnailURL.data.imageUrls[0].url
                    })
                }
            }
        }

        let payload: CreatePostType = {
            ...(uploadedPost.length > 0 && { media: uploadedPost }),
            ...(postText.trim() && { description: postText.trim() }),
        }

        dispatch(createPostAPI(payload))
            .unwrap()
            .then(() => {
                navigate("/dashboard/post-requests");
                setNewPost([]);
                setPostText("");
            })
            .catch((err) => {
                console.error("Error creating post:", err);
                showErrorToast(err?.message || "Failed to create post.");
            })
            .finally(() => {
                setLoading(false)
            });
    };

    return (
        <>
            {loading && <LoaderScreen />}
            <input
                type="file"
                multiple
                accept="image/* , video/*"
                ref={inputSitePostRef}
                style={{ display: "none" }}
                onChange={(e) => {
                    if (e.target.files) {
                        const filesArray = Array.from(e.target.files);
                        onChangeData("post-photos", filesArray);
                    }
                }}
            />
            <div className="screen_container">
                <div className="add_site_photo">
                    <div
                        className="add_photo_img"
                        onClick={() => {
                            if (inputSitePostRef.current) {
                                inputSitePostRef.current.value = "";
                                inputSitePostRef.current.click();
                            }
                        }}
                    >
                        <img src={image.ADD_IMAGE_PLACEHOLDER} />
                        <div className="add_image_text">Add Image</div>
                    </div>

                    {newPost.map((photo, index) => (
                        <div className="site_photos" key={index} style={{ position: "relative" }}>
                            <img className="img" src={photo.url} alt="post" />
                            <img
                                className="cancel_icon"
                                src={image.CLOSE_ICON}
                                onClick={() => removeMedia("post-photos", index)}
                                alt="remove"
                            />
                            {photo.type === 2 && <img
                                src={image.ADD_VIDEO_PLACEHOLDER}
                                style={{ marginLeft: "0" }}
                                className="video_post_icon"
                            />}
                        </div>
                    ))}
                </div>
                <div className="project_data">
                    <div className="project_description">
                        <div className="desc_container">
                            <label className="label">
                                Post Content
                            </label>
                            <textarea
                                className="input number_input"
                                value={postText}
                                placeholder="Enter post content here"
                                onChange={(e) => {
                                    onChangeData("post-text", e.target.value);
                                }}
                                rows={6}
                                style={{ resize: "none" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="div_center create_project_btn_container">
                <button className="cancel_btn" onClick={() => setDiscardChangeModal(true)}   >
                    Cancel
                </button>

                <button className="submit_btn" onClick={() => handleSubmit()}>
                    Submit
                </button>
            </div>
            {discardChangeModal && (
                <ConfirmModal confirmModalProps={confirmModalProps} />
            )}
        </>

    )
}

export default CreatePost