import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../atoms/Card/Card'
import { getImageURL } from '../../../../app/utils/common/getImageUrl'
import { image } from '../../../../app/utils/common/image'
import { BrokerSiteVisitType } from '../../../../dto/BrokerSiteVisit.dto'
import EmptyPage from '../../../atoms/EmptyPage/EmptyPage'
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook'
import { getCPsSiteVisitAPI } from '../../../../redux/API/BrokerAPI'
import { useParams } from 'react-router-dom'
import { setBrokerPagination } from '../../../../redux/Slices/brokerSlice'
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen'
import Loader from '../../../atoms/Loader/Loader'


const ManageBrokerSitesVisited = () => {

    const scrollRef = useRef<HTMLDivElement>(null);
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const { brokers, CPsManagerPagination, CPsSitePagination, brokerSiteVisit } = useAppSelector(state => state.broker);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const currentBroker = brokers.find((broker) => broker.cp_id.toString() === id);
        if (currentBroker) {
            // console.log("current====>", currentBroker)
            setLoading(true);
            dispatch(getCPsSiteVisitAPI({ cpId: Number(id), page: 1 })).unwrap()
                .then(() => { }).catch(() => { }).finally(() => { setLoading(false) })
                ;
        }
    }, [CPsSitePagination.current_page])
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loading) {
                if (CPsSitePagination.current_page < CPsSitePagination.total_pages)
                    dispatch(setBrokerPagination({ ...CPsSitePagination, current_page: CPsSitePagination.current_page + 1 }))
            }
        }
    };

    const MAX_VISIBLE_IMAGES = 3; // Limit of visible images
    // const images = record?.managers || []; // Fallback if record.managers is undefined
    const images = [image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,
    image.DEFAULT_PROFILE_IMAGE,]
    return (
        <div className='site-visited-container'>
            <div className='site-title'>Site Visited</div>
            {loading ? <div style={{ height: "100%", width: "100%" }} className='center_div'><Loader /> </div> :
                brokerSiteVisit.length > 0 ?
                    <div className='active_projects_container active-sites' ref={scrollRef} onScroll={handleScroll}>
                        {brokerSiteVisit.map((siteVisit, index) => (
                            <Card key={index} customClass='project_card'>
                                <div className='post-card site-card'>
                                    <div style={{ display: "contents" }}>
                                        <div className='post-thumbnail-container'>
                                            <img
                                                className='project-card-thumbnail'
                                                src={siteVisit.url}
                                                onError={(e: any) => e.target.src = image.BackgroundImage}
                                                alt="Building Thumbnail" />
                                        </div>

                                        <div className='site-visited-title'>{siteVisit.name || 'Unknown Site'}</div>
                                        <div className='site-visited-desc'>{siteVisit.address}, {siteVisit.city}, {siteVisit.state},  {siteVisit.zip_code} </div>

                                        <div className='site-visited-group'>
                                            <div className='group_list_container total-list-site'>
                                                {images?.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: string, index: number) => (
                                                    <img key={index} src={imgSrc} alt={`Member ${index + 1}`} />
                                                ))}
                                                {images?.length > MAX_VISIBLE_IMAGES && (
                                                    <div className="total_members_class total-member-site">
                                                        +{images.length - MAX_VISIBLE_IMAGES}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='site-visited-manager'>Managers & Brokers</div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        ))}

                    </div>
                    : <div className='EmptyPageSite_container'>
                        <EmptyPage image={image.NO_SITE_VISITED_YET_IMAGE} text="No site available!" />
                    </div>
            }

        </div>

    )
}

export default ManageBrokerSitesVisited