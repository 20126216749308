import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import VisitsList from './VisitsList';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { getSiteVisitDetailsAPI, getSiteVisitesAPI } from '../../../redux/API/VisitManagementAPI';
import { useParams } from 'react-router-dom';
import { setVisitManagementActiveTab, setVisitManangementPagination } from '../../../redux/Slices/visitManagementSlice';

const VisitTab = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState('scheduled');
    const { scheduledData, visitedData, loading, pagination, visitActiveTab } = useAppSelector((state) => state.vistManagement);

    useEffect(() => {
        dispatch(getSiteVisitesAPI({ page: pagination.current_page, visit_type: activeTab }))
    }, [activeTab, dispatch, pagination.current_page])


    const ScheduledTabHeader = (data: { number: any }) => (
        <div className='tab_selected'>
            Scheduled
            {/* <div className='active_project_number'>{pagination.total_data}</div> */}
        </div>
    );

    const CompletedTabHeader = (data: { number: any }) => (
        <div style={{ color: "#5f9937" }} className='tab_selected'>
            Completed
            {/* <div className='completed_projects_number'>{pagination.total_data}</div> */}
        </div>
    );


    const handleTabChange = (key: string) => {
        dispatch(setVisitManangementPagination({ ...pagination, current_page: 1 }))
        setActiveTab(key)
        dispatch(setVisitManagementActiveTab(key))
    }

    return (
        <div>
            <Tabs
                defaultActiveKey="scheduled"
                onChange={handleTabChange}
                tabBarStyle={{
                    borderBottom: '1px solid #C4C4C4',
                }}
                items={[
                    {
                        key: 'scheduled',
                        label: <ScheduledTabHeader number={6} />,
                        children: <VisitsList status="scheduled" isActive={true} data={scheduledData} />,
                    },
                    {
                        key: 'visited',
                        label: <CompletedTabHeader number={6} />,
                        children: <VisitsList status="visited" isActive={true} data={visitedData} />,
                    },
                ]}
            />

        </div>
    )
}

export default VisitTab
