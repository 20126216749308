import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationReducer } from "../Reducer/NotificationReducer/NotificationReducer";
import { NotificationType } from "../../dto/Notification.dto";
import { PaginationType } from "../../dto/Pagination.dto";


export interface NotificationState {
    loading: boolean;
    success: boolean;
    error: string | null;
    seenNotifications: NotificationType[]
    pagination: PaginationType
    unseenNotifications: NotificationType[]
    unseenNotificationCount: number;
  
  }

const initialState: NotificationState = {
    loading: false,
    success: false,
    error: null,
    seenNotifications: [],
    unseenNotifications:[],
    pagination: {
      total_data: 0,
      current_page: 1,
      page_size: 7,
      total_pages: 1,
    },
    unseenNotificationCount: 0
  };
  
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    SetNotificationPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },
    SetUnseenNotificationCount(state, action: PayloadAction<number>) {
      state.unseenNotificationCount = action.payload;
    },
    },
    extraReducers: (builder) => {
        NotificationReducer(builder);
      },
    
});
export const { SetNotificationPagination, SetUnseenNotificationCount } = notificationSlice.actions;
 export default notificationSlice.reducer;