import React, { useState } from 'react';
import "./NotificationScreen.scss";
import Input from '../../../atoms/Input/Input';
import Button from '../../../atoms/Button/Button';
import AllNotifications from './SeenNotifications';
import { useAppDispatch } from '../../../../app/utils/hooks/reduxHook';
import { SendNotificationAPI } from '../../../../redux/API/NotificationAPI';
import LoaderScreen from '../../../atoms/LoaderScreen/LoaderScreen';
import { showSuccessToast } from '../../../../app/utils/common/Toast';
import SeenNotifications from './SeenNotifications';

const NotificationScreen = () => {
    const [notificationMessage, setNotificationMessage] = useState('');
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const handleLogutButton = async () => {
        setLoading(true);
        try {
            await dispatch(SendNotificationAPI({ message: notificationMessage })).unwrap();
            showSuccessToast("Notification has been sent successfully!");
            setNotificationMessage('');
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='notification-container'>
            <div className='notification-title-container'>
                <p className='notification-title'>Send Notification to Users</p>
            </div>
            <div className='notification-input-container'>
                <div className='notification-input-title'>Type Text Here</div>
                <textarea
                    className="notification-textarea"
                    rows={10}
                    placeholder="send notification..."
                    value={notificationMessage}
                    onChange={(e) => setNotificationMessage(e.target.value)}
                />
                <div className='notification-button-container'>
                    <div className='reject-accept-btn_container'>
                        <div className='reject-accept-btn_postDetails'>
                            <Button
                                buttonTitle="Send"
                                onClick={handleLogutButton}
                                className={'notification-send-button'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SeenNotifications />
            {loading && <div><LoaderScreen /></div>}
        </div>
    );
};

export default NotificationScreen;
