import React, { useEffect, useRef, useState } from 'react';
import "./NotificationScreen.scss"
import { image } from '../../../../app/utils/common/image';
import { getSeenNotificationAPI } from '../../../../redux/API/NotificationAPI';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import moment from 'moment';
import Loader from '../../../atoms/Loader/Loader';
import { PaginationType } from '../../../../dto/Pagination.dto';
import { NotificationType } from '../../../../dto/Notification.dto';

const SeenNotifications = () => {
    const dispatch = useAppDispatch();
    // const { seenNotifications } = useAppSelector(state => state.notification)
    const [loading, setLoading] = useState(false);
    const [seenNotifications, setSeenNotifications] = useState<NotificationType[]>([]);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [pagination, setPagination] = useState({
        total_data: 0,
        current_page: 1,
        page_size: 7,
        total_pages: 1,
    })
    useEffect(() => {
        setLoading(true);
        dispatch(getSeenNotificationAPI({ page: pagination.current_page })).unwrap().then((data: {
            notification_data: NotificationType[],
            pagination: PaginationType
        }) => {
            if (data.pagination.current_page === pagination.current_page) {
                if (data.pagination.current_page === 1) {
                    setPagination(data.pagination)
                    setSeenNotifications(data.notification_data);
                } else {
                    setSeenNotifications([...seenNotifications, ...data.notification_data]);
                }
            }
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        })
    }, [pagination.current_page])


    // pagination
    let debounceTimer: NodeJS.Timeout;
    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= 0.7 && !loading) {
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => {
                    if (!loading && pagination.current_page < pagination.total_pages) {
                        setPagination({
                            ...pagination, current_page: pagination.current_page + 1,
                        });
                    }
                }, 500);
            }
        }
    };

    return (
        <div className="AllNotification-container">
            {loading && pagination.current_page === 1 ? (
                <div className="center_div">
                    <Loader />
                </div>
            ) : (
                <>
                    <p className="header">Seen Notifications</p>
                    <div ref={scrollRef} onScroll={handleScroll} className="notification-list">
                        {seenNotifications.map((notification) => (
                            <div key={notification.id}>
                                <div className="notification-item">
                                    <div className='notification-icon-container'>
                                        <img className='notification-icon' src={image.NOTIFICATION_ALARM} alt="Notification Icon" />
                                    </div>
                                    <div className='notification-item-container'>
                                        <div>
                                            <div className="message">{notification.title}</div>
                                            <div className="body">{notification.body}</div>
                                        </div>
                                        <div>
                                            <div className="time">{moment(notification.created_at).fromNow()}</div>
                                        </div>
                                    </div>
                                </div>
                                <hr className='horizontal-line' />
                            </div>
                        ))}
                    </div>
                </>
            )}
            {loading && pagination.current_page > 1 && (
                <div className='loading_more_notifications'>Loading more data...</div>
            )}
        </div>
    );
};

export default SeenNotifications;
