import React, { FC, useEffect, useId, useState } from "react";
import type { TableColumnsType } from "antd";
import TableComponent from "../../atoms/TableComponent/Tablecomponent";
import { useAppDispatch, useAppSelector, } from "../../../app/utils/hooks/reduxHook";
import { getImageURL } from "../../../app/utils/common/getImageUrl";
import { image } from "../../../app/utils/common/image";
import { getAllUsersAPI } from "../../../redux/API/AllUsersAPI";
import { setAllUsersPagination } from "../../../redux/Slices/allUsersSlice";
import { AllUsersType } from "../../../dto/AllUsers.dto";

const UserManagementTable: FC = () => {
    const { allUsers, pagination, loading } = useAppSelector((state) => state.allUsers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!loading) {
            dispatch(
                getAllUsersAPI({
                    page: pagination.current_page,
                    role_id: 2,
                })
            );
        }
    }, [pagination.current_page]);


    // Handle page changes
    const handlePageChange = (pageNumber: number) => {
        if (!loading) {
            let tempData = { ...pagination };
            tempData.current_page = pageNumber;
            dispatch(setAllUsersPagination(tempData));
        }
    };


    const columns: TableColumnsType<AllUsersType> = [
        {
            title: "Profile",
            dataIndex: "profile_image",
            render: (profile) => (
                <img
                    src={getImageURL(profile) || image.DEFAULT_PROFILE_IMAGE}
                    onError={(e: any) => (e.target.src = image.DEFAULT_PROFILE_IMAGE)}
                    alt="Profile"
                    className="reg-profile"
                />
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Phone Number",
            dataIndex: "phone_number",
            render: (_, record) => (
                <div style={{ textAlign: "center" }}>
                    {record.country_code} {record.phone_number}
                </div>
            )
        },
        {
            title: "Role",
            dataIndex: "role",
        },
    ];

    return (
        <div className="registration_dataTable">
            <TableComponent
                columns={columns}
                loading={loading}
                dataSource={allUsers}
                pageNumbers={Array.from({ length: pagination.total_pages }, (_, index) => index + 1)}
                handlePageLink={handlePageChange}
                isPagination={pagination.total_pages > 1}
                totalProfile={pagination.total_data}
                prevPage={() => handlePageChange(pagination.current_page - 1)}
                nextPage={() => handlePageChange(pagination.current_page + 1)}
                activePage={pagination.current_page}
                currentTotal={Math.min(pagination.current_page * pagination.page_size, allUsers.length)}
            />
        </div>
    );
};

export default UserManagementTable;
