import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/utils/hooks/reduxHook";
import { useLocation } from "react-router-dom";
import { searchPath } from "../../../app/utils/common/getSearchPath";
import { getAllBrokers } from "../../../redux/API/BrokerAPI";
import {
  getAllGroups,
  getAvailableManagers,
} from "../../../redux/API/GroupApi";
import { getRegistrationRequestsAPI } from "../../../redux/API/RegistrationRequestAPI";
import { getAllActiveProjects } from "../../../redux/API/ProjectAPI";
import { getAllUsersAPI } from "../../../redux/API/AllUsersAPI";
import { getAllManagers, getAvailableCPsAPI } from "../../../redux/API/ManagerAPI";
import { setavailableCPsPagination, setManagerFilters, setManagerPagination } from "../../../redux/Slices/managerSlice";
import { setBrokerFilter, setBrokerPagination } from "../../../redux/Slices/brokerSlice";
import { setavailableManagerPagination, setGroupPagination } from "../../../redux/Slices/groupSlice";
import { setRegistrationReqPagination } from "../../../redux/Slices/registrationRequestSlice";
import { setAllUsersPagination } from "../../../redux/Slices/allUsersSlice";
import { setProjectPagination } from "../../../redux/Slices/projectSlice";
import { setSearchText } from "../../../redux/Slices/searchSlice";

// Styled Components
const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  // border: 1px solid #ccc;
  padding: 5px 10px;
  width: 300px;
  background-color: #fff;
  width: 478px;
  height: 40px;
  box-shadow: 0px 5px 30px 0px #0000001a;
  border-radius: 8px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  border-radius: 25px;
  font-size: 16px;
`;

const Icon = styled(FaSearch)`
  color: #5b463e;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 8px;
  cursor: pointer;
`;

const ClearButton = styled.div`
  font-size: 18px;
  color: #888;
  cursor: pointer;
  margin-left: 8px;

  &:hover {
    color: #5b463e;
  }
`;

const SearchBar: React.FC = () => {
  const [inputData, setInputData] = useState("");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const path = location.pathname;
  const { manager, broker } = useAppSelector(state => state)
  useEffect(() => {
    const searchTimeout = setTimeout(async () => {
      dispatch(setSearchText(inputData));
      switch (path) {
        case searchPath.SITE_MANAGEMENT:
          dispatch(setProjectPagination({
            current_page: 1,
            total_pages: 1,
            total_data: 10,
            page_size: 10
          }))
          dispatch(getAllActiveProjects({ status: "Active", current_page: 1, searchText: inputData ?? "", limit: 10 }))
          return;
        case searchPath.MANAGE_MANAGER:
          try {
            dispatch(setManagerPagination({
              current_page: 1,
              total_data: 10,
              total_pages: 1,
              page_size: 10
            }))
            let payload: any = {
              page: 1,
              searchText: inputData ?? ""
            }
            if (manager.managerFilter.length > 0) {
              payload.sortBy = manager.managerFilter;
            }
            dispatch(getAllManagers(payload));
          } catch (error) {
            console.log("error while fetching managers :>> ", error);
          }
          return;

        case searchPath.MANAGE_GROUP:
          try {
            dispatch(setGroupPagination({
              current_page: 1,
              total_data: 10,
              total_pages: 1,
              page_size: 10
            }))
            dispatch(getAllGroups({ page: 1, searchText: inputData ?? "" }))
          } catch (error) {
          }
          return;

        case searchPath.MANAGE_BROKER:
          try {

            dispatch(setBrokerPagination({
              current_page: 1,
              total_data: 10,
              total_pages: 1,
              page_size: 10
            }))
            let payload: any = {
              page: 1,
              searchText: inputData ?? ""
            }
            if (broker.brokerFilter.length > 0) {
              payload.sortBy = broker.brokerFilter;
            }
            dispatch(getAllBrokers(payload));
          } catch (error) {
          }
          return;

        case searchPath.ALLOCATE_BROKER:
          try {
            dispatch(setavailableCPsPagination({
              current_page: 1,
              total_data: 10,
              total_pages: 1,
              page_size: 10
            }))
            let payload: any = {
              page: 1,
              searchText: inputData ?? ""
            }
            if (broker.brokerFilter.length > 0) {
              payload.sortBy = broker.brokerFilter;
            }
            dispatch(getAvailableCPsAPI(payload));
          } catch {
          }
          return;

        case searchPath.AVAILABLE_MANAGER:
          try {
            dispatch(setavailableManagerPagination({
              current_page: 1,
              total_data: 10,
              total_pages: 1,
              page_size: 10
            }))
            let payload: any = {
              page: 1,
              searchText: inputData ?? ""
            }
            if (manager.managerFilter.length > 0) {
              payload.sortBy = manager.managerFilter;
            }
            dispatch(
              getAllManagers(payload)
            );
          } catch (error) {

          }
          return;

        case searchPath.REGISTRATION_REQUEST:
          try {
            dispatch(setRegistrationReqPagination({
              current_page: 1,
              total_data: 10,
              total_pages: 1,
              page_size: 10
            }));
            dispatch(
              getRegistrationRequestsAPI({
                page: 1,
                registration_req_Status: "pending",
                searchText: inputData ?? "",
              })
            );
          } catch (error) {
            console.log(
              "error while fetching registration requests :>> ",
              error
            );
          }
          return;

        case searchPath.ALL_USERS:
          try {
            dispatch(setAllUsersPagination({
              current_page: 1,
              total_data: 10,
              total_pages: 1,
              page_size: 10
            }));
            dispatch(
              getAllUsersAPI({ page: 1, role_id: 2, searchText: inputData ?? "" })
            );
          } catch (error) {
          }
          return;
        
          case searchPath.USER_MANAGEMENT:
            try {
              dispatch(setAllUsersPagination({
                current_page: 1,
                total_data: 10,
                total_pages: 1,
                page_size: 10
              }));
              // dispatch(
              //   getAllUsersAPI({ page: 1, role_id: 2, searchText: inputData ?? "" })
              // );
            } catch (error) {
            }
            return;
      }
    }, 700);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [inputData]);

  useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setManagerFilters(""));
    dispatch(setBrokerFilter(""));
    setInputData("");
  }, [path]);

  const handleSearchVisible = () => {
    if (
      path === searchPath.SITE_MANAGEMENT ||
      path === searchPath.MANAGE_MANAGER ||
      path === searchPath.MANAGE_GROUP ||
      path === searchPath.MANAGE_BROKER ||
      path === searchPath.ALLOCATE_BROKER ||
      path === searchPath.AVAILABLE_MANAGER ||
      path === searchPath.REGISTRATION_REQUEST ||
      path === searchPath.ALL_USERS || 
      path === searchPath.USER_MANAGEMENT
    ) {
      return true;
    }
    return false;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    // Allow spaces as part of input but not at the start
    if (value === "" || (value.trim() !== "" && !/^\s/.test(value))) {
      setInputData(value);
    }
  };

  const handleResetSearch = () => {
    setInputData("");
  };
  return (
    <>
      {handleSearchVisible() ? (
        <SearchBarContainer>
          <Input
            type="text"
            value={inputData}
            placeholder="Search by name or phone number..."
            onChange={handleInputChange}
          />
          {inputData.length > 0 ? (
            <ClearButton onClick={() => handleResetSearch()}>X</ClearButton>
          ) : (
            <Icon />
          )}
        </SearchBarContainer>
      ) : null}
    </>
  );
};

export default SearchBar;
