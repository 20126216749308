import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { PaginationType } from "../../../dto/Pagination.dto";
import { showErrorToast, showSuccessToast } from "../../../app/utils/common/Toast";
import { GroupSliceState } from "../../Slices/groupSlice";
import { AvailableManagersType, GroupType } from "../../../dto/Groups.dto";
import { createGroupAPI, deleteGroupAPI, getAllGroups, getAllGroupsManagers, getAllGroupsManagersCPs, getAvailableManagers, updateGroupAPI } from "../../API/GroupApi";


export const GroupReducer = (builder: ActionReducerMapBuilder<GroupSliceState>) => {
    builder
        .addCase(getAllGroups.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllGroups.fulfilled, (state, action: PayloadAction<{
            group_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            if (state.groupPagination.current_page === action.payload.pagination.current_page) {
                state.groups = action.payload.group_data;
                state.groupPagination = action.payload.pagination;
                state.refreshTable = false;
            }
        }).addCase(getAllGroups.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        .addCase(getAllGroupsManagers.pending, (state) => {
            state.groupLoading = true;
        })
        .addCase(getAllGroupsManagers.fulfilled, (state, action: PayloadAction<{
            group_manager_data: GroupType[]
        }>) => {
            state.groupLoading = false;
            state.groups = action.payload.group_manager_data;


        }).addCase(getAvailableManagers.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        .addCase(getAvailableManagers.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAvailableManagers.fulfilled, (state, action: PayloadAction<{
            manager_data: AvailableManagersType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            state.availableManagers = action.payload.manager_data;
            state.refreshTable = false;
        }).addCase(getAllGroupsManagers.rejected, (state, action: PayloadAction<any>) => {
            state.groupLoading = false;
        })
        .addCase(getAllGroupsManagersCPs.pending, (state) => {
            state.groupCpsLoading = true;
        })
        .addCase(getAllGroupsManagersCPs.fulfilled, (state, action: PayloadAction<{
            group_Cps_data: GroupType[],
        }>) => {
            state.groupCpsLoading = false;
            state.groups = action.payload.group_Cps_data;
            state.refreshTable = false;
        }).addCase(getAllGroupsManagersCPs.rejected, (state, action: PayloadAction<any>) => {
            state.groupCpsLoading = false;
        })
        .addCase(createGroupAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(createGroupAPI.fulfilled, (state, action: PayloadAction<{
            group_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            showSuccessToast("Group created.");
            state.groups = [];
        }).addCase(createGroupAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                showErrorToast("Error in Group creation.")
            }
        })
        .addCase(updateGroupAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateGroupAPI.fulfilled, (state, action: PayloadAction<{
            group_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            showSuccessToast("Group updated.");
            state.groups = [];
        }).addCase(updateGroupAPI.rejected, (state, action: PayloadAction<any>) => {
            if (action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                showErrorToast("Error in Group creation.")
            }
            state.loading = false;
        })
        .addCase(deleteGroupAPI.pending, (state) => {
            state.loading = true;

        })
        .addCase(deleteGroupAPI.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            const tempManagers = [...(state.groups ?? [])]
            const filteredGroups = tempManagers.filter((group) => group.group_id !== action.payload.group_id) ?? []
            state.groups = [...filteredGroups];
        })
        .addCase(deleteGroupAPI.rejected, (state, action: PayloadAction<any>) => {
            if (action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                showErrorToast("Error in manager creation.")
            }
            state.loading = false;
        })

};
