import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { useNavigate } from 'react-router-dom';
import { image } from '../../../app/utils/common/image'
import LogoutPopup from '../../organisms/AuthScreens/LogoutPopup';
import { getUnseenNotificationAPI, MakeNotificationSeenAPI } from '../../../redux/API/NotificationAPI';
import { error } from 'console';
import moment from 'moment';
import Loader from '../../atoms/Loader/Loader';
import EmptyPage from '../../atoms/EmptyPage/EmptyPage';
import { NotificationType } from '../../../dto/Notification.dto';
import { PaginationType } from '../../../dto/Pagination.dto';
import { SetUnseenNotificationCount } from '../../../redux/Slices/notificationSlice';
import store from '../../../redux/store';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }

`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    
  }
  to {
    opacity: 0;
    
  }

`;
const DropdownMenu = styled.div<{ menuOpen: boolean }>`
  position: absolute;
  top: 49px;
  right: -4px;
  background: white;
  width: 425px;
  height: 340px;
  overflow-y: scroll;
  z-index: 1000;
  border-radius: 10px;
  display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')};
  animation: ${({ menuOpen }) => (menuOpen ? fadeIn : fadeOut)} 0.3s ease-out;
  box-shadow:3px 3px 15px #00000030;
`
const MenuItem = styled.div`
  padding: 2px 16px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ItemIcon = styled.img`
  width: 20px;
  height: 20px;
`
const ItemNameContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`
const ItemName = styled.p`
  /* margin-right: 14px; */
    width: 151px;
    height: 23px;
    font-family: Lufga;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    line-height: 23px;
    border: 1px solid var(--newThemeColor);
    border-radius: 5px;
    /* padding: 2px; */
`
const Hr = styled.hr`
margin:0;
color: #5B463E;
`

const NotificationContent = styled.div`
position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NotificationTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #333;
`;

const NotificationBody = styled.p`
  font-size: 12px;
  color: #666;
  margin: 2px 0 0;
`;

const NotificationTime = styled.span`
  font-size: 12px;
  color: #999;
  margin-left: 10px;
  white-space: nowrap;
`;

const NotificationPopup: React.FC<{ menuOpen: boolean, setMenuOpen: (boolean: boolean) => void }> = ({ setMenuOpen, menuOpen }) => {
  // const menuRef = useRef<HTMLDivElement>(null);
  // const scrollRef = useRef<HTMLDivElement>(null);
  const menuAndScrollRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate()
  const [logoutPopup, setLogoutPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  // const { unseenNotifications, loading } = useAppSelector(state => state.notification);
  const [unseenNotifications, setUnseenNotifications] = useState<NotificationType[]>([]);

  const [pagination, setPagination] = useState({
    total_data: 0,
    current_page: 1,
    page_size: 7,
    total_pages: 1,
  })


  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuOpen, unseenNotifications]);


  //call unseen notification api
  useEffect(() => {
    if (menuOpen) {
      setLoading(true);
      dispatch(getUnseenNotificationAPI({ page: pagination.current_page, limit: 5 }))
        .unwrap()
        .then((data: { notification_data: NotificationType[]; pagination: PaginationType }) => {
          if (data.pagination.current_page === pagination.current_page) {
            if (data.pagination.current_page === 1) {
              setPagination(data.pagination);
              setUnseenNotifications(data.notification_data);
            } else {
              setUnseenNotifications([...unseenNotifications, ...data.notification_data]);
            }
            setLoading(false);
          }
        });
    }
  }, [menuOpen, pagination.current_page]);


  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (menuAndScrollRef.current && !menuAndScrollRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
    const unseenNotificationCount = store.getState().notification.unseenNotificationCount
    const notificationIds = unseenNotifications.map(notification => notification.id);
    if (notificationIds.length > 0) {
      dispatch(MakeNotificationSeenAPI({ notification_ids: notificationIds })).unwrap().then(() => {
        dispatch(SetUnseenNotificationCount(unseenNotificationCount - notificationIds.length))
      })
    }
  }, [unseenNotifications, dispatch]);

  let debounceTimer: NodeJS.Timeout;
  const handleScroll = () => {
    if (menuAndScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuAndScrollRef.current;
      const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
      if (scrollPercentage >= 0.7 && !loading) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          if (!loading && pagination.current_page < pagination.total_pages) {
            setPagination({
              ...pagination, current_page: pagination.current_page + 1,
            });
          }
        }, 500);
      }
    }
  };
  return (
    <>
      <DropdownMenu menuOpen={menuOpen} ref={menuAndScrollRef} onScroll={handleScroll}>
        <MenuItem onClick={() => navigate('/dashboard/send-notification')}>
          {/* <ItemIcon src={image.PROFILE_ICON} /> */}
          <ItemNameContainer>
            <ItemName>Send Notification</ItemName>
          </ItemNameContainer>
        </MenuItem>
        <Hr />
        {loading && pagination.current_page === 1 ? <div className='center_div'><Loader /></div> :
          unseenNotifications.length > 0 ?
            unseenNotifications.map((notification) => (
              <>
                <MenuItem key={notification.id}>
                  <NotificationContent>
                    <div className="notification-text">
                      <NotificationTitle>{notification?.title}</NotificationTitle>
                      <NotificationBody>{notification?.body}</NotificationBody>
                    </div>
                    <NotificationTime>
                      {moment(notification.created_at).fromNow()}
                    </NotificationTime>

                  </NotificationContent>
                </MenuItem>
                <hr className='horizontal-line' />
              </>
            )) : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "55%", margin: "0 auto" }}><EmptyPage image={image.NO_MANAGER_ADDED_YET_IMAGE} text='No Notification Found' /></div>
        }

        {logoutPopup && <LogoutPopup logoutPopup={logoutPopup} setLogoutPopup={setLogoutPopup} />}
        {loading && pagination.current_page > 1 && (<div className='loading_more_unSeenNotifications'>Loading data...</div>)}

      </DropdownMenu>
    </>
  )
}

export default NotificationPopup