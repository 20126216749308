import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { image } from '../../../app/utils/common/image';
import './PostRequests.scss';
import PostProjectTab from '../../molecules/PostManagement/PostProjectTab';
import { checkPermission, permissionType } from '../../../app/utils/common/checkPermission';

const PostRequests = () => {
    const navigate = useNavigate();

    return (
        <div className='site_management_page_container'>
            <div className='site_management_header'>
                <div className='header_text'>
                    Post Request
                </div>
                {checkPermission(permissionType.POST_MODULE, 'create') && <div className='button_container'>
                    <div className='btn' onClick={() => navigate('post/create')}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Create
                        </div>
                    </div>
                </div>}
            </div>
            <div className='project_list_container'>
                <PostProjectTab />
            </div>
        </div>
    )
}

export default PostRequests