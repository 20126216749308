import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { storeDeviceTokenAPI } from "./redux/API/NotificationAPI";
import { useAppDispatch, useAppSelector } from "./app/utils/hooks/reduxHook";
import GetNotification from "./components/organisms/Dashboard/Notification/GetNotification";
import { useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyDEwHThJnmJWrq4OGpqVDP06FyoLOzG97U",
  authDomain: "arbventures-b8f00.firebaseapp.com",
  projectId: "arbventures-b8f00",
  storageBucket: "arbventures-b8f00.firebasestorage.app",
  messagingSenderId: "376332297396",
  appId: "1:376332297396:web:7cfdf871cf1a768365a3e1",
  measurementId: "G-1YZPZN8THR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const useRequestPermission = () => {
  const dispatch = useAppDispatch();
  const access_token = useAppSelector((state) => state.auth.access_token);

  const requestPermission = async (): Promise<string | null> => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {

        // Get the device token
        const currentToken = await getToken(messaging, {
          vapidKey:
            "BHGGLRP5TCWEpwdoH9FEKV8-uP_qOsLcG5-ZRzc7i9VCkGZXu9CmSMhww064ZMUWCD0lQoWNbCyihOIwjD8vIZc",
        });

        if (currentToken) {
          // Dispatch the API call to store the device token
          if (access_token) {
            await dispatch(storeDeviceTokenAPI({ device_token: currentToken }));
          }
          return currentToken; // Return the token
        } else {
          return null;
        }
      } else {

        return null;
      }
    } catch (error) {
      return null;
    }
  };

  return requestPermission;
};

export const onMessageListener = (): Promise<any> => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};


