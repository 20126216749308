import React, { useState } from 'react'
import ManageBrokerTable from '../../molecules/ManageBrokersComponent/ManageBrokerTable/ManageBrokerTable';
import './ManageBrokers.scss'
import { Button } from 'antd';
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';
import { checkPermission, permissionType } from '../../../app/utils/common/checkPermission';
import FilterModal from '../../atoms/FilterModal/FilterModal';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { getAllBrokers } from '../../../redux/API/BrokerAPI';
import { setBrokerFilter } from '../../../redux/Slices/brokerSlice';

const ManageBrokers = () => {
    const navigate = useNavigate();
    const { searchText } = useAppSelector(state => state.search);
    const {brokerFilter} = useAppSelector(state=> state.broker)

    const dispatch = useAppDispatch();
    const filterModalOptions = [
        {
            key: "most_site_visits",
            value: "Most Site Visits"
        },
        {
            key: "least_site_visits",
            value: "Least Site Visits"
        },
        {
            key: "most_referrals",
            value: "Most Referrals"
        },
        {
            key: "least_referrals",
            value: "Least Referrals"
        }
    ]
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const fetchFilterData = (sortBy: string) => {
        dispatch(setBrokerFilter(sortBy))
        dispatch(getAllBrokers({ page: 1, sortBy, searchText }));
    }
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Manage Brokers
                </div>
                <div className='button_container'>
                 {(brokerFilter !== "" || null || undefined) &&  <div className='filter_count'><span>1</span></div>}  
                    <div className='btn' onClick={() => setFilterModal(!filterModal)}>
                        <img className='btn_icon' src={image.FILTER_ICON} />
                        <div>
                            Filter
                        </div>
                    </div>
                    {checkPermission(permissionType.BROKER_MODULE, 'create') &&
                        <div className='btn' onClick={() => { navigate('broker/create') }}>
                            <img className='btn_icon' src={image.ADD_ICON} />
                            <div>
                                Add
                            </div>
                        </div>
                    }

                </div>
            </div>

            <div className='manager_data_table'>
                <ManageBrokerTable />
            </div>
            {filterModal && (
                <div className='filter_modal_wrapper'>
                    <FilterModal
                        options={filterModalOptions}
                        handleSubmit={(data) => {
                            console.log(data)
                            fetchFilterData(data.key);
                            setFilterModal(false);
                        }}
                        handleRemoveFilter={() => {
                            dispatch(getAllBrokers({ page: 1, limit: 15 }))
                            dispatch(setBrokerFilter(''));
                            setFilterModal(false);
                        }}
                        filterType='broker'
                        onOutSideClick={() => setFilterModal(false)}
                    />
                </div>
            )}
        </div >
    )
}

export default ManageBrokers