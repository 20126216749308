import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { showSuccessToast } from '../../../app/utils/common/Toast';

const QRScanner: React.FC<{
    handleGetData: (data: any) => void;
    loading: boolean;
}> = ({
    handleGetData,
    loading
}) => {
        const [data, setData] = useState('');
        const [error, setError] = useState('');
        const [permissionDenied, setPermissionDenied] = useState(false);

        const checkCameraPermission = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ video: true });
                // Camera access granted
                setPermissionDenied(false);
            } catch (err) {
                // Camera access denied
                setPermissionDenied(true);
                console.error("Camera permission denied", err);
            }
        };

        useEffect(() => {
            if (data && data.length > 0 && !loading) {
                handleGetData(data);
            }
        }, [data])

        useEffect(() => {
            checkCameraPermission();
        }, []);

        return (
            <div style={{ textAlign: 'center' }}>
                <h2>QR Scanner</h2>
                {permissionDenied ? (
                    <p style={{ color: 'red' }}>
                        Camera access is required to scan QR codes. Please enable it in your browser settings.
                    </p>
                ) : (
                    <div style={{ width: '300px', margin: '0 auto' }}>
                        <QrReader
                            onResult={(result: any, error) => {
                                if (!!result) {
                                    setData(result.text);
                                }
                                if (!!error) {
                                    setError(error.message);
                                }
                            }}
                            constraints={{ facingMode: 'environment' }} // Back camera
                        />
                    </div>
                )}
                {error && <p style={{ color: 'red' }}>Error: {error}</p>}
            </div>
        );
    };

export default QRScanner;