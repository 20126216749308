import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { projectReducer } from "../Reducer/Project/ProjectReducer";
import {
  ProjectGroupType,
  ProjectListType,
  SingleProjectType,
} from "../../dto/Projects.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { ManagerType } from "../../dto/Manager.dto";

export interface ProjectData {
  id: number;
  name: string;
  url: string;
}

export interface ProjectState {
  ActiveProjects: ProjectListType[];
  CompletedProjects: ProjectListType[];
  CurrentProject: null | { id: number; name: string; url: string };
  error: string | null;
  loading: boolean;
  SingleProject?: SingleProjectType;
  SingleProjectGroups: ProjectGroupType[];
  SingleProjectGroupsPagination: PaginationType;
  SingleProjectManagers: ManagerType[];
  pagination: PaginationType;
}

const initialState: ProjectState = {
  loading: false,
  ActiveProjects: [],
  CompletedProjects: [],
  SingleProjectGroups: [],
  CurrentProject: null,
  error: null,
  SingleProjectManagers: [],
  pagination: {
    total_data: 0,
    current_page: 1,
    page_size: 10,
    total_pages: 1,
  },
  SingleProjectGroupsPagination: {
    total_data: 0,
    current_page: 1,
    page_size: 5,
    total_pages: 1,
  },
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectGroupPagination(state, action: PayloadAction<PaginationType>) {
      state.SingleProjectGroupsPagination = action.payload;
    },
    clearSingleSiteData(state) {
      delete state.SingleProject;
      state.SingleProjectGroups = [];
      state.SingleProjectManagers = [];
      state.SingleProjectGroupsPagination = {
        total_data: 0,
        current_page: 1,
        page_size: 5,
        total_pages: 1,
      };
    },
    setProjectPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    projectReducer(builder);
  },
});
export const {
  setProjectGroupPagination,
  clearSingleSiteData,
  setProjectPagination,
} = projectSlice.actions;
export default projectSlice.reducer;
