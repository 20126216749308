import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import React, { act } from "react";
import { PostSliceState } from "../../Slices/postSlice";
import {
  createPostAPI,
  deletePostAPI,
  getPostRequestsAPI,
  VerifyPostAPI,
} from "../../API/PostAPI";
import { PostType, CreatePostType } from "../../../dto/post.dto";
import { PaginationType } from "../../../dto/Pagination.dto";
import { showErrorToast, showSuccessToast } from "../../../app/utils/common/Toast";
import { RoleModuleType } from "../../../dto/Role.dto";

export const PostReducer = (
  builder: ActionReducerMapBuilder<PostSliceState>
) => {
  builder
    .addCase(getPostRequestsAPI.pending, (state) => {
      state.loading = true;
    })
    // {
    //   pagination: PaginationType,
    //   status: string,
    //   posts_data: PostType[]
    // }
    .addCase(
      getPostRequestsAPI.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (
          action.payload &&
          action.payload.pagination &&
          action.payload.pagination.current_page ===
          state.pagination.current_page
        ) {
          if (action.payload && action.payload.status === state.postActiveTab) {
            const postsToAppend = action.payload?.posts_data || [];
            if (action.payload.status === "pending") {
              state.pendingLoading = false;
              state.pendingPosts =
                state.pagination.current_page === 1
                  ? postsToAppend
                  : [
                    ...state.pendingPosts,
                    ...postsToAppend.filter(
                      (post: any) => !state.pendingPosts.some((p: any) => p.post_id === post.post_id)
                    ),
                  ];
            } else if (action.payload.status === "approved") {
              state.approvedLoading = false;
              state.activePost =
                state.pagination.current_page === 1
                  ? postsToAppend
                  : [
                    ...state.activePost,
                    ...postsToAppend.filter(
                      (post: any) => !state.activePost.some((p: any) => p.post_id === post.post_id)
                    ),
                  ];

            } else if (action.payload.status === "rejected") {
              state.rejectedLoading = false;
              state.rejectedPosts =
                state.pagination.current_page === 1
                  ? postsToAppend
                  : [
                    ...state.rejectedPosts,
                    ...postsToAppend.filter(
                      (post: any) => !state.rejectedPosts.some((p: any) => p.post_id === post.post_id)
                    ),
                  ];
            }
            if (action.payload.pagination.current_page === 1)
              state.pagination = action.payload.pagination;
            state.loading = false;
          }

        }
      }
    )
    .addCase(
      getPostRequestsAPI.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.pendingLoading = true;
        state.approvedLoading = true;
        state.rejectedLoading = true;
        if (action.payload.message) {
          showErrorToast(action.payload.message);
        } else {
          showErrorToast("Error in post fetching.");
        }
      }
    )

    .addCase(VerifyPostAPI.pending, (state) => {
      state.verifyLoading = true;
    })
    .addCase(VerifyPostAPI.fulfilled, (state, action: PayloadAction<any>) => {
      console.log("action.payload :>> ", action.payload);

      state.verifyLoading = false;
    })
    .addCase(VerifyPostAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
        state.verifyLoading = false;
      } else {
        showErrorToast("Error in verify post.");
      }
    })

    .addCase(deletePostAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(deletePostAPI.fulfilled, (state, action: PayloadAction<any>) => {
      console.log("action.payload deletePostAPI :>> ", action.payload);
      state.loading = false;
    })
    .addCase(deletePostAPI.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.message) {
        showErrorToast(action.payload.message);
        state.loading = false;
      } else {
        showErrorToast("Error in post deletion.");
      }
    })
    .addCase(createPostAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      createPostAPI.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        showSuccessToast("Post created successfully.")
      }
    )
    .addCase(createPostAPI.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in role update.");
      }
    })
};
