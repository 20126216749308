import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import axios, { CancelTokenSource } from "axios";

let cancelPendingPostToken: CancelTokenSource | undefined;
let cancelApprovedPostToken: CancelTokenSource | undefined;
let cancelRejectedPostToken: CancelTokenSource | undefined;

export const getPostRequestsAPI = createAsyncThunk(
  "postRequest/getPostRequestsAPI",
  async (
    {
      current_page,
      total_page = 10,
      Post_Status,
    }: { current_page: number; total_page?: number; Post_Status: string },
    { rejectWithValue }
  ) => {
    try {
      let cancelToken: CancelTokenSource | undefined;
      if (Post_Status === "pending") {
        if (cancelPendingPostToken) {
          cancelPendingPostToken.cancel(
            "Operation canceled due to new request."
          );
        }
        cancelPendingPostToken = axios.CancelToken.source();
        cancelToken = cancelPendingPostToken;
      }
      if (Post_Status === "approved") {
        if (cancelApprovedPostToken) {
          cancelApprovedPostToken.cancel(
            "Operation canceled due to new request."
          );
        }
        cancelApprovedPostToken = axios.CancelToken.source();
        cancelToken = cancelApprovedPostToken;
      }
      if (Post_Status === "rejected") {
        if (cancelRejectedPostToken) {
          cancelRejectedPostToken.cancel(
            "Operation canceled due to new request."
          );
        }
        cancelRejectedPostToken = axios.CancelToken.source();
        cancelToken = cancelRejectedPostToken;
      }
      const response = await apiClient.get(
        `${ApiConstants.POST_REQUEST}/${Post_Status}/?page=${current_page}&limit=${total_page}`
        // { cancelToken: cancelToken?.token }
      );
      return {
        posts_data: response.data.data.posts_data,
        pagination: response.data.data.pagination,
        status: Post_Status,
      };
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      } else {
        return rejectWithValue(error.response?.data || "Failed to get posts");
      }
    }
  }
);

export const VerifyPostAPI = createAsyncThunk(
  "postRequest/verifyPostAPI",
  async (
    data: { postId: number; status: number; reject_reason?: string },

    { rejectWithValue }
  ) => {
    try {
      const { postId, status, reject_reason } = data;

      const response = await apiClient.post(
        `${ApiConstants.VERIFY_POST}/${postId}/verify`,
        { status, reject_reason }
      );
      return response.data.data;
    } catch (error: any) {
      console.log("error print", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const deletePostAPI = createAsyncThunk(
  "postRequest/deletePostAPI",
  async (data: { postId: number; status: number }, { rejectWithValue }) => {
    try {
      const { postId, status } = data;
      const response = await apiClient.delete(
        `${ApiConstants.DELETE_POST}/${postId}/delete`
      );
      return response.data.data;
    } catch (error: any) {
      console.log("error in deleting post:", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const createPostAPI = createAsyncThunk(
  "postRequest/createPostAPI",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`${ApiConstants.CREATE_POST_API}`, data);
      console.log(response.data, 'response.dataapost');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);